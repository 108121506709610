
import { defineComponent, ref, computed, onMounted } from "vue";

export default defineComponent({
  name: "LoginCarousel",
  setup() {
    const currentImageIndex = ref(0);
    const currentHeadlineIndex = ref(0);
    const currentSubheadlineIndex = ref(0);
    const headlines = ref([
      "Encontre oportunidades",
      "Tenha o estudo detalhado",
      "Entramos em contato",
    ]);
    const subheadlines = ref([
      "Busque as oportunidades que estão dentro do seu perfil.",
      "Acesse o conteúdo mais completo e se destaque.",
      "Nós vamos te ajudar a alcançar seus objetivos.",
    ]);
    const slides = ref(["slide-1", "slide-2", "slide-3"]);

    const currentImageClass = computed(
      () => `carousel-image-${currentImageIndex.value + 1}`
    );
    const currentHeadlineClass = computed(
      () => `headline bold-h4 ${currentHeadlineEffectClass.value}`
    );
    const currentHeadlineText = computed(
      () => headlines.value[currentHeadlineIndex.value]
    );
    const currentHeadlineEffectClass = computed(() => {
      if (currentImageIndex.value === 0) {
        return "fade";
      } else if (currentImageIndex.value === 1) {
        return "fadeUp";
      } else {
        return "fadeDown";
      }
    });
    const currentSubheadlineClass = computed(
      () => `sub-headline regular-p ${currentSubheadlineEffectClass.value}`
    );
    const currentSubheadlineText = computed(
      () => subheadlines.value[currentSubheadlineIndex.value]
    );
    const currentSubheadlineEffectClass = computed(() => {
      if (currentImageIndex.value === 0) {
        return "fade";
      } else if (currentImageIndex.value === 1) {
        return "fadeUp";
      } else {
        return "fadeDown";
      }
    });

    const changeImage = () => {
      currentImageIndex.value = (currentImageIndex.value + 1) % 3;
      currentHeadlineIndex.value = (currentHeadlineIndex.value + 1) % 3;
      currentSubheadlineIndex.value = (currentSubheadlineIndex.value + 1) % 3;
    };

    onMounted(() => {
      setInterval(changeImage, 4000);
    });
    return {
      currentImageClass,
      currentHeadlineClass,
      currentHeadlineText,
      currentHeadlineEffectClass,
      currentSubheadlineClass,
      currentSubheadlineText,
      currentSubheadlineEffectClass,
      slides,
      currentImageIndex,
    };
  },
});
