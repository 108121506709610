
import { defineComponent, ref, onMounted, onUnmounted } from "vue";

export default defineComponent({
  name: "ButtonLoader",
  setup() {
    const currentDot = ref(1);
    const intervalId = ref(0);

    const startAutoPlay = () => {
      intervalId.value = setInterval(() => {
        currentDot.value = (currentDot.value % 3) + 1;
      }, 500);
    };

    onMounted(() => {
      startAutoPlay();
    });

    onUnmounted(() => {
      clearInterval(intervalId.value);
    });

    return {
      currentDot,
    };
  },
});
