<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open, 'colored': isFilled }" @click="open = !open">
      {{ selected.descricao }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option;
          open = false;
          $emit('input', option);
          $emit('option-selected', option.descricao);
        "
      >
        {{ option.descricao }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    title: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
      computed: {
        isFilled() {
          return this.selected.descricao !== this.default.descricao;
        }
      }
    };
  },
  mounted() {
    this.$emit("input", this.selected);
  },
};
</script>

<style scoped lang="scss">
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 47px;
  font-size: 14px;
}

.custom-select .selected {
  background-color: white;
  border-radius: 12px;
  border: 1px solid #767676;
  color: #767676;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;

  & .colored {
    background-color: #BCA9F5;
  }
}

.custom-select .selected.open {
  border: 1px solid #BCA9F5;
  border-radius: 12px 12px 0px 0px;
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #BCA9F5 transparent transparent transparent;
}

.custom-select .items {
  color: #080808;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  border-right: 1px solid #BCA9F5;
  border-left: 1px solid #BCA9F5;
  border-bottom: 1px solid #BCA9F5;
  position: absolute;
  background-color: white;
  left: 0;
  right: 0;
  z-index: 1;
}

.custom-select .items div {
  color: #767676;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #BCA9F5;
  color: white;
}

.selectHide {
  display: none;
}
</style>
