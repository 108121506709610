import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e376b8ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal-mask"
}
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = {
  class: "modal-container",
  ref: "target"
}
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isOpen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "header", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.title), 1)
              ], true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "content", {}, () => [
                _createTextVNode(" default content ")
              ], true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
            ])
          ], 512)
        ])
      ]))
    : _createCommentVNode("", true)
}