
import { computed, defineComponent, ref, onUnmounted, onMounted } from 'vue';
import { useStore } from "@/store";
import PageLoading from '@/components/PageLoading.vue';
import { OpportunityMutationTypes } from '@/store/modules/opportunity/mutation-types';

export default defineComponent({
  name: 'BiOpportunityPlanB',
  components: {
    PageLoading: PageLoading,
  },
  setup() {
    const isLoading = ref(true);
    const store = useStore();
    const sectionMain = ref<HTMLElement | null>(null);
    const opportunityFromBiURL = computed(() => store.getters.selectedBiOpportunityURL);
    const thereIsURL = computed(() => store.getters.selectedBiOpportunityURL !== '');

    const handleIframeLoad = () => {
      setTimeout(() => {
        isLoading.value = false;
      }, 1100);
    };

    const goBack = () => {
      window.history.back();
    };

    onUnmounted(() => {
      store.commit(OpportunityMutationTypes.SET_SELECTED_BI_OPPORTUNITY_URL, '');
    });

    onMounted(() => {
      if (!thereIsURL.value) {
        isLoading.value = false;
      }
    });

    return {
      isLoading,
      sectionMain,
      handleIframeLoad,
      opportunityFromBiURL,
      goBack,
      thereIsURL,
    };
  }
});
