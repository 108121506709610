import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import sendAnalyticsTrack from "@/helpers/route";
import Panel from "../views/panel/Panel.vue";
import Marketplace from "../views/marketplace/Marketplace.vue";
import Send from "../views/send/Send.vue";
import BiOpportunityView from "@/views/panel/BiOpportunityView.vue"
import Login from "@/views/login/Login.vue";
import ForgotPassword from "@/views/login/ForgotPassword.vue";
import RecoverPassword from "@/views/login/RecoverPassword.vue";
import RecoverFinalization from "@/views/login/RecoverFinalization.vue";
import RegisterFinalization from "@/views/login/RegisterFinalization.vue";
import Register from "@/views/login/Register.vue";
import AuthenticationEmail from "@/views/login/AuthenticationEmail.vue";
import OpportunityView from "@/views/opportunity/OpportunityView.vue"
import Account from "@/views/account/Account.vue";
import { getUserData } from "@/services/user/userService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/recover-password",
    name: "recover-password",
    component: RecoverPassword,
  },
  {
    path: "/recover-finalization",
    name: "recover-finalization",
    component: RecoverFinalization,
  },
  {
    path: "/register-finalization",
    name: "register-finalization",
    component: RegisterFinalization,
  },
  {
    path: "/auth-email",
    name: "auth-email",
    component: AuthenticationEmail,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/opportunity/:id",
    name: "summary-opportunities",
    component: OpportunityView,
    meta: { requiresAuth: true }
  },
  {
    path: "/marketplace",
    name: "marketplace",
    component: Marketplace,
    meta: { requiresAuth: true }
  },
  {
    path: "/my-panel",
    name: "my-panel",
    component: Panel,
    meta: { requiresAuth: true }
  },
  {
    path: "/send",
    name: "send",
    component: Send,
    meta: { requiresAuth: true }
  },
  {
    path: "/bi-opportunity/:id",
    name: "bi-opportunity",
    component: BiOpportunityView,
    meta: { requiresAuth: true }
  },
  {
    path: "/account/:tab?",
    name: "account",
    component: Account,
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const userData = getUserData();
    if (!userData) {
      return next({ name: 'login' });
    }
    next();
  } else {
    next();
  }

  if (typeof window !== 'undefined' && (window as any).gtag) {
    sendAnalyticsTrack(to, from.name as string);
  }
});

export default router;
