
import { useStore } from "@/store";
import { defineComponent, ref, computed, onMounted, nextTick } from 'vue';
import { LoadingMutationTypes } from '@/store/modules/loading/mutation-types';
import { getMarketplaceBis } from '@/services/opportunity/opportunityService';
import Card from '@/components/Card.vue';
import Sidebar from '@/components/Sidebar.vue';

export default defineComponent({
  name: 'Marketplace',
  components: {
    Card,
    Sidebar,
  },
  setup() {
    const store = useStore();
    const isLoading = computed(() => store.getters.loading);
    const isMobile = ref(window.innerWidth <= 768);
    const sidebarOpen = ref(true);
    const status = ref('');
    const vgvMin = ref('Min');
    const vgvMax = ref('Máx');
    const userID = ref(0);
    const opportunitiesFromBI = ref<any>([]);
    const isSearchExpanded = ref(true);
    const search = ref('');

    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };

    const toggleSearch = () => {
      if (!isMobile.value) return;
      isSearchExpanded.value = !isSearchExpanded.value;
      if (isSearchExpanded.value) {
        nextTick(() => {
          const input = document.querySelector('.input-group input');
          if (input instanceof HTMLInputElement) {
            input.focus();
          }
        });
      }
    };

    const getImageNameTitle = (cardTitle: string) => {
      switch (cardTitle) {
        case "Prédio Multifamiliar":
        case "Multifamiliar":
          return "predio-multifamiliar";
        case "Multifamiliar":
          return "predio-multifamiliar";
        case "Loteamento":
          return "loteamento";
        case "Prédio Comercial":
          return "predio-comercial";
        case "Galeria Comercial":
          return "galeria-comercial";
        case "Master Plan":
          return "masterplan";
        case "Galpão":
          return "galpao";
        case "DEMONSTRAÇÃO":
          return "demo";
        default:
          break;
      }
    };

    const formatVgvValue = (value?: any) => {
      if (value && value >= 1000000) {
        const formattedValue = (value / 1000000).toLocaleString("pt-BR", {
          maximumFractionDigits: 1,
        });
        return `R$${formattedValue}mi`;
      } else if (value && value >= 1000) {
        const formattedValue = (value / 1000).toLocaleString("pt-BR", {
          maximumFractionDigits: 0,
        });
        return `R$${formattedValue}mil`;
      } else if (value) {
        return `R$${value.toLocaleString("pt-BR")}`;
      }
    };

    const fetchOpportunitiesFromBI = async (isReload?: boolean): Promise<void> => {
      try {
        if (!isReload) {
          store.commit(LoadingMutationTypes.SET_LOADING, true);
        }

        const userData = localStorage.getItem("userData");
        if (typeof userData === "string") {
          userID.value = JSON.parse(userData).user_id;
        }

        if (userID.value > 0) {
          const response = await getMarketplaceBis(userID.value);
          response.forEach((opportunity: any) => {
            if (opportunity.controle_status === 'finalizado') {
              opportunitiesFromBI.value.push(opportunity);
            }
          });
        }


        opportunitiesFromBI.value.sort((a: any, b: any) => {
          const dateA = new Date(a.created_at).getTime();
          const dateB = new Date(b.created_at).getTime();
          return dateB - dateA;
        });
      } catch (error) {
        console.error(error);
      } finally {
        store.commit(LoadingMutationTypes.SET_LOADING, false);
      }
    };

    const filteredOpportunities = computed(() => {
      if (!search.value) {
        return opportunitiesFromBI.value;
      }

      const searchString = search.value.toLowerCase();
      return opportunitiesFromBI.value.filter((opportunity: any) => {
        return (
          opportunity.title?.toLowerCase().includes(searchString) ||
          opportunity.tipo?.toLowerCase().includes(searchString) ||
          opportunity.cidade?.toLowerCase().includes(searchString) ||
          opportunity.estado?.toLowerCase().includes(searchString) ||
          opportunity.bairro?.toLowerCase().includes(searchString) ||
          opportunity.gid_imovel.toString().includes(searchString)
        );
      });
    });

    onMounted(() => {
      fetchOpportunitiesFromBI();

      if (isMobile.value) {
        sidebarOpen.value = false;
        isSearchExpanded.value = false;
      }

      window.addEventListener('resize', () => {
        isMobile.value = window.innerWidth <= 768;
        if (isMobile.value) {
          sidebarOpen.value = false;
          isSearchExpanded.value = false;
        }
      });

      window.addEventListener('keydown', (event) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 'b') {
          toggleSidebar();
        }
      });
    });

    return {
      isLoading,
      opportunitiesFromBI,
      getImageNameTitle,
      formatVgvValue,
      isMobile,
      toggleSidebar,
      sidebarOpen,
      status,
      vgvMin,
      vgvMax,
      search,
      isSearchExpanded,
      toggleSearch,
      filteredOpportunities,
    }
  }
})
