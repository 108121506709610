import axiosInstance from "@/axios/axiosInstance";
import { Favorite, FavoriteResponse } from "./types";
import { Opportunity } from "@/@types";
import { OpportunityMutationTypes } from '@/store/modules/opportunity/mutation-types';
import { useStore } from "@/store";

const save = async (body: Favorite): Promise<FavoriteResponse> => {
  const response = await axiosInstance.post("/favorito", body);
  return response.data;
};

export const toggleFavoriteList = async (idImmobile: number, opportunities: Opportunity[]) => {
  const store = useStore();
  let index = 0;
  opportunities.forEach(async (opportunity: Opportunity) => {
    
    if (idImmobile === opportunity.id) {
      
      let favoriteId: any = null;
      
      if(opportunity.id_favorito != null)
        favoriteId = opportunity.id_favorito
      else{
        favoriteId = 0
      }

      const isFavorite: boolean = opportunity.bl_favorito == null ? false : opportunity.bl_favorito;
      opportunity.bl_favorito = !isFavorite;

      store.commit(OpportunityMutationTypes.SET_OPPORTUNITY_FIELD, { index: index, field: 'bl_favorito', value: opportunity.bl_favorito });

      const body: Favorite = {
        'oportunidade_id': opportunity.id,
        'favoritar': opportunity.bl_favorito,
        'id_favorito': favoriteId
      };
      
      await save(body);
    }

    index++;

  });
};

export const toggleFavorite = async (idImmobile: number, opportunity: Opportunity, indexById: number) => {
  const store = useStore();
  let favoriteId: any = null;
  
  if (opportunity.id_favorito != null) {
    favoriteId = opportunity.id_favorito;
  } else {
    favoriteId = 0;
  }

  const isFavorite: boolean = opportunity.bl_favorito == null ? false : opportunity.bl_favorito;
  opportunity.bl_favorito = !isFavorite;

  const body: Favorite = {
    'oportunidade_id': opportunity.id,
    'favoritar': opportunity.bl_favorito,
    'id_favorito': favoriteId
  };

  await save(body);

  store.commit(OpportunityMutationTypes.SET_OPPORTUNITY_FIELD, {
    index: indexById,
    field: 'bl_favorito',
    value: opportunity.bl_favorito
  });
};
