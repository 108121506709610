import type { Opportunity } from "@/@types";

export type State = {
  opportunities: Opportunity,
  field: any,
  completeOpportunities: any,
  opportunity: Opportunity,
  selectedMarketButton: number,
  selectedBiOpportunityURL: string,
};

export const state: State = {
  opportunities: [],
  field: {},
  completeOpportunities: {},
  opportunity: {},
  selectedMarketButton: 1,
  selectedBiOpportunityURL: '',
};
