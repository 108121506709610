import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e5129720"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["type", "id", "name", "placeholder", "required", "disabled"]
const _hoisted_4 = {
  key: 1,
  class: "error-message"
}
const _hoisted_5 = {
  key: 2,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.hasLabel)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            class: "form-label",
            for: _ctx.id
          }, _toDisplayString(_ctx.textLabel), 9, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        type: _ctx.type,
        id: _ctx.id,
        name: _ctx.name,
        class: _normalizeClass(["input-field", { error: _ctx.showError }]),
        placeholder: _ctx.textPlaceholder,
        required: _ctx.isRequired,
        style: _normalizeStyle({ width: _ctx.width, height: _ctx.height }),
        disabled: _ctx.isDisabled,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInputValue && _ctx.handleInputValue(...args))),
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validate())),
        onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showError = false))
      }, null, 46, _hoisted_3),
      (_ctx.showError && !_ctx.customizeMessageError)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1))
        : _createCommentVNode("", true),
      (_ctx.customizeMessageError)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.customizeMessageError), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}