<template>
  <div class="maintenance-page">
    <header>
      <img src="@/assets/logo.png" alt="Logo" />
    </header>
    <div class="info-box">
      <h1>Estamos em manutenção!</h1>
      <p>Lamentamos o imprevisto, logo estaremos de volta! Tente novamente mais tarde.</p>
      <img src="@/assets/images/maintenance.jpg" alt="Ilustração de manutenção.">
    </div>
  </div>
</template>

<script>
export default {
  name: 'MaintenancePage',
}
</script>

<style lang="scss" scoped>
.maintenance-page {
  height: 100vh;
  overflow-y: auto;
  text-align: center;
  background-color: none;
  & header {
    background-color: #4F3C88; padding: 5px;
    & img {
      width: 150px;
    }
  }
}

.info-box {
  padding: 2rem;
  & img {
    max-width: 500px;
  }
}
</style>
