<template>
  <div :class="{ 'sidebar-open': sidebarOpen, 'lcts-sidebar-mobile': isMobile, 'lcts-sidebar': !isMobile, 'sidebar-open-mobile': isMobile && sidebarOpen }">
    <slot v-if="sidebarOpen"></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Sidebar',
  props: {
    sidebarOpen: {
      type: Boolean,
      default: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  }
})
</script>

<style scoped lang="scss">
.lcts-sidebar {
  width: 0;
  overflow-x: hidden;
  background-color: #FFF;
  border-right: 0.2px solid rgba(0, 0, 0, 0.05);
  transition: width 0.3s ease;

  & h5 {
    font-size: 1.2rem;
    font-weight: 500;
    color: gray;
  }
}
.lcts-sidebar-mobile {
  width: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  margin-top: 148px;
  z-index: 99999;
  overflow-x: hidden;
  background-color: #FFF;
  border-right: 0.2px solid rgba(0, 0, 0, 0.05);
  transition: width 0.3s ease;

  & h5 {
    font-size: 1.2rem;
    font-weight: 500;
    color: gray;
  }
}
.sidebar-open {
  width: 350px;
  padding: 15px;
}
.sidebar-open-mobile {
  width: 100%;
  padding: 15px;
}
</style>
