import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f22b294e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 p-0" }
const _hoisted_3 = { class: "card lcts-card" }
const _hoisted_4 = { class: "card-header lcts-header d-flex justify-content-between align-items-center" }
const _hoisted_5 = { class: "card-body p-0" }
const _hoisted_6 = { class: "p-2" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "lcts-cell" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_pagination = _resolveComponent("table-pagination")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'container': !_ctx.noContainerClass})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h6", {
              class: _normalizeClass(["lcts-header-title mb-0", { 'nowrap': _ctx.dontWrap }])
            }, _toDisplayString(_ctx.tableTitle), 3),
            _createElementVNode("div", {
              class: "rounded-point",
              style: _normalizeStyle({ 'background-color': _ctx.pointColor })
            }, null, 4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: "table-responsive rounded-bottom",
              style: _normalizeStyle({ 'max-height': _ctx.tableMaxHeight, 'height': _ctx.taxHeight })
            }, [
              _createElementVNode("table", {
                class: _normalizeClass(["table table-borderless mb-0", { 'table-striped': _ctx.stripedTable }])
              }, [
                _createElementVNode("thead", null, [
                  _createElementVNode("tr", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeaders, (header, headerIdx) => {
                      return (_openBlock(), _createElementBlock("th", {
                        key: headerIdx,
                        scope: "col",
                        class: _normalizeClass(["nowrap", {'blurred': _ctx.isBlockedOpportunity}])
                      }, _toDisplayString(header), 3))
                    }), 128))
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getVisibleData, (column, columnIdx) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: columnIdx,
                      onClick: ($event: any) => (_ctx.handleRowClick(columnIdx, column)),
                      class: _normalizeClass([
                      {'selected': _ctx.selectedIndex !== -1 && _ctx.selectedIndex === columnIdx},
                      {'dimmed': _ctx.selectedIndex !== -1 && _ctx.selectedIndex !== columnIdx},
                      {'cursor': _ctx.hasFilterClick && column[1] > 0},
                      {'gray-row': _ctx.showHeatMap && columnIdx % 2 === 0}
                    ])
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(column, (cell, cellIdx) => {
                        return (_openBlock(), _createElementBlock("td", {
                          key: cellIdx,
                          class: _normalizeClass([{'blurred': _ctx.isBlockedOpportunity}, "overflow-auto"]),
                          style: _normalizeStyle({
                        'background-color': (!_ctx.isCodeZone && cellIdx === 2 && _ctx.showHeatMap && _ctx.heatMapColors[columnIdx]?.color) ? _ctx.heatMapColors[columnIdx].color : 'transparent'
                      })
                        }, [
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("p", {
                              onClick: ($event: any) => (_ctx.redirectLink(cell, columnIdx)),
                              class: _normalizeClass({
                            'capsule': _ctx.isCodeZone && cellIdx === 0,
                            'border-capsule': _ctx.isCodeZone && cellIdx === 0 && !_ctx.heatMapColors[columnIdx]?.color,
                            'link-style nowrap': cell === 'Acesse clicando aqui',
                          }),
                              style: _normalizeStyle({'background-color': _ctx.isCodeZone && cellIdx === 0 && _ctx.heatMapColors[columnIdx]?.color})
                            }, _toDisplayString(cell), 15, _hoisted_9)
                          ])
                        ], 6))
                      }), 128))
                    ], 10, _hoisted_7))
                  }), 128))
                ])
              ], 2)
            ], 4)
          ])
        ])
      ])
    ]),
    (_ctx.showPagination)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_table_pagination, {
            currentPage: _ctx.currentPage,
            displayedPages: _ctx.displayedPages,
            totalPages: _ctx.totalPages,
            totalItems: _ctx.totalItems,
            onGoToPage: _ctx.goToPage
          }, null, 8, ["currentPage", "displayedPages", "totalPages", "totalItems", "onGoToPage"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}