
import { defineComponent, ref, watch } from "vue";
import PasswordMeter from 'vue-simple-password-meter';

export default defineComponent({
  name: "Password",
  emits: ["valuePasswordChange", "validPassword"],
  components: {
    PasswordMeter,
  },
  props: {
    isRegister: {
      type: Boolean,
      default: false
    },
    isConfirm: {
      type: Boolean,
      default: false
    },
    isReset: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const password = ref('');
    const confirmationPassword = ref('');
    const score = ref(0);
    const showPassword = ref(false);
    const safetyFeedback = ref('');
    const confirmationText = ref('');
    const passwordsMatch = ref(false);

    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };

    const onScore = (payload: any) => {
      score.value = payload.score;
      safetyFeedback.value = payload.strength === 'risky' ? 'Senha arriscada!' :
      payload.strength === 'guessable' ? 'Dá para adivinhar...' :
      payload.strength === 'weak' ? 'Senha fraca.' :
      payload.strength === 'safe' ? 'Senha muito forte!' :
      'Senha segura!';
    };

    const validatePassword = () => {
      if (props.isRegister) {
        passwordsMatch.value = confirmationPassword.value === password.value;
        confirmationText.value = passwordsMatch.value ? 'As senhas coincidem!' : 'As senhas devem ser iguais!';
        if (passwordsMatch.value && password.value.length > 6) {
          emit('valuePasswordChange', password.value)
          emit('validPassword', true)
        } else {
          emit('validPassword', false)
        }
      } else {
        emit('valuePasswordChange', password.value)
      }
    }

    watch(password, () => {
      validatePassword();
      if (password.value === '') {
        safetyFeedback.value = '';
      }
    });

    watch(confirmationPassword, () => {
      validatePassword();
    });

    return {
      showPassword,
      togglePasswordVisibility,
      password,
      score,
      onScore,
      safetyFeedback,
      confirmationText,
      confirmationPassword,
      validatePassword,
      passwordsMatch,
    };
  },
});
