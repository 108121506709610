<template>
  <div class="main" v-if="!loading">
    <l-map
      style="width: 100vw; height: 600px;"
      :zoom="zoom"
      :center="center"
      :options="{ attributionControl: false }"
      ref="map"
      @update:mapObject="onMapObjectUpdate"
    >
      <l-tile-layer :url="currentMapLayer"></l-tile-layer>
      <l-geo-json
        v-for="geom in parsedGeoms"
        :key="geom.gid"
        :geojson="geom.geojson"
        :options-style="geoJsonStyle"
        @click="zoomToFeature(geom)"
      ></l-geo-json>
    </l-map>

    <img
      v-if="isStreetMap"
      class="map-overlay"
      src="@/assets/images/toMap.svg"
      @click="toggleMapLayer"
    />
    <img
      v-else
      src="@/assets/images/toSatelite.svg"
      class="map-overlay"
      @click="toggleMapLayer"
    />
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import { LMap, LTileLayer, LGeoJson } from '@vue-leaflet/vue-leaflet'
import L from 'leaflet'
import { createApp} from "vue";
import PopupContentMap from '@/components/PopupContentMap.vue';

export default {
  name: 'PanelMap',
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    PopupContentMap,
  },
  props: {
    geoms: {
      type: Array,
      required: true,
      default: () => [],
    },
    opportunities: {
      type: Array,
      require: true,
    }
  },
  data() {
    return {
      loading: true,
      zoom: 5,
      center: [0, 0],
      mapLayers: {
        street: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}',
        satellite: 'http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
      },
      currentMapLayer: 'http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
      isStreetMap: false,
      parsedGeoms: [],
      markers: [],
    }
  },
  watch: {
    geoms: {
      immediate: true,
      handler(newGeoms) {
        this.formatGeoms(newGeoms)
      }
    }
  },
  mounted() {
    this.formatGeoms(this.geoms)
  },
  methods: {
    geoJsonStyle() {
      return {
        color: '#BCA9F5',
        weight: 3,
        opacity: 1,
        fillOpacity: 0.5,
        fillColor: '#BCA9F5',
      }
    },
    onMapObjectUpdate(mapObject) {
      if (mapObject) {
        this.$nextTick(() => {
          this.fitMapToBounds()
          this.addMarkersToMap()
        })
      }
    },
    formatGeoms(geoms) {
      this.loading = true
      this.parsedGeoms = geoms.length ? geoms.map(geom => ({
        gid: geom.gid,
        geojson: JSON.parse(geom.geom)
      })) : []

      if (this.parsedGeoms.length) {
        this.loading = false
        this.$nextTick(this.fitMapToBounds)
      }
    },
    toggleMapLayer() {
      this.isStreetMap = !this.isStreetMap
      this.currentMapLayer = this.isStreetMap
        ? this.mapLayers.street
        : this.mapLayers.satellite
    },
    getImageNameTitle (value) {
      switch (value) {
        case "Prédio Multifamiliar":
        case "Multifamiliar":
          return "predio-multifamiliar";
        case "Multifamiliar":
          return "predio-multifamiliar";
        case "Loteamento":
          return "loteamento";
        case "Prédio Comercial":
          return "predio-comercial";
        case "Galeria Comercial":
          return "galeria-comercial";
        case "Master Plan":
          return "masterplan";
        case "Galpão":
          return "galpao";
        case "DEMONSTRAÇÃO":
          return "demo";
        default:
          break;
      }
    },
    formatVgvValue (value) {
      if (value && value >= 1000000) {
        const formattedValue = (value / 1000000).toLocaleString("pt-BR", {
          maximumFractionDigits: 1,
        });
        return `R$${formattedValue}mi`;
      } else if (value && value >= 1000) {
        const formattedValue = (value / 1000).toLocaleString("pt-BR", {
          maximumFractionDigits: 0,
        });
        return `R$${formattedValue}mil`;
      } else if (value) {
        return `R$${value.toLocaleString("pt-BR")}`;
      }
    },
    zoomToFeature(geom) {
      return (e) => {
        const layer = e.target
        const map = this.$refs.map.leafletObject
        map.fitBounds(layer.getBounds(), { padding: [20, 20], maxZoom: 18 })

        
        if (layer.feature && layer.feature.properties) {
          const dataCard = this.opportunities.find(op => op.gid_imovel === geom.gid)

          if (!dataCard) return

          const propsCard = {
            isPdf: Boolean(dataCard.isPdf),
            imageNameTitle: this.getImageNameTitle(dataCard.tipo),
            title: dataCard.title ? dataCard.title : dataCard.tipo,
            type: dataCard.tipo,
            status: dataCard.controle_status,
            cityAndState: `${dataCard.cidade} - ${dataCard.estado}`,
            location: `${dataCard.logradouro} - ${dataCard.bairro}`,
            areaLote: dataCard.area_lote,
            isToShowAreaLote: dataCard.tipo_envio !== 4,
            recommendation: dataCard.tags.length > 0 ? dataCard.tags[0].descricao : '',
            biURL: dataCard.url,
            gidImovel: dataCard.gid_imovel,
            nearby: dataCard.tags[1] ? dataCard.tags[1].descricao : '',
            iconLeft: dataCard.tags.length > 0 ? dataCard.tags[0].tagenum : 'default-left-icon',
            iconRight: dataCard.tags.length > 1 ? dataCard.tags[1].tagenum : 'default-right-icon',
            imageMapName: dataCard.image,
            tagVgv: 'VGV',
            tagVgvValue: dataCard.vgv ? this.formatVgvValue(dataCard.vgv) : null,
            tagRoi: 'ROI',
            tagRoiValue: dataCard.roi ? dataCard.roi + '%' : null,
            tagVgvColor: '#bca9f5',
            id: dataCard.id_bis
          }
      
          const popupDiv = document.createElement('div')
          
          const app = createApp(PopupContentMap, propsCard)
          app.mount(popupDiv)

          layer.bindPopup(popupDiv).openPopup()
        } else {
          console.warn('As propriedades do recurso não estão disponíveis.')
        }
      }
    },
    fitMapToBounds() {
      this.$nextTick(() => {
        if (this.$refs.map && this.$refs.map.leafletObject) {
          const map = this.$refs.map.leafletObject

          const featureGroup = L.featureGroup(
            this.parsedGeoms.map((geom) => L.geoJSON(geom.geojson, {
              onEachFeature: (feature, layer) => {
                layer.on({
                  click: this.zoomToFeature(geom),
                })
              },
            }))
          )

          const bounds = featureGroup.getBounds()
          map.fitBounds(bounds, { padding: [20, 20], maxZoom: 18 })

          featureGroup.addTo(map)

          this.updateMarkers(map)
          
          map.on('zoomend', () => { this.updateMarkers(map) })
        } else {
          console.warn('Instância do mapa não disponível ainda. Tentando novamente...')
          setTimeout(this.fitMapToBounds, 100)
        }
      })
    },
    addMarkersToMap(map) {
      if (this.$refs.map && map) {
        this.markers.forEach(marker => marker.remove())
        this.markers = []

        this.parsedGeoms.forEach(geom => {
          if (geom.geojson && geom.geojson.coordinates) {
            const latlng = this.calculatePolygonCenter(geom.geojson)
            const marker = L.marker(latlng).addTo(map)
            this.markers.push(marker)
          }
        })
      } else {
        console.warn('Instância do mapa não disponível para adicionar marcadores', this.$refs.map)
      }
    },
    updateMarkers(map) {
      this.markers.forEach(marker => marker.remove())
      this.markers = []

      const currentZoom = map.getZoom()
      const minZoomForMarkers = 10

      if (currentZoom < minZoomForMarkers) {
        this.addMarkersToMap(map)
      }
    },
    calculatePolygonCenter(geojson) {
      const type = geojson.type
      let coordinates = []

      if (type === 'Polygon') {
        coordinates = geojson.coordinates[0]
      } else if (type === 'MultiPolygon') {
        coordinates = geojson.coordinates[0][0]
      }

      const latlngs = coordinates.map(coord => L.latLng(coord[1], coord[0]))
      const center = L.latLngBounds(latlngs).getCenter()
      return center
    }
  }
}
</script>
<style lang="scss" scoped>
.main {
  position: relative;
}

.map-overlay {
  position: absolute;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
  z-index: 999;
}
</style>
