
import { defineComponent } from "vue";
import LoginCarousel from "@/components/LoginCarousel.vue";

export default defineComponent({
  name: "RecoverFinalization",
  components: {
    LoginCarousel,
  },
  setup() {
    return {};
  },
});
