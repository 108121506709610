
import { defineComponent, nextTick, ref } from 'vue';
import { computePosition, offset, flip, shift } from '@floating-ui/dom';

export default defineComponent({
  name: 'CustomTooltip',
  props: { small: { type: Boolean, default: false } },
  setup() {
    const triggerRef = ref<HTMLElement | null>(null);
    const tooltipRef = ref<HTMLElement | null>(null);
    const isVisible = ref(false);

    const showTooltip = async () => {
      isVisible.value = true;
      await nextTick(); // wait for the tooltip to be rendered
      if (triggerRef.value && tooltipRef.value) {
        computePosition(triggerRef.value, tooltipRef.value, {
          middleware: [offset(8), flip(), shift()],
        }).then(({ x, y }) => {
          Object.assign(tooltipRef.value!.style, {
            left: `${x}px`,
            top: `${y}px`,
          });
        });
      }
    };

    const hideTooltip = () => {
      isVisible.value = false;
    };

    return {
      triggerRef,
      tooltipRef,
      isVisible,
      showTooltip,
      hideTooltip,
    };
  },
});
