import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-icons/font/bootstrap-icons.css'
import { createApp} from "vue";
import Toast from 'vue-toastification'
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import "@/styles/main.scss";
import 'vue-toastification/dist/index.css'
import "./fontaewsome/css/all.css"
import vClickOutside from 'v-click-outside';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(Toast);
app.use(vClickOutside);
app.mount("#app");

import "bootstrap/dist/js/bootstrap.js";
