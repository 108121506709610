
import { defineComponent, toRaw, ref, onMounted } from "vue";
import Button from "@/components/Button.vue";
import ButtonInputTextfieldAuthCode from "@/components/InputTextfieldAuthCode.vue";
import { authCodeIsValid, sendAuthEmail } from "@/services/email/emailService";
import { useStore } from "@/store";
import { AuthEmailResponse } from "@/services/email/types";
import { useToast } from "vue-toastification";
import router from "@/router/index";
import { LoadingMutationTypes } from "@/store/modules/loading/mutation-types";
import { updateUserById, } from "@/services/user/userService";
import { UserResponse, UserUpdatePayload } from "@/services/user/types";
import VOtpInput from "vue3-otp-input";

export default defineComponent({
  name: "FogotPassword",
  emits: ["resend-code"],
  components: {
    Button,
    ButtonInputTextfieldAuthCode,
    VOtpInput,
  },
  props:{
    userObject: {
      type: Object,
      default: null,
    },
    registerEmail: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const toast = useToast();

    const handleButtonClick = async () => {
      try {
        const validate = await isAuthCodeEmailValid(Number(bindModal.value));
        if (!validate) {
          showErrorToast("Código inválido ou expirado!");
          store.commit(LoadingMutationTypes.SET_LOADING, false);
        } else {
          const userUpdatePayload :UserUpdatePayload = {
            id: props.userObject.id,
            nome: props.userObject.nome,
            email: props.userObject.email,
            link_foto: props.userObject.link_foto,
            usuario: props.userObject.usuario,
            empresa: props.userObject.empresa,
            ocupacao: props.userObject.ocupacao,
            salt: props.userObject.salt,
            bl_ativo: false,
          }
          const activeUser: UserResponse = await updateUserById(userUpdatePayload);
          store.commit(LoadingMutationTypes.SET_LOADING, false);
          goToFinalizationRegister();
        }
      } catch (error) {
        store.commit(LoadingMutationTypes.SET_LOADING, false);
        showErrorToast("Código inválido!");
      }
    };
    const handleResendCode = async () => {
      const response: AuthEmailResponse = await sendAuthEmail({ email: props.registerEmail });
      const registerResponse = {
        ok: response.ok,
      };
      if (response.ok) {
        toast.success("Código reenviado!", {
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else {
        showErrorToast("Erro ao reenviar e-mail");
      }
    };

    const isAuthCodeEmailValid = async (code: number) => {
      const email = props.registerEmail ?? '';
      const response: string = await authCodeIsValid(email, code);
      let codeIsValidResponse
      if (response === 'Codigo invalido') {
        codeIsValidResponse = false;
      } else if (response === 'Codigo expirado') {
        codeIsValidResponse = false;
      } else {
        codeIsValidResponse = true;
      }
      return codeIsValidResponse;
    };

    const showErrorToast = (message: string) => {
      toast.error(message, {
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    };

    const goToFinalizationRegister = () => {
      router.push({ name: "register-finalization" });
    };

    const bindModal = ref("");

    return {
      bindModal,
      handleButtonClick,
      handleResendCode,
    };
  },
});
