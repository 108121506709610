
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "InputTextfieldAuthCode",
  emits: ["lctsInputTextField-value", "lctsInputTextField-isValid"],
  props: {
    textLabel: {
      type: String,
      default: "",
    },
    textPlaceholder: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    hasLabel: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "auto",
    },
    height: {
      type: String,
      default: "auto",
    },
    tabIndex: {
      type: String,
      default: "",
    },
    validator: {
      type: String,
      default: "",
      validator: (value: string) =>
        ["empty", "email", "numeric"].includes(value),
    },
  },
  setup(props, { emit }) {
    const valueToValidate = ref("");
    const showError = ref(false);
    const errorMessage = ref("");
    const isValid = ref(true);
    const handleInputValue = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const newValue = target.value.slice(0, 1);
      emit("lctsInputTextField-value", newValue);
      showError.value = false;
      valueToValidate.value = newValue;
    };
    const handleKeyDown = (event: KeyboardEvent) => {
      const target = event.target as HTMLInputElement;
      if (target.value.length >= 1) {
        event.preventDefault();
      }

      if (event.keyCode === 8 || event.keyCode === 46) {
        target.value = "";
      }
    };

    const validateEmpty = (): boolean => {
      return (
        !props.isRequired ||
        (props.isRequired && valueToValidate.value.trim().length > 0)
      );
    };

    const validateEmail = (): boolean => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return (
        !props.isRequired ||
        (props.isRequired && regex.test(valueToValidate.value))
      );
    };

    const validateNumeric = (): boolean => {
      const regex = /^[0-9]*$/;
      return (
        !props.isRequired ||
        (props.isRequired && regex.test(valueToValidate.value))
      );
    };

    const validate = () => {
      let isFieldValid = true;
      if (props.validator === "empty") {
        isFieldValid = validateEmpty();
        errorMessage.value = "Por favor, preencha este campo";
      } else if (props.validator === "email") {
        isFieldValid = validateEmail();
        errorMessage.value = "Insira um endereço de e-mail válido";
      } else if (props.validator === "numeric") {
        isFieldValid = validateNumeric();
        errorMessage.value = "Insira um valor numérico válido";
      }
      isValid.value = isFieldValid;
      showError.value = !isFieldValid;

      emit("lctsInputTextField-isValid", isValid.value);
    };

    watch(
      () => valueToValidate.value,
      (newValue) => {
        valueToValidate.value = newValue ?? "";
      }
    );

    return {
      handleInputValue,
      showError,
      errorMessage,
      isValid,
      validate,
      valueToValidate,
      handleKeyDown,
    };
  },
});
