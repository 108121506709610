import { GetterTree } from "vuex";
import type {Filter, Filters} from "@/@types";
import { RootState } from "@/store";
import { State } from "./state";

export type Getters = {
  filterInput(state: State): Filter,
  filterLocationCity(state: State): Filter,
  filterLocationDistrict(state: State): Filters,
  filterArea(state: State): Filters,
  filterRoi(state: State): Filter,
  filterVgv(state: State): Filters,
};

export const getters: GetterTree<State, RootState> & Getters = {
  filterInput: (state) => state.filterInput,
  filterLocationCity: (state) => state.filterLocationCity,
  filterLocationDistrict: (state) => state.filterLocationDistrict,
  filterArea: (state) => state.filterArea,
  filterRoi: (state) => state.filterRoi,
  filterVgv: (state) => state.filterVgv,
};
