import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-660f93de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "carousel-container" }
const _hoisted_3 = { class: "image-overlay" }
const _hoisted_4 = { class: "carousel-indicators" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.currentImageClass)
          }, null, 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.currentHeadlineClass)
          }, _toDisplayString(_ctx.currentHeadlineText), 3),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.currentSubheadlineClass)
          }, _toDisplayString(_ctx.currentSubheadlineText), 3),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide, index) => {
              return (_openBlock(), _createElementBlock("span", {
                key: index,
                class: _normalizeClass({ active: index === _ctx.currentImageIndex })
              }, null, 2))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}