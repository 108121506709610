import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    name: "page-transition",
    mode: "out-in"
  }, {
    default: _withCtx(() => [
      (_ctx.currentComponent)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentComponent), { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}