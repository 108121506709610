import axiosInstance from '@/axios/axiosInstance'
import axios from 'axios'
import { OpportunitiesResponse} from './types'
import { Opportunity } from '@/@types'

export const findAll = async (): Promise<OpportunitiesResponse> => {
  const response = await axiosInstance.get('/oportunidades')
  return response.data
}

export const getOpportunitiesFromBI = async (): Promise<any> => {
  const response = await axios.get('https://n8n.locates.com.br/webhook/endpoint_bis');
  return response.data;
}

type OpportunityBI = {
  title: string;
  tipo: string;
  cidade: string;
  bairro: string;
  cep: string;
  area_lote: any;
  logradouro: string;
  estado: string;
  arquivos_localizacao: File[];
  arquivos_complementares: File[];
}
export const sendNewOpportunityToBI = async (opportunity: any): Promise<any> => {
  const response = await axiosInstance.post('/formulario', opportunity)
  return response.data;
}

export const findById = async (id: number): Promise<Opportunity> => {
  const response = await axiosInstance.get(`/oportunidades/${id}`)
  return response.data
}

export const findAllCompleteOpportunity = async (id: number): Promise<any> => {
  const response = await axiosInstance.get(`/oportunidade-completa?oportunidade_id=${id}`)
  return response.data
}

export const findByRaioStart = async (oportunidade_id: number, raio: number): Promise<any> => {
  const response = await axiosInstance.get(`/mercado-imobiliario-raio?oportunidade_id=${oportunidade_id}&raio=${raio}`)
  return response.data
}

export const findByDistrictStart = async (oportunidade_id: number, bairro: string): Promise<any> => {
  const response = await axiosInstance.get(`/mercado-imobiliario-bairro?oportunidade_id=${oportunidade_id}&bairro=${bairro}`)
  return response.data
}

export const findByLaunchStart = async (oportunidade_id: number, raio: number): Promise<any> => {
  const response = await axiosInstance.get(`/mercado-imobiliario-lancamentos?oportunidade_id=${oportunidade_id}&raio=${raio}`)
  return response.data
}


export const findRaioByMeters = async (oportunidade_id: number, raio: number, metragem_min: number, metragem_max: number, valor_min?: number, valor_max?: number): Promise<any> => {
  try {
    let response;
    if (valor_min !== undefined && valor_max !== undefined) {
      response = await axiosInstance.get(`/mercado-imobiliario-raio?oportunidade_id=${oportunidade_id}&raio=${raio}&metragem_min=${metragem_min}&metragem_max=${metragem_max}&valor_min=${valor_min}&valor_max=${valor_max}`);
    } else {
      response = await axiosInstance.get(`/mercado-imobiliario-raio?oportunidade_id=${oportunidade_id}&raio=${raio}&metragem_min=${metragem_min}&metragem_max=${metragem_max}`);
    }

    return response.data;
  } catch (error) {
    console.error('Erro na chamada da API:', error);
    throw error;
  }
}

export const findRaioByValues = async (oportunidade_id: number, raio: number, valor_min: number, valor_max: number, metragem_min?: number, metragem_max?: number): Promise<any> => {
  try {
    let response;

    if (metragem_min !== undefined && metragem_max !== undefined) {
      response = await axiosInstance.get(`/mercado-imobiliario-raio?oportunidade_id=${oportunidade_id}&raio=${raio}&valor_min=${valor_min}&valor_max=${valor_max}&metragem_min=${metragem_min}&metragem_max=${metragem_max}`);
    } else {
      response = await axiosInstance.get(`/mercado-imobiliario-raio?oportunidade_id=${oportunidade_id}&raio=${raio}&valor_min=${valor_min}&valor_max=${valor_max}`);
    }

    return response.data;
  } catch (error) {
    console.error('Erro na chamada da API:', error);
    throw error; 
  }
}

export const findDistrictByMeters = async (oportunidade_id: number, bairro: string, metragem_min: number, metragem_max:number, valor_min?: number, valor_max?: number): Promise<any> => {
  try {
    let response;

    if (valor_min !== undefined && valor_max !== undefined) {
      response = await axiosInstance.get(`/mercado-imobiliario-bairro?oportunidade_id=${oportunidade_id}&bairro=${bairro}&metragem_min=${metragem_min}&metragem_max=${metragem_max}&valor_min=${valor_min}&valor_max=${valor_max}`)
    } else {
      response = await axiosInstance.get(`/mercado-imobiliario-bairro?oportunidade_id=${oportunidade_id}&bairro=${bairro}&metragem_min=${metragem_min}&metragem_max=${metragem_max}`)
    }

    return response.data;
  } catch (error) {
    console.error('Erro na chamada da API:', error);
    throw error; 
  }
}

export const findDistrictByValues = async (oportunidade_id: number, bairro: string, valor_min: number, valor_max: number, metragem_min?: number, metragem_max?: number): Promise<any> => {
  try {
    let response;

    if (metragem_min !== undefined && metragem_max !== undefined) {
      response = await axiosInstance.get(`/mercado-imobiliario-bairro?oportunidade_id=${oportunidade_id}&bairro=${bairro}&valor_min=${valor_min}&valor_max=${valor_max}&metragem_min=${metragem_min}&metragem_max=${metragem_max}`);
    } else {
      response = await axiosInstance.get(`/mercado-imobiliario-bairro?oportunidade_id=${oportunidade_id}&bairro=${bairro}&valor_min=${valor_min}&valor_max=${valor_max}`);
    }

    return response.data;
  } catch (error) {
    console.error('Erro na chamada da API:', error);
    throw error; 
  }
}

export const findLaunchByMeters = async (oportunidade_id: number, raio: number, metragem_min: number, metragem_max: number, valor_min?: number, valor_max?: number): Promise<any> => {
  try {
    let response;
    if (valor_min !== undefined && valor_max !== undefined) {
      response = await axiosInstance.get(`/mercado-imobiliario-lancamentos?oportunidade_id=${oportunidade_id}&raio=${raio}&metragem_min=${metragem_min}&metragem_max=${metragem_max}&valor_min=${valor_min}&valor_max=${valor_max}`);
    } else {
      response = await axiosInstance.get(`/mercado-imobiliario-lancamentos?oportunidade_id=${oportunidade_id}&raio=${raio}&metragem_min=${metragem_min}&metragem_max=${metragem_max}`);
    }

    return response.data;
  } catch (error) {
    console.error('Erro na chamada da API:', error);
    throw error;
  }
}

export const findLaunchByValues = async (oportunidade_id: number, raio: number, valor_min: number, valor_max: number, metragem_min?: number, metragem_max?: number): Promise<any> => {
  try {
    let response;

    if (metragem_min !== undefined && metragem_max !== undefined) {
      response = await axiosInstance.get(`/mercado-imobiliario-lancamentos?oportunidade_id=${oportunidade_id}&raio=${raio}&valor_min=${valor_min}&valor_max=${valor_max}&metragem_min=${metragem_min}&metragem_max=${metragem_max}`);
    } else {
      response = await axiosInstance.get(`/mercado-imobiliario-lancamentos?oportunidade_id=${oportunidade_id}&raio=${raio}&valor_min=${valor_min}&valor_max=${valor_max}`);
    }

    return response.data;
  } catch (error) {
    console.error('Erro na chamada da API:', error);
    throw error; 
  }
}

export const findAllBis = async (businessControl: number, userID?: number, type?: number): Promise<any> => {
  let url = `/bis/${businessControl}`;
  if (userID) {
    url += `?user_id=${userID}`;
  }

  if (type) {
    url += `&tipo_envio=${type}`;
  }

  const response = await axiosInstance.get(url);
  return response.data;
}

export const getMarketplaceBis = async (userID: number, isCreatedByUser?: boolean): Promise<any> => {
  const url = isCreatedByUser ? `/marketplace/${userID}?isCreatedByUser=true` : `/marketplace/${userID}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export const getImoveisFromEsteira = async (): Promise<any> => {
  const response = await axiosInstance.get('/imoveis-esteira');
  return response.data;
}

export const getGeomsByGidList = async (gidList: number[]): Promise<any> => {
  const response = await axiosInstance.post(
    '/geoms-esteira',
    { gid_list: gidList },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return response.data;
}
