
import { useStore } from "@/store";
import { OpportunityMutationTypes } from '@/store/modules/opportunity/mutation-types';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MiniMap',
  props: {
    marketFilter: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const emitter = require('tiny-emitter/instance');

    const redirectToMarket = () => {
      const marketButton = props.marketFilter === 'LANCAMENTO' ? 3 : 1;
      store.commit(OpportunityMutationTypes.SET_SELECTED_MARKET_BUTTON, marketButton)
      emitter.emit('redirect-to-market');
    };

    return { redirectToMarket };
  },
});
