
import { Opportunity } from '@/@types';
import { defineComponent, onMounted, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { findById, findAllCompleteOpportunity } from '@/services/opportunity/opportunityService'
import { LoadingMutationTypes } from '@/store/modules/loading/mutation-types';
import { OpportunityMutationTypes } from '@/store/modules/opportunity/mutation-types';
import { useStore } from "@/store";
import { toggleFavorite } from '@/services/favorites/favoriteService'
import OpportunityHeader from '@/components/OpportunityHeader.vue';
import PageLoading from '@/components/PageLoading.vue';
import ExecutiveSection from './ExecutiveSection.vue';
import ViabilitySection from './ViabilitySection.vue';
import ViabilityPhisicDataSection from './ViabilityPhisicDataSection.vue'
import ConstructivePotentialSection from './ConstructivePotentialSection.vue'
import FinancialAnalysisSection from './FinancialAnalysisSection.vue'
import RealStateMarketSection from './RealStateMarketSection.vue';

export default defineComponent({
  name: 'OpportunityView',
  components: {
    OpportunityHeader,
    PageLoading,
    ExecutiveSection,
    ViabilitySection,
    ViabilityPhisicDataSection,
    ConstructivePotentialSection,
    FinancialAnalysisSection,
    RealStateMarketSection,
  },

  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const id = +route.params.id; // Converter para número usando o operador +
    const opportunity = ref<Opportunity | null>(null);
    const completeOpportunity = ref<any | null>(null);
    const opportunities = computed(() => store.getters.opportunities);
    const isLoading = computed(() => store.getters.loading);
    const selectedMenuItem = ref(1);
    const breadcrumbItem = ref<string[]>([]);
    const titleBreadcrumb = ref("");
    const subtitleBreadcrumb = ref("");
    const backgroundImage = ref("");
    const sectionMain = ref<HTMLElement | null>(null);
    const completeOpportunityStore = computed(() => store.getters.completeOpportunities);
    const currentVGV = ref("");
    const currentROI = ref("");
    const opportunityRef = ref("");
    const isMounted = ref(false);

    const fetchCompleteOpportunity = async (): Promise<void> => {
      try {
        store.commit(LoadingMutationTypes.SET_LOADING, true);
        const response: any = await findAllCompleteOpportunity(id);
        completeOpportunity.value = response.oportunidade_completa;
        store.commit(OpportunityMutationTypes.SET_COMPLETE_OPPORTUNITY, completeOpportunity.value);
      } catch (error) {
        console.error('ERROR', error);
      } finally {
        store.commit(LoadingMutationTypes.SET_LOADING, false);
      }
    };

    const fetchOpportunityById = async (): Promise<void> => {
      try {
        store.commit(LoadingMutationTypes.SET_LOADING, true);
        const response: Opportunity = await findById(id);
        opportunity.value = await response;
        currentVGV.value = formatVgvValue(opportunity.value.vgv);
        currentROI.value = `${opportunity.value.roi}%`;
        opportunityRef.value = `Ref: ${opportunity.value.id}`;
        store.commit(OpportunityMutationTypes.SET_OPPORTUNITY_UNIQUE, await opportunity.value);
      } catch (error) {
        console.error(error);
      } finally {
        store.commit(LoadingMutationTypes.SET_LOADING, false);
      }
    };

    const handleClickBack = () => {
      router.go(-1);
    };

    const handleFavoriteClick = async () => {
      const indexStoreById = opportunities.value.findIndex((opp: Opportunity) => opp.id === opportunity.value.id);
      if (indexStoreById !== -1) {
        await toggleFavorite(opportunity.value.id, opportunity.value, indexStoreById);
      }
    };

    const isCardFavorited = (): boolean => {
      return opportunity.value?.bl_favorito || false;
    };

    const menuItems = [
      { id: 1, text: 'Projeto', disabled: false },
      { id: 5, text: 'Mercado imobiliário', disabled: false },
      { id: 2, text: 'Demográfico', disabled: true },
      { id: 3, text: 'Socioeconômico', disabled: true },
      { id: 4, text: 'Empresas', disabled: true },
      { id: 6, text: 'Pontos de interesse', disabled: true },
      { id: 7, text: 'Educacional', disabled: true },
      { id: 8, text: 'Saúde Pública', disabled: true }
    ];

    const projectSubmenuItems = ref([
      { id: 1, text: 'Resumo executivo', active: true, disabled: false },
      { id: 2, text: 'Viabilidade', active: false, disabled: false },
      { id: 3, text: 'Potencial construtivo', active: false, disabled: false },
      { id: 4, text: 'Análise Financeira', active: false, disabled: false },
      { id: 5, text: 'Valor Geral de Vendas', active: false, disabled: true },
      { id: 6, text: 'Perfil do empreendimento', active: false, disabled: true },
      { id: 7, text: 'Valor residual do terreno', active: false, disabled: true },
      { id: 8, text: 'Modelo horizontal', active: false, disabled: true }
    ]);

    const demographicSubmenuItems = ref([
      { id: 1, text: 'Item1', active: true },
      { id: 2, text: 'Item2', active: false },
      { id: 3, text: 'Item3', active: false }
    ]);

    const realStateMarketSubmenuItems = ref([
      { id: 1, text: 'Anúncios', active: true, disabled: false },
      { id: 2, text: 'Evolução', active: false, disabled: true },
      { id: 3, text: 'CNO', active: false, disabled: true }
    ]);

    const selectedMenuItemText = ref(menuItems[0].text);
    const handleMenuItemClick = (itemId: number, itemText: string) => {
      const clickedItem = menuItems.find((item) => item.id === itemId);
      if (clickedItem && !clickedItem.disabled) {
        selectedMenuItem.value = itemId;
        selectedMenuItemText.value = itemText;
      }
    };

    const handleRedirectToMarket = () => {
      handleMenuItemClick(5, 'Mercado imobiliário');
      handleVisibleSection(5);
    };
    const emitter = require('tiny-emitter/instance');
    emitter.on('redirect-to-market', () => handleRedirectToMarket());

    const handleSubmenuItemMouseOver = (itemId: number) => {
      projectSubmenuItems.value.forEach((item) => {
        item.active = item.id === itemId;
      });
    };

    const handleSubmenuItemClick = (itemId: number) => {
      projectSubmenuItems.value.forEach((item) => {
        item.active = item.id === itemId;
      });

      const sectionId = `section-${itemId}`;
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    };

    const handleDemographicSubmenuItemMouseOver = (itemId: number) => {
      demographicSubmenuItems.value.forEach((item) => {
        item.active = item.id === itemId;
      });
    };

    const handleDemographicSubmenuItemClick = (itemId: number) => {
      demographicSubmenuItems.value.forEach((item) => {
        item.active = item.id === itemId;
      });
    };

    const handleRealStateMarketSubmenuItemMouseOver = (itemId: number) => {
      realStateMarketSubmenuItems.value.forEach((item) => {
        item.active = item.id === itemId;
      });
    };

    const handleRealStateMarketSubmenuItemClick = (itemId: number) => {
      realStateMarketSubmenuItems.value.forEach((item) => {
        item.active = item.id === itemId;
      });
    };

    const formatVgvValue = (value: any) => {
      if (value >= 1000000) {
        const formattedValue = (value / 1000000).toLocaleString("pt-BR", { maximumFractionDigits: 1 });
        return `R$${formattedValue}mi`;
      } else if (value >= 1000) {
        const formattedValue = (value / 1000).toLocaleString("pt-BR", { maximumFractionDigits: 0 });
        return `R$${formattedValue}mil`;
      } else {
        return `R$${value.toLocaleString("pt-BR")}`;
      }
    };

    onMounted(async () => {
      await fetchCompleteOpportunity();
      await fetchOpportunityById();
      backgroundImage.value = `url(${await completeOpportunity.value.thumb_completa})`

      breadcrumbItem.value = ['Marketplace', opportunity.value.tipologia_label];
      titleBreadcrumb.value = opportunity.value.tipologia_label;
      subtitleBreadcrumb.value = opportunity.value.localizacao;

      selectedMenuItemText.value = menuItems[0].text;

      isMounted.value = true;
    });

    const handleVisibleSection = (itemId: number) => {
      projectSubmenuItems.value.forEach((item) => {
        item.active = item.id === itemId;
      });
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          emit("SummaryExecutiveSection-visible", 1);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection);

    onMounted(() => {
      if (sectionMain.value) {
        observer.observe(sectionMain.value);
      }
    });

    return {
      opportunity,
      isLoading,
      handleClickBack,
      handleFavoriteClick,
      isCardFavorited,
      menuItems,
      projectSubmenuItems,
      selectedMenuItem,
      selectedMenuItemText,
      handleMenuItemClick,
      handleSubmenuItemMouseOver,
      handleSubmenuItemClick,
      demographicSubmenuItems,
      handleDemographicSubmenuItemMouseOver,
      handleDemographicSubmenuItemClick,
      titleBreadcrumb,
      breadcrumbItem,
      subtitleBreadcrumb,
      handleVisibleSection,
      sectionMain,
      completeOpportunity,
      backgroundImage,
      completeOpportunityStore,
      realStateMarketSubmenuItems,
      handleRealStateMarketSubmenuItemClick,
      handleRealStateMarketSubmenuItemMouseOver,
      isMounted,
      currentVGV,
      currentROI,
      opportunityRef,
    };
  }
});
