import { ActionTree, ActionContext } from "vuex";
import { Mutations } from "./mutations";
import { RootState } from "@/store";
import { State } from "./state";
import { OpportunityActionTypes } from "./action-types";
import { OpportunityMutationTypes } from "./mutation-types";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [OpportunityMutationTypes.SET_OPPORTUNITY](
    { commit }: AugmentedActionContext,
    opportunities: any
  ): void,
  [OpportunityMutationTypes.SET_OPPORTUNITY_FIELD](
    { commit }: AugmentedActionContext,
    field: any
  ): void,
  [OpportunityMutationTypes.SET_COMPLETE_OPPORTUNITY](
    { commit }: AugmentedActionContext,
    opportunities: any
  ): void,
  [OpportunityMutationTypes.SET_OPPORTUNITY_UNIQUE](
    { commit }: AugmentedActionContext,
    opportunity: any
  ): void,
  
}

export const actions: ActionTree<State, RootState> & Actions = {
  [OpportunityActionTypes.SET_OPPORTUNITY]({ commit }, opportunities: any) {
    commit(OpportunityMutationTypes.SET_OPPORTUNITY, opportunities)
  },
  [OpportunityActionTypes.SET_OPPORTUNITY_FIELD]({ commit }, field: any) {
    commit(OpportunityMutationTypes.SET_OPPORTUNITY_FIELD, field)
  },
  [OpportunityActionTypes.SET_COMPLETE_OPPORTUNITY]({ commit }, completeOpportunities: any) {
    commit(OpportunityMutationTypes.SET_COMPLETE_OPPORTUNITY, completeOpportunities)
  },
  [OpportunityActionTypes.SET_OPPORTUNITY_UNIQUE]({ commit }, opportunity: any) {
    commit(OpportunityMutationTypes.SET_OPPORTUNITY_UNIQUE, opportunity)
  },
};
