import { MutationTree } from "vuex";

import type { Filter, Filters  } from "@/@types";

import { State } from "./state";
import { FilterMutationTypes as MutationTypes } from "./mutation-types";

export type Mutations<S = State> = {
  [MutationTypes.SET_FILTER_INPUT](state: S, payload: Filter): void,
  [MutationTypes.SET_FILTER_LOCATION_CITY](state: S, payload: Filter): void,
  [MutationTypes.SET_FILTER_LOCATION_DISTRICT](state: S, payload: Filters): void,
  [MutationTypes.SET_FILTER_AREA](state: S, payload: Filters): void,
  [MutationTypes.SET_FILTER_ROI](state: S, payload: Filter): void,
  [MutationTypes.SET_FILTER_VGV](state: S, payload: Filters): void,
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_FILTER_INPUT](state: State, filterInput: Filter) {
    state.filterInput = filterInput;
  },
  [MutationTypes.SET_FILTER_LOCATION_CITY](state: State, filterLocationCity: Filter) {
    state.filterLocationCity = filterLocationCity;
  },
  [MutationTypes.SET_FILTER_LOCATION_DISTRICT](state: State, filterLocationDistrict: Filters) {
    state.filterLocationDistrict = filterLocationDistrict;
  },
  [MutationTypes.SET_FILTER_AREA](state: State, filterArea: Filters) {
    state.filterArea = filterArea;
  },
  [MutationTypes.SET_FILTER_ROI](state: State, filterRoi: Filter) {
    state.filterRoi = filterRoi;
  },
  [MutationTypes.SET_FILTER_VGV](state: State, filterVgv: Filters) {
    state.filterVgv = filterVgv;
  },
};
