
import { defineComponent, ref, onMounted, computed, watchEffect, nextTick } from "vue";
import { formatNumber } from '@/helpers/mask'
import { useStore } from "@/store";
import ResponsiveTable from "@/components/ResponsiveTable.vue";

export default defineComponent({
  name: "SummaryConstructivePotentialSection",
  components: {
    ResponsiveTable,
  },
  props: {
    showHr: {
      type: Boolean,
      default: false,
    },
    isBlockedOpportunity: {
      type: Boolean,
      default: false,
    },
  },
  setup( props, { emit }) {
    const store = useStore();
    const sectionMain = ref<HTMLElement | null>(null);
    const completeOpportunity = computed(() => store.getters.completeOpportunities);
    const opportunityById = computed(() => store.state.opportunity.opportunity);
    const eficiencia = computed(() => completeOpportunity.value.eficiencia);

    const getConstructivePotentialData = async () => {
      var dataList: any = [];
      let constructivePotential = completeOpportunity.value.pot_info[0];

      dataList.push( 
        ["Área do Lote", formatNumber(completeOpportunity.value.area_do_terreno)],
        ["Área do terreno proporcional", formatNumber(constructivePotential.area_zoneamento_ocupavel)],
        ["Área do atingimento viário/ambiental", formatNumber(constructivePotential.area_atingimento)],
        ["Área remanescente do terreno", formatNumber(constructivePotential.area_zoneamento_ocupavel)],
        ["Área máxima de aproveitamento", formatNumber(constructivePotential.area_max_aproveitamento)],
        ["Área máxima de ocupação", formatNumber(constructivePotential.area_taxa_ocupacao)],
        ["Área máxima de impermeabilização", formatNumber(constructivePotential.area_maxima_impermeabilizacao)],
        ["",""],
      )

      return dataList;
    }

    const getAreaChartData = async () => {
      var dataList: any = [];
      let quadroAreas = completeOpportunity.value.pot_info[0];

      dataList.push( 
        ["Pavimento Térreo", formatNumber(quadroAreas.area_terreo)],
        ["Demais Pavimentos", quadroAreas.area_demais_pavimentos],
        ["Subsolo", formatNumber(quadroAreas.subsolo)],
        ["Mezanino", quadroAreas.area_mezanino],
        ["Ático", quadroAreas.area_atico],
        ["Casa de Máquina", quadroAreas.casa_de_maquina],
        ["Reservatório", quadroAreas.reservatorio],
        ["Depósito Lixo e Gás", quadroAreas.deposito_de_lixo_e_ga],
      )

      return dataList;
    }

    const legendItems = ref([
      { label: "Viabilidade", color: "#D4A809" },
      { label: "Mercado imobiliário", color: "#4F3C88" },
      { label: "Sociodemografia", color: "#4FE48B" },
    ]);

    const constructivePotencialTable = ref<any>({});
    const areaChartTable = ref<any>({});
    const computableAreaTable = ref<any>({});
    const reachedCaTable = ref<any>({});
    const totalBuiltAreaTable = ref<any>({});
    const circulationDiscountTable = ref<any>({});
    const privateAreaTable = ref<any>({});
    const eficiencyTable = ref<any>({});

    const fillConstructivePotentialTable = async () => {
      const constructivePotencial = await getConstructivePotentialData();

      constructivePotencialTable.value = {
        circleColor: "#E0E0E0",
        title: "Potencial construtivo",
        headers: ["Classe", "Área (m²)"],
        data: constructivePotencial,
      };
    };

    const fillAreaChartTable = async () => {
      const areaChart = await getAreaChartData();

      areaChartTable.value = {
        circleColor: "#E0E0E0",
        title: "Quadro de áreas",
        headers: ["Classe", "Área (m²)"],
        data: areaChart,
      };
    };

    const fillTotalBuiltAreaTable = async () => {
      let totalBuiltArea = formatNumber(opportunityById.value.area_total_construida);
      totalBuiltAreaTable.value = {
        circleColor: "#E0E0E0",
        title: "Área total construída",
        headers: [totalBuiltArea + 'm²'],
        data: [[]],
      };
    };

    const fillPrivateAreaTable = async () => {
      let privateArea = formatNumber(opportunityById.value.area_privativa);
      privateAreaTable.value = {
        circleColor: "#E0E0E0",
        title: "Área privativa",
        headers: [privateArea + 'm²'],
        data: [[]],
      };
    };

    computableAreaTable.value = {
      circleColor: "#E0E0E0",
      title: "Área computável",
      headers: [completeOpportunity.value.area_computavel_final + 'm²'],
      data: [[]],
    };

    reachedCaTable.value = {
      circleColor: "#E0E0E0",
      title: "CA atingido",
      headers: [completeOpportunity.value.ca_atingido + '%'] ,
      data: [[]],
    };

    circulationDiscountTable.value = {
      circleColor: "#E0E0E0",
      title: "Desconto de circulação",
      headers: [formatNumber(completeOpportunity.value.desconto_circulacao) + 'm²'],
      data: [[]],
    };

    eficiencyTable.value = {
      circleColor: "#E0E0E0",
      title: "Eficiência",
      headers: [formatNumber(eficiencia.value) + '%'],
      data: [[]],
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          emit("SummaryConstructivePotentialSection-visible", 3);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection);
    const tableResizableHeight = ref<string>('');

    const adjustTableHeight = () => {
      const mapImg = document.getElementById('mapImage');
      if (mapImg) {
        const renderedHeight = mapImg.offsetHeight;
        const wantedHeight = renderedHeight - 35;
        tableResizableHeight.value = `${wantedHeight}px`;
      }
    }; 

    onMounted(async () => {
      fillConstructivePotentialTable();
      fillAreaChartTable();
      fillTotalBuiltAreaTable();
      fillPrivateAreaTable();
      if (sectionMain.value) {
        observer.observe(sectionMain.value);
      }

      await nextTick();
      adjustTableHeight();

      const mapImg = document.getElementById('mapImage');
      mapImg?.addEventListener('load', () => {
        adjustTableHeight();
      });

      watchEffect(() => {
        window.addEventListener("resize", adjustTableHeight);
        return () => {
          window.removeEventListener("resize", adjustTableHeight);
        };
      });
    });

    return {
      legendItems,
      constructivePotencialTable,
      areaChartTable,
      computableAreaTable,
      reachedCaTable,
      totalBuiltAreaTable,
      circulationDiscountTable,
      privateAreaTable,
      eficiencyTable,
      sectionMain,
      completeOpportunity,
      tableResizableHeight,
    };
  }
});
