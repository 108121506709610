
import { defineComponent, ref, computed, watch, onMounted } from "vue";
import Slider from "./Slider.vue";
import { formatNumberWithDesc, formatNumber } from "@/helpers/mask";

export default defineComponent({
  components: {
    Slider,
  },
  name: "CardCalc",
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    showInput: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      default: "m²",
    },
    valueStart: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 0.5,
    },
    scaleSize: {
      type: String,
      default: "medium",
    },
    showAsPercentual: {
      type: Boolean,
      default: false,
    },
    showAsNumber: {
      type: Boolean,
      default: false,
    },
    isIndirectCoasts: {
      type: Boolean,
      default: false,
    },
    isGroundCoasts: {
      type: Boolean,
      default: false,
    },
    valueList: {
      type: Array,
      required: false,
      default: () => [],
    },
    comissoes: {
      type: Number,
    },
    impostos: {
      type: Number,
    },
    marketing: {
      type: Number,
    },
    projeto: {
      type: Number,
    },
    areaPrivativaValue: {
      type: Number,
      default: 0,
    },
    areaConstruidaValue: {
      type: Number,
      default: 0,
    },
    isBlockedOpportunity: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const sliderValue = ref(props.value);

    const showInputAreaPrivativa = ref(false);
    const showInputAreaConstruida = ref(false);
    const showInputNumber = ref(false);

    const rawInputValue = ref(0);
    const inputNumberValue = ref('0,00');
    const areaPriviativaInput = ref(props.areaPrivativaValue);
    const areaConstruidaInput = ref(props.areaConstruidaValue);
    const index = ref(0);
    const inputValue = computed(() => rawInputValue.value);

    const toggleEditView = (isPrivateArea: boolean) => {
      if (isPrivateArea) {
        showInputAreaPrivativa.value = !showInputAreaPrivativa.value;
      } else {
        showInputAreaConstruida.value = !showInputAreaConstruida.value;
      }
    };

    const toggleInputNumberEdit = () => {
      showInputNumber.value = !showInputNumber.value;
      if (!showInputNumber.value) {
        formatAndSetRawInputValue();
      }
    }

    const updateImovelDataItem = (event: any, index: any) => {
      emit("input-value-updated", { group: 'imovelDataItems', index: index, value: event.target.value })
      if (index === 1) {
        areaPriviativaInput.value = event.target.value
      } else {
        areaConstruidaInput.value = event.target.value
      }
    }

    // Input Value Custo do Terreno - start
    const formatAndSetRawInputValue = () => {
      const formattedValue = formatNumber(inputNumberValue.value);
      inputNumberValue.value = formattedValue;
      emit("input-value-updated", { group: 'landCoastItems', value: inputNumberValue.value })
      showInputNumber.value = false;
    };
    // Input Value Custo do Terreno - end


    const handleInputEdit2 = (event: any) => {
      areaConstruidaInput.value = event.target.value;
    };

    const formattedSliderValue = computed(() => {
      return `R$ ${sliderValue.value.toLocaleString()} mil`;
    });

    const handleSliderValue = (newValue: any) => {
      sliderValue.value = newValue;
      emit("Slider-value", sliderValue.value);
    };

    // Custos indiretos - start
    const handleSliderValueIndirectCoast = (newValue: any, index: number) => {
      switch (index) {
        case 0:
          emit("Slider-value-project", newValue);
          break;
        case 1:
          emit("Slider-value-marketing", newValue);
          break;
        case 2:
          emit("Slider-value-impostos", newValue);
          break;
        case 3:
          emit("Slider-value-comissoes", newValue);
          break;
      }
    };
    // Custos indiretos - end

    const getValueItem = () => {
      return props.valueList[index.value];
    };

    watch(inputValue, (newValue) => {
      emit("LctsCardCalc-inputValue", newValue);
    });

    onMounted(() => {
      // Emitindo para iniciar os valores
      emit("Slider-value-project", 2);
      emit("Slider-value-marketing", 2);
      emit("Slider-value-impostos", 4);
      emit("Slider-value-comissoes", 6);
    });

    return {
      formattedSliderValue,
      sliderValue,
      toggleEditView,
      rawInputValue,
      inputValue,
      handleSliderValue,
      formatNumber,
      handleSliderValueIndirectCoast,
      getValueItem,
      formatNumberWithDesc,
      showInputAreaPrivativa,
      showInputAreaConstruida,
      areaPriviativaInput,
      areaConstruidaInput,
      handleInputEdit2,
      formatAndSetRawInputValue,
      updateImovelDataItem,
      toggleInputNumberEdit,
      showInputNumber,
      inputNumberValue,
    };
  },
});
