import type { Filter, Filters } from "@/@types";

export type State = {
  filterInput: Filter,
  filterLocationCity: Filter,
  filterLocationDistrict: Filters,
  filterArea: Filters,
  filterRoi: Filter,
  filterVgv: Filters,
};

export const state: State = {
  filterInput: '',
  filterLocationCity: '',
  filterLocationDistrict: [''],
  filterArea: ['',''],
  filterRoi: '0',
  filterVgv: ['',''],
};
