import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "triggerRef",
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showTooltip && _ctx.showTooltip(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hideTooltip && _ctx.hideTooltip(...args)))
  }, [
    _renderSlot(_ctx.$slots, "trigger", {}, undefined, true),
    (_ctx.isVisible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          ref: "tooltipRef",
          class: _normalizeClass(["custom-tooltip", { 'small': _ctx.small }])
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2))
      : _createCommentVNode("", true)
  ], 544))
}