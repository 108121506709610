
import { defineComponent, ref } from "vue";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import { LatLngTuple } from "leaflet";

export default defineComponent({
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  props: {
    latLonList: {
      type: Array as () => Array<LatLngTuple>,
      default: () => [],
    },
    centerLat: {
      type: Number,
    },
    centerLng: {
      type: Number,
    },
    zoom: {
      type: Number,
    },
  },

  setup(props) {
    const center = ref([props.centerLat, props.centerLng] as LatLngTuple);
    const showOpenStreetMap = ref(true);

    const handleImageClick = () => {
      showOpenStreetMap.value = !showOpenStreetMap.value;
    };

    return {
      center,
      handleImageClick,
      showOpenStreetMap
    };
  },
});
