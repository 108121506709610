
import { defineComponent, ref, computed, onMounted } from "vue";
import Tooltip from "@/components/Tooltip.vue";
import { useRoute } from "vue-router";
import { useStore } from "@/store";
import router from "@/router/index";
import { formatNumber } from "@/helpers/mask";
import { OpportunityMutationTypes } from "@/store/modules/opportunity/mutation-types";
import CustomTooltip from "@/components/CustomTooltip.vue";

export default defineComponent({
  components: {
    Tooltip,
    CustomTooltip,
  },
  name: "Card",
  props: {
    map: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    imageNameTitle: {
      type: String,
      default: "",
    },
    lotsImage: {
      type: String,
      default: "",
    },
    cityAndState: {
      type: String,
      default: "",
    },
    location: {
      type: String,
      default: "",
    },
    areaLote: {
      type: String,
      default: "",
    },
    recommendation: {
      type: String,
      default: "",
    },
    nearby: {
      type: String,
      default: "",
    },
    tagVgv: {
      type: String,
      default: "VGV",
    },
    tagVgvValue: {
      type: String,
      default: "",
    },
    tagVgvColor: {
      type: String,
      default: "#bca9f5",
    },
    tagRoi: {
      type: String,
      default: "ROI",
    },
    tagRoiValue: {
      type: String,
      default: "",
    },
    iconLeft: {
      type: String,
      default: "fa-regular fa-flag",
    },
    iconRight: {
      type: String,
      default: "",
    },
    imageMapName: {
      type: String,
      default: "",
    },
    favorite: {
      type: Number,
      default: 0,
    },
    id: {
      type: Number,
      default: -1,
    },
    circleColor: {
      type: String,
      default: "#F5BF80",
    },
    biURL: {
      type: String,
      default: "",
    },
    gidImovel: {
      type: Number,
      default: 0,
    },
    status: {
      type: String,
      default: "",
    },
    disableStudyView: {
      type: Boolean,
      default: false,
    },
    isPdf: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    isToShowAreaLote: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const routeName = computed(() => route.name);

    const iconTitle = computed(() => {
      try {
        const icon = require(`@/assets/images/${props.imageNameTitle}.svg`);
        return icon;
      } catch (error) {
        return '';
      }
    });

    const firstCardRef = ref<HTMLElement | null>(null);
    const showTooltip = ref(false);

    const tooltipTitle = "Categoria";
    const tooltipInfo = "Outras informações";
    const tooltipData = {
      "Informação X": "TESTESTE-X",
      "Informação Y": "TESTESTE-Y",
      "Informação Z": "TESTESTE-Z",
    };

    const formattedAreaLote = computed(() => {
      if (props.areaLote === "" || props.areaLote === null) {
        return '–';
      }
      return `${formatNumber(props.areaLote)}m²`;
    });

    const handleOpportunityClick = (isPdf: boolean) => {
      if (isPdf) {
        return window.open(props.biURL, '_blank');
      }
      if (props.biURL) {
        store.commit(OpportunityMutationTypes.SET_SELECTED_BI_OPPORTUNITY_URL, props.biURL);
        router.push({ name: "bi-opportunity", params: { id: props.gidImovel } });
      }
    };

    const whatsappUrl = computed(() => {
      let message = props.status !== 'analise' ? `Olá, estou interessado no imóvel de Ref: ${props.gidImovel}, ${props.location}. Poderia me dar mais informações?` : `Olá, estou interessado no imóvel de Localização: ${props.location}. Poderia me dar mais informações?`;
      const encodedMessage = encodeURIComponent(message);
      const phoneNumber = '5548988480769';
      return `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    });

    const handleWhatsAppClick = () => {
      window.open(whatsappUrl.value, '_blank');
    };

    const cardTagMargin = computed(() => {
      if (firstCardRef.value) {
        const firstCardWidth = firstCardRef.value.offsetWidth;
        const additionalPadding = firstCardWidth + 10;
        return `margin-left: ${additionalPadding}px`;
      }
      return "";
    });

    const defaultImageSrc = ref('https://locates.com.br/bis/land4.png');

    const handleImageError = (event: any) => {
      event.target.src = defaultImageSrc.value;
    };

    onMounted(() => {
      cardTagMargin.value;
    });

    return {
      iconTitle,
      firstCardRef,
      cardTagMargin,
      showTooltip,
      routeName,
      tooltipTitle,
      tooltipInfo,
      tooltipData,
      formattedAreaLote,
      handleOpportunityClick,
      handleWhatsAppClick,
      handleImageError,
      defaultImageSrc,
    };
  },
});
