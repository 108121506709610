
import { ref, computed, PropType, defineComponent } from "vue";
import { useStore } from "@/store";
import TablePagination from "./TablePagination.vue";

export default defineComponent({
  name: "ResponsiveTable",
  components: { TablePagination },
  props: {
    isCodeZone: {
      type: Boolean,
      default: false
    },
    codeZoneColors: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    heatMapColors: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    tableTitle: {
      type: String,
      default: "",
      required: true,
    },
    tableHeaders: {
      type: Array as PropType<string[]>,
      default: () => [],
      required: true,
    },
    tableData: {
      type: Array as PropType<string[][]>,
      default: () => [],
      required: true,
    },
    pointColor: {
      type: String,
      default: "#000",
    },
    showHeatMap : {
      type: Boolean,
      default: false,
    },
    isBlockedOpportunity: {
      type: Boolean,
      default: false,
    },
    stripedTable : {
      type: Boolean,
      default: false,
    },
    showPagination : {
      type: Boolean,
      default: false,
    },
    hasFilterClick : {
      type: Boolean,
      default: false,
    },
    tableMaxHeight: {
      type: String,
    },
    taxHeight: {
      type: String,
    },
    dontWrap: {
      type: Boolean,
      default: false,
    },
    noContainerClass: {
      type: Boolean,
      default: false,
    }
  },

  setup(props, { emit }) {
    const store = useStore();
    const completeOpportunity = computed(() => store.getters.completeOpportunities);

    // PAGINAÇÃO - INÍCIO
    const selectedIndex = ref(-1);
    const currentPage = ref(1);
    const itemsPerPage = 10;
    const totalItems = computed(() => props.tableData.length);
    const totalPages = computed(() => Math.ceil(totalItems.value / itemsPerPage));

    const displayedPages = computed(() => {
      const maxDisplayedPages = 5;

      let startPage = Math.max(1, currentPage.value - Math.floor(maxDisplayedPages / 2));
      let endPage = startPage + maxDisplayedPages - 1;

      if (endPage > totalPages.value) {
        endPage = totalPages.value;
        startPage = Math.max(1, endPage - maxDisplayedPages + 1);
      }

      const pages = [];
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      return pages;
    });

    const goToPage = (page: number) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };
    
    const getVisibleData = computed(() => {
      if(!props.showPagination){
        return props.tableData;
      }
      const startIndex = (currentPage.value - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return props.tableData.slice(startIndex, endIndex);
    });
    // PAGINAÇÃO - FIM
    
    const handleRowClick = (index: number, item: any) => {
      if (props.hasFilterClick && item[1] > 0) {
        if (selectedIndex.value === index) {
          selectedIndex.value = -1;
          emit("lctsCardTable-item-desselected", 0);
        } else {
          selectedIndex.value = index;
          emit("lctsCardTable-item-selected", item);
        }
      }
    };

    const redirectLink = (data: string, index:number) => {
      if (data.includes('Acesse clicando aqui')) {
        window.open(completeOpportunity.value.mercado_info.anuncios[index].st_link, '_blank');
      }
    };

    return {
      goToPage,
      selectedIndex,
      currentPage,
      totalPages,
      totalItems,
      displayedPages,
      getVisibleData,
      handleRowClick,
      redirectLink,
    };
  },
});
