
import { defineComponent, onMounted, ref } from 'vue';
import router from "@/router/index";
import Card from "@/components/Card.vue";

export default defineComponent({
  name: 'PanelList',
  components: { Card },
  props: {
    opportunities: {
      type: Array,
      default: () => [],
    },
    isFunding: {
      type: Boolean,
      default: false,
    },
    isObservatory: {
      type: Boolean,
      default: false,
    },
    isMarketplace: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    enum CardText {
      FUNDING = 'Envie sua oportunidade/projeto para conectar com nossa rede de investidores!',
      REPORTS = 'Envie uma nova oportunidade para receber nossos estudos!',
      OBSERVATORY = 'Solicite o observatório de uma nova cidade!',
      MARKETPLACE = 'Envie uma oportunidade para o Marketplace!',
    }

    const titleNewOpportunity = props.isFunding ? CardText.FUNDING : props.isObservatory ? CardText.OBSERVATORY : props.isMarketplace ? CardText.MARKETPLACE : CardText.REPORTS;

    const getImageNameTitle = (cardTitle: string) => {
      switch (cardTitle) {
        case "Prédio Multifamiliar":
        case "Multifamiliar":
          return "predio-multifamiliar";
        case "Multifamiliar":
          return "predio-multifamiliar";
        case "Loteamento":
          return "loteamento";
        case "Prédio Comercial":
          return "predio-comercial";
        case "Galeria Comercial":
          return "galeria-comercial";
        case "Master Plan":
          return "masterplan";
        case "Galpão":
          return "galpao";
        case "DEMONSTRAÇÃO":
          return "demo";
        default:
          break;
      }
    };

    const formatVgvValue = (value?: any) => {
      if (value && value >= 1000000) {
        const formattedValue = (value / 1000000).toLocaleString("pt-BR", {
          maximumFractionDigits: 1,
        });
        return `R$${formattedValue}mi`;
      } else if (value && value >= 1000) {
        const formattedValue = (value / 1000).toLocaleString("pt-BR", {
          maximumFractionDigits: 0,
        });
        return `R$${formattedValue}mil`;
      } else if (value) {
        return `R$${value.toLocaleString("pt-BR")}`;
      }
    };

    const addNewOpportunity = () => {
      router.push({ name: 'send' });
    }

    return {
      getImageNameTitle,
      formatVgvValue,
      addNewOpportunity,
      titleNewOpportunity,
    };
  },
});
