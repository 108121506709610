
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'PageTransition',
  setup() {
    const route = useRoute();

    const currentComponent = computed(() => {
      const lastMatched = route.matched[route.matched.length - 1];
      if (lastMatched) {
        const components = lastMatched.components;
        if (components && components.default) {
          return components.default;
        }
      }
      return null;
    });

    return {
      currentComponent
    };
  }
});
