
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import Slider from "@/components/Slider.vue";
import CardCalc from "@/components/CalcCard.vue";
import { useStore } from "@/store";
import { formatNumber } from "@/helpers/mask";

export default defineComponent({
  name: "SummaryFinancialAnalysisSectionTest",
  components: {
    CardCalc,
    Slider,
  },
  props: {
    showHr: {
      type: Boolean,
      default: false,
    },
    isBlockedOpportunity: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const sectionMain = ref<HTMLElement | null>(null);
    const store = useStore();
    const completeOpportunity = computed(() => store.getters.completeOpportunities);
    const opportunityById = computed(() => store.state.opportunity.opportunity);
    const isMobile = ref(window.innerWidth < 980);
    
    // Dados do imóvel - START
    const inputAreaPrivativaValue = ref(opportunityById.value.area_privativa);
    const inputAreaTotalConstruidaValue = ref(opportunityById.value.area_total_construida);

    const formattedAreaPrivativa = computed(() => {
      return formatNumber(inputAreaPrivativaValue.value) + " m²";
    });
    const formattedAreaTotalConstruida = computed(() => {
      return formatNumber(inputAreaTotalConstruidaValue.value) + " m²";
    });

    const eficiencia = computed(() => {
      return formatNumber((inputAreaPrivativaValue.value / inputAreaTotalConstruidaValue.value) * 100);
    });

    const efficiencyValue = ref<string>(eficiencia.value);
    const formattedEfficiency = computed(() => {
      return `${efficiencyValue.value}%`;
    });

    const imovelDataItems = ref([
      {
        description: "Área do lote",
        icon: "",
        value: `${formatNumber(completeOpportunity.value.area_do_terreno)} m²`,
        showSlider: false,
      },
      {
        description: "Área privativa",
        icon: "bi bi-pencil",
        value: formattedAreaPrivativa,
        showSlider: false,
      },
      {
        description: "Área total construída",
        icon: "bi bi-pencil",
        value: formattedAreaTotalConstruida,
        showSlider: false,
      },
      {
        description: "Eficiência",
        icon: "",
        value: formattedEfficiency,
        showSlider: false,
      },
    ]);
    // Dados do imóvel - END

    // VGV - START
    const valorMetroQuadradoSlider = ref<number>(opportunityById.value.valor_media);

    const vgv = computed(() => {
      const calcVgv = (valorMetroQuadradoSlider.value * inputAreaPrivativaValue.value);
      return calcVgv;
    });
    
    const vgvValue = ref<number>(vgv.value);
    // VGV - END

    // Custos do terreno - START
    const inputAdiantamentoDinheiroValue = ref(0);
    const permutaSlider = ref<number>(completeOpportunity.value.valor_permuta);

    const permutaCalc = computed(() => {
      return (permutaSlider.value / 100) * vgvValue.value;
    });

    const permutaValue = ref(permutaCalc.value);

    const landCoastItems = ref([
      {
        description: "Adiantamento dinheiro",
        icon: "",
        value: "Insira um valor",
        showSlider: false,
      },
      {
        description: "Média m² na região",
        icon: "",
        value: "R$ " + formatNumber(opportunityById.value.valor_media),
        showSlider: false,
      },
      {
        description: "Permuta",
        startValue: 0,
        endValue: 100,
        icon: "",
        value: `R$ ${formatNumber(permutaValue.value)}`,
        showSlider: true,
        showAsPercentual: true,
      },
    ]);
    // Custos do terreno - END

    // VGV Líquido - START
    const vgvLiquidoCalc = computed(() => {
      return vgv.value - permutaValue.value;
    });

    const vgvLiquidoValue = ref<number>(vgvLiquidoCalc.value);
    // VGV Líquido - END

    // Custos Diretos de Obra - START
    const custosObraSlider = ref<number>(opportunityById.value.cub);
    const custosObraCalc = computed(() => {
      return custosObraSlider.value * inputAreaTotalConstruidaValue.value;
    });
    const custosObraValue = ref<number>(custosObraCalc.value);
    // Custos Diretos de Obra - END

    // Custos Indiretos - START
    const comissoes_final = ref(0);
    const impostos_final = ref(0);
    const marketing_final = ref(0);
    const projeto_final = ref(0);
    const valueList = ref(<any>[]);
    const indirectCoasts = ref([
      {
        description: "Projetos/Aprovação",
        icon: "",
        value: completeOpportunity.value.projeto,
        startValue: 0,
        endValue: 6,
        showSlider: true,
        sliderWidth: "97px",
        sliderLineWidth: "80px",
        scale: "small",
        sliderMaxWidth: "123px",
        showAsPercentual: true,
      },
      {
        description: "Marketing",
        icon: "",
        value: completeOpportunity.value.marketing,
        startValue: 0,
        endValue: 6,
        showSlider: true,
        sliderWidth: "97px",
        sliderLineWidth: "80px",
        scale: "small",
        sliderMaxWidth: "123px",
        showAsPercentual: true,
      },
      {
        description: "Impostos",
        icon: "",
        startValue: 0,
        endValue: 10,
        value: completeOpportunity.value.impostos,
        showSlider: true,
        sliderWidth: "97px",
        sliderLineWidth: "80px",
        scale: "small",
        sliderMaxWidth: "123px",
        showAsPercentual: true,
      },
      {
        description: "Comissões",
        icon: "",
        startValue: 0,
        endValue: 10,
        value: completeOpportunity.value.comissoes,
        showSlider: true,
        sliderWidth: "97px",
        sliderLineWidth: "80px",
        scale: "small",
        sliderMaxWidth: "123px",
        showAsPercentual: true,
      },
    ]);
    watch(() => vgvLiquidoValue.value, (newVgvLiquido) => {
      indirectCoasts.value[0].value = `R$ ${formatNumber(((projeto_final.value / 100) * newVgvLiquido), 0)}`;
      indirectCoasts.value[1].value = `R$ ${formatNumber(((marketing_final.value / 100) * newVgvLiquido), 0)}`;
      indirectCoasts.value[2].value = `R$ ${formatNumber(((impostos_final.value / 100) * newVgvLiquido), 0)}`;
      indirectCoasts.value[3].value = `R$ ${formatNumber(((comissoes_final.value / 100) * newVgvLiquido), 0)}`;
      }
    );
    // Custos Indiretos - END

    // Indicadores - START
    const investimentoPrevisto = ref(
      custosObraValue.value +
      comissoes_final.value +
      impostos_final.value +
      marketing_final.value +
      projeto_final.value +
      inputAdiantamentoDinheiroValue.value
    )
    const lucroLiquido = ref(vgvLiquidoValue.value - investimentoPrevisto.value);
    const margemLiquida = ref(lucroLiquido.value / vgvValue.value);

    const updateInvestimentoPrevisto = () => {
      investimentoPrevisto.value =
        custosObraValue.value +
        (comissoes_final.value / 100) * vgvLiquidoValue.value +
        (impostos_final.value / 100) * vgvLiquidoValue.value +
        (marketing_final.value / 100) * vgvLiquidoValue.value +
        (projeto_final.value / 100) * vgvLiquidoValue.value +
        Number(inputAdiantamentoDinheiroValue.value);
    } 
    // Indicadores - END

    // ROI - START
    const roi = ref(((vgvLiquidoValue.value - investimentoPrevisto.value) * 100) / investimentoPrevisto.value);
    const formato_percentual = {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    };
    const formatROI = (value: any) => {
      if (typeof value === "number" && !isNaN(value)) {
        return value.toLocaleString("pt-br", formato_percentual) + "%";
      }
      return "0%";
    };
    // ROI - END

    // Funções de atualização dos valores - START
    const updateCalculations = () => {
      efficiencyValue.value = formatNumber((inputAreaPrivativaValue.value / inputAreaTotalConstruidaValue.value) * 100);
      vgvValue.value = ( valorMetroQuadradoSlider.value * inputAreaPrivativaValue.value);
      permutaValue.value = (permutaSlider.value / 100) * vgvValue.value;
      landCoastItems.value[2].value = `R$${formatNumber(permutaValue.value)}`;
      vgvLiquidoValue.value = vgvValue.value - permutaValue.value;
      custosObraValue.value = custosObraSlider.value * inputAreaTotalConstruidaValue.value;
      updateInvestimentoPrevisto();
      lucroLiquido.value = vgvLiquidoValue.value - investimentoPrevisto.value;
      margemLiquida.value = lucroLiquido.value / vgvValue.value;
      roi.value = ((vgvLiquidoValue.value - investimentoPrevisto.value) * 100) / investimentoPrevisto.value;
    };

    const handleInputValueUpdated = (newValue: any) => {
      if (newValue.group === 'imovelDataItems') {
        if (newValue.index === 1) {
          inputAreaPrivativaValue.value = newValue.value;
        } else if (newValue.index === 2) {
          inputAreaTotalConstruidaValue.value = newValue.value;
        }
      }

      if (newValue.group === 'landCoastItems') {
        inputAdiantamentoDinheiroValue.value = newValue.value;
      }

      updateCalculations();
    }

    const handleSliderChange = (newValue: any, sliderType: string) => {
      if (sliderType === 'sectionVGV') {
        valorMetroQuadradoSlider.value = newValue;
      }
      
      if (sliderType === 'sectionGroundCoasts') {
        permutaSlider.value = newValue;
      }

      if (sliderType === 'custosObraSection') {
        custosObraSlider.value = newValue;
      }

      updateCalculations();
    };

    const handleIndirectCoastsChange = (newValue: any, index: number) => {
      indirectCoasts.value[index].value = `R$ ${formatNumber(
        ((newValue / 100) * vgvLiquidoValue.value),
        0
      )}`;

      switch (index) {
        case 0:
          projeto_final.value = newValue;
          break;
        case 1:
          marketing_final.value = newValue;
          break;
        case 2:
          impostos_final.value = newValue;
          break;
        case 3:
          comissoes_final.value = newValue;
          break;
      }
      updateCalculations();
    };

    // Funções de atualização dos valores - END

    // TODO: Método para resetar os valores ficará para depois. 
    // const resetCalculator = () => {
    //   inputAreaPrivativaValue.value = opportunityById.value.area_privativa;
    //   inputAreaTotalConstruidaValue.value = opportunityById.value.area_total_construida;
    //   valorMetroQuadradoSlider.value = opportunityById.value.valor_media;
    //   permutaSlider.value = completeOpportunity.value.valor_permuta;
    //   custosObraSlider.value = opportunityById.value.cub;
    //   comissoes_final.value = 0;
    //   impostos_final.value = 0;
    //   marketing_final.value = 0;
    //   projeto_final.value = 0;

    //   handleSliderChange(opportunityById.value.valor_media, 'sectionVGV')
    //   handleSliderChange(completeOpportunity.value.valor_permuta, 'sectionGroundCoasts')
    //   updateCalculations();
    // };

    // Intersection - start
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          emit("SummaryFinancialAnalysisSection-visible", 4);
        }
      });
    };
    const observer = new IntersectionObserver(handleIntersection);
    // Intersection - end

    onMounted(async () => {
      if (sectionMain.value) {
        observer.observe(sectionMain.value);
      }
      window.addEventListener('resize', () => {
        isMobile.value = window.innerWidth < 980;
      });
    });

    return {
      sectionMain,
      opportunityById,
      completeOpportunity,
      inputAreaPrivativaValue,
      inputAreaTotalConstruidaValue,
      eficiencia,
      vgvValue,
      imovelDataItems,
      landCoastItems,
      formatNumber,
      handleInputValueUpdated,
      handleSliderChange,
      valorMetroQuadradoSlider,
      permutaSlider,
      vgvLiquidoValue,
      custosObraSlider,
      custosObraValue,
      indirectCoasts,
      handleIndirectCoastsChange,
      valueList,
      investimentoPrevisto,
      lucroLiquido,
      margemLiquida,
      formatROI,
      roi,
      isMobile,
      // resetCalculator,
    };
  },
});
