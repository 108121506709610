
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "OpportunityHeader",
  props: {
    breadcrumbs: {
      type: Array as () => Array<string>,
      default: () => ["Marketplace", ""],
    },
    title: {
      type: String,
      default: "TITLE",
    },
    subtitle: {
      type: String,
      default: "Trindade, Florianópolis, Santa Catarina",
    },
    showHr: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array as () => Array<string>,
    },
    vgv: {
      type: String,
    },
    roi: {
      type: String,
    },
    opportunityRef: {
      type: String,
    },
  },

  setup( props, { emit }) {
    const sectionMain = ref<HTMLElement | null>(null);
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
          if (entry.isIntersecting) {
            emit("LctsBreadcrumbWithModal-visible", 1);
          }
      });
    };

    const observer = new IntersectionObserver(handleIntersection);

    onMounted(() => {
      if (sectionMain.value) {
        observer.observe(sectionMain.value);
      }
    });
    return {
      sectionMain
    }
  }
});
