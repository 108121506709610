
import { defineComponent, ref, computed } from "vue";

export default defineComponent({
  name: "InputCheckbox",
  emits: ["lctsCheckbox-changed"],
  props: {
    text: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isChecked = ref(props.checked);
    const isDisabled = computed(() => props.disabled);
    const checkboxText = computed(() => props.text);

    const handleCheckboxChange = (event: Event) => {
      const isCheckedValue = (event.target as HTMLInputElement).checked;
      emit("lctsCheckbox-changed", isCheckedValue);
    };

    return {
      isChecked,
      isDisabled,
      checkboxText,
      handleCheckboxChange,
    };
  },
});
