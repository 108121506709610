<template>
  <div class="pagination justify-content-end pagination-container">
    <nav>
      <ul class="pagination">
        <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
          <a class="page-link" @click="goToPage(currentPage - 1)" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li v-for="page in displayedPages" :key="page" class="page-item" :class="{ 'active': page === currentPage }">
          <a class="page-link" @click="goToPage(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
          <a class="page-link" @click="goToPage(currentPage + 1)" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
    <div class="total-records">
      Total de registros: {{ totalItems }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    displayedPages: {
      type: Array,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
  },
  methods: {
    goToPage(page) {
      this.$emit("goToPage", page);
    },
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.pagination-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 0 20px;
  flex-direction: column;
}

.pagination .page-link {
  color: #4f3c88 !important;
  border-color: #3c2963 !important;
}

.pagination .page-link:hover {
  background-color: #edeaea !important;
  cursor: pointer;
}

.pagination .page-item.active .page-link {
  background-color: #bca9f5 !important;
}

.pagination ul {
  list-style: none;
  display: flex;
  gap: 8px;
  margin: 0;
  padding: 0;
}

.pagination li {
  display: inline-block;
}

.pagination a.page-link {
  display: block;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #4f3c88;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.pagination a.page-link:hover {
  background-color: #3c2963;
}

.pagination li.page-item.active a.page-link {
  background-color: #3c2963;
  color: white;
}

.pagination a.page-link.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.total-records {
  padding-top: 15px;
  margin-left: 10px;
  font-size: 12px;
  color: #666; 
}
</style>
