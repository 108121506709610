
import { defineComponent, reactive, toRaw, onMounted, ref } from "vue";
import Button from "@/components/Button.vue";
import InputTextField from "@/components/InputTextfield.vue";
import LoginCarousel from "@/components/LoginCarousel.vue";
import { GenericResponse } from "@/services/user/types";
import { resetPassword } from "@/services/user/userService";
import { LoadingMutationTypes } from "@/store/modules/loading/mutation-types";
import { UserMutationTypes } from "@/store/modules/user/mutation-types";
import router from "@/router/index";
import { useStore } from "@/store";
import { useRoute } from 'vue-router';
import { useToast } from "vue-toastification";
import Password from "@/components/Password.vue";

interface PasswordForm {
  new_password: string;
  confirmPassword: string;
}

interface ResetPasswordPayload {
  id: string;
  new_password: string;
}

export default defineComponent({
  name: "RecoverPassword",
  components: {
    Button,
    InputTextField,
    LoginCarousel,
    Password,
  },
  setup() {
    const store = useStore();
    const toast = useToast();

    const form = reactive<PasswordForm>({
      new_password: "",
      confirmPassword: "",
    });

    const emailUrl = ref<any>("");
    const idURL = ref('');
    const isPasswordValid = ref(false);
    
    onMounted(() => {
      const route = useRoute();
      idURL.value = route.query.id as string;
    });

    const handleInputNewPasswordValue = (value: string) => {
      form.new_password = value;
    };

    const handleInputPasswordIsValid = (value: boolean) => {
      isPasswordValid.value = value;
    };

    const handleInputConfirmPasswordValue = (value: string) => {
      form.confirmPassword = value;
    };

    const handleButtonClick = async (passwordForm: PasswordForm) => {
      const rawFormLogin = toRaw(passwordForm);

      const payload = {
        id: idURL.value,
        new_password: rawFormLogin.new_password,
      };

      try {
        const result = await resetPassword(payload);
        store.commit(LoadingMutationTypes.SET_LOADING, false);
        store.commit(UserMutationTypes.SET_EMAIL, emailUrl.value);

        goToFinalization();
      } catch (error) {
        showErrorToast("Não foi possível redefinir sua senha. Tente novamente.");
        store.commit(LoadingMutationTypes.SET_LOADING, false);
        store.commit(UserMutationTypes.SET_EMAIL, emailUrl.value);
      }
    };

    const goToFinalization = () => {
      router.push({ name: "recover-finalization" });
    };

    const showErrorToast = (message: string) => {
      toast.error(message, {
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    };

    return {
      handleButtonClick,
      handleInputNewPasswordValue,
      handleInputConfirmPasswordValue,
      form,
      handleInputPasswordIsValid,
      isPasswordValid,
    };
  },
});
