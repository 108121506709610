
import router from "@/router/index";
import { defineComponent, ref, onMounted } from 'vue';
import { RouterLink } from 'vue-router';
import { logout } from '@/helpers/auth';
import { getUserById } from '@/services/user/userService';
import CustomTooltip from './CustomTooltip.vue';

export default defineComponent({
  components: {
    RouterLink,
    CustomTooltip,
  },
  setup() {
    const activeItem = ref('');
    const currentUser = ref<any>(null);

    const getUser = async () => {
      try {
        const userData = localStorage.getItem("userData");
        if (!userData) return;
        const userId = JSON.parse(userData).user_id;
        const result = await getUserById(userId);
        if (result) {
          currentUser.value = result;
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const setActiveItem = (item: string) => {
      activeItem.value = item;
    };

    const goToEditProfile = () => {
      router.push('/account');
    };

    const goToAccountPage = (tab: string) => {
      router.push(`/account/${tab}`);
    };

    onMounted(() => {
      getUser();
    });

    return {
      activeItem,
      setActiveItem,
      logout,
      currentUser,
      goToEditProfile,
      goToAccountPage,
    };
  }
});
