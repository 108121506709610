import { MutationTree } from "vuex";

import type { Loading } from "@/@types";

import { State } from "./state";
import { LoadingMutationTypes as MutationTypes } from "./mutation-types";

export type Mutations<S = State> = {
  [MutationTypes.SET_LOADING](state: S, payload: Loading): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_LOADING](state: State, isLoading: Loading) {
    state.isLoading = isLoading;
  },
};
