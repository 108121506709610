import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/toMap.svg'
import _imports_1 from '@/assets/images/toSatelite.svg'


const _withScopeId = n => (_pushScopeId("data-v-69ed90d3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_l_tile_layer = _resolveComponent("l-tile-layer")!
  const _component_l_marker = _resolveComponent("l-marker")!
  const _component_l_map = _resolveComponent("l-map")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_l_map, {
      ref: "map",
      zoom: _ctx.zoom,
      options: {attributionControl: false},
      center: _ctx.center
    }, {
      default: _withCtx(() => [
        (_ctx.showOpenStreetMap)
          ? (_openBlock(), _createBlock(_component_l_tile_layer, {
              key: 0,
              url: "http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}",
              "layer-type": "base",
              name: "OpenStreetMap"
            }))
          : (_openBlock(), _createBlock(_component_l_tile_layer, {
              key: 1,
              url: "http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
              "layer-type": "base",
              name: "Positron"
            })),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.latLonList, (latLon, index) => {
          return (_openBlock(), _createBlock(_component_l_marker, {
            key: index,
            "lat-lng": latLon
          }, null, 8, ["lat-lng"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["zoom", "center"]),
    (_ctx.showOpenStreetMap)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _imports_0,
          class: "map-overlay",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleImageClick && _ctx.handleImageClick(...args)))
        }))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _imports_1,
          class: "map-overlay",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleImageClick && _ctx.handleImageClick(...args)))
        }))
  ]))
}