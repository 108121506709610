import { ActionTree, ActionContext } from "vuex";
import { Mutations } from "./mutations";
import { RootState } from "@/store";
import { State } from "./state";
import { FilterActionTypes } from "./action-types";
import { FilterMutationTypes } from "./mutation-types";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [FilterMutationTypes.SET_FILTER_INPUT](
    { commit }: AugmentedActionContext,
    filterInput: string
  ): void,
  [FilterMutationTypes.SET_FILTER_LOCATION_CITY](
    { commit }: AugmentedActionContext,
    filterLocation: string
  ): void,
  [FilterMutationTypes.SET_FILTER_LOCATION_DISTRICT](
    { commit }: AugmentedActionContext,
    filterLocation: string[]
  ): void,
  [FilterMutationTypes.SET_FILTER_AREA](
    { commit }: AugmentedActionContext,
    filterArea: string[]
  ): void,
  [FilterMutationTypes.SET_FILTER_ROI](
    { commit }: AugmentedActionContext,
    filterRoi: string
  ): void,
  [FilterMutationTypes.SET_FILTER_VGV](
    { commit }: AugmentedActionContext,
    filterVgv: string[]
  ): void,
}

export const actions: ActionTree<State, RootState> & Actions = {
  [FilterActionTypes.SET_FILTER_INPUT]({ commit }, filterInput: string) {
    commit(FilterMutationTypes.SET_FILTER_INPUT, filterInput)
  },
  [FilterActionTypes.SET_FILTER_LOCATION_CITY]({ commit }, filterLocationCity: string) {
    commit(FilterMutationTypes.SET_FILTER_LOCATION_CITY, filterLocationCity);
  },
  [FilterActionTypes.SET_FILTER_LOCATION_DISTRICT]({ commit }, filterLocationDistrict: string[]) {
    commit(FilterMutationTypes.SET_FILTER_LOCATION_DISTRICT, filterLocationDistrict);
  },
  [FilterActionTypes.SET_FILTER_AREA]({ commit }, filterArea: string[]) {
    commit(FilterMutationTypes.SET_FILTER_AREA, filterArea);
  },
  [FilterActionTypes.SET_FILTER_ROI]({ commit }, filterRoi: string) {
    commit(FilterMutationTypes.SET_FILTER_ROI, filterRoi);
  },
  [FilterActionTypes.SET_FILTER_VGV]({ commit }, filterVgv: string[]) {
    commit(FilterMutationTypes.SET_FILTER_VGV, filterVgv);
  },
};
