import { GetterTree } from "vuex";
import type {Opportunity} from "@/@types";
import { RootState } from "@/store";
import { State } from "./state";

export type Getters = {
  opportunities(state: State): Opportunity,
  completeOpportunities(state: State): any,
  opportunity(state: State): Opportunity,
  selectedMarketButton(state: State): number,
  selectedBiOpportunityURL(state: State): string,
};

export const getters: GetterTree<State, RootState> & Getters = {
  opportunities: (state) => state.opportunities,
  completeOpportunities: (state) => state.completeOpportunities,
  opportunity: (state) => state.opportunities,
  selectedMarketButton: (state) => state.selectedMarketButton,
  selectedBiOpportunityURL: (state) => state.selectedBiOpportunityURL,
};
