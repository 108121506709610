import { ActionTree, ActionContext } from "vuex";
import { Mutations } from "./mutations";
import { RootState } from "@/store";
import { State } from "./state";
import { LoadingActionTypes } from "./action-types";
import { LoadingMutationTypes } from "./mutation-types";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [LoadingActionTypes.UPDATE_LOADING](
    { commit }: AugmentedActionContext,
    isLoading: boolean
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  [LoadingActionTypes.UPDATE_LOADING]({ commit }, isLoading: boolean) {
    commit(LoadingMutationTypes.SET_LOADING, isLoading);
  },
};
