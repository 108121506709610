
import { defineComponent } from "vue";
import LoginCarousel from "@/components/LoginCarousel.vue";
import { useStore } from "@/store";
import { LoadingMutationTypes } from "@/store/modules/loading/mutation-types";


export default defineComponent({
  name: "RecoverFinalization",
  components: {
    LoginCarousel,
  },
  setup() {
    const store = useStore();
    store.commit(LoadingMutationTypes.SET_LOADING, false);
    return {};
  },
});
