import axios from 'axios';
import { getUserData } from "@/services/user/userService";
import { logout } from '@/helpers/auth';

let baseURL = process.env.VUE_APP_BASE_API_URL;

if (process.env.VUE_APP_API_PORT) {
  console.log('DEV MODE')
  baseURL += `:${process.env.VUE_APP_API_PORT}`;
} else {
  console.log('PROD MODE')
}

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

axiosInstance.interceptors.request.use(
  (config) => {
    const userData = getUserData()
    if (userData?.token) {
      config.headers['Authorization'] = `Bearer ${userData?.token}`
      config.headers['UserId'] = userData?.login
      config.headers['UserCode'] = userData?.user_id
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response?.status === 401) {
      logout();
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
