
import { defineComponent, ref, computed, watch } from "vue";

export default defineComponent({
  name: "Slider",
  props: {
    label: {
      type: String,
      default: "",
    },
    valueStart: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 0.5,
    },
    sliderWidth: {
      type: String,
      default: "165px",
    },
    sliderLineWidth: {
      type: String,
      default: "109px",
    },
    scaleSize: {
      type: String,
      default: "medium",
    },
    showAsPercentual: {
      type: Boolean,
      default: false,
    },
    showAsNumber: {
      type: Boolean,
      default: false,
    },
    isBlockedOpportunity: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const knobPosition = ref(0); // This is for visual representation
    const sliderValue = ref(props.value); // This is the real value

    watch(sliderValue, (newValue) => {
      emit("LctsSlider-value", sliderValue.value);
    });

    return {
      sliderValue,
    };
  },
});
