import type { Layout } from "@/@types";

export type State = {
  isSidebarLeftOpen: Layout,
  isSidebarRightOpen: Layout,
};

export const state: State = {
  isSidebarLeftOpen: false,
  isSidebarRightOpen: false,
};
