
import { getUserById, updateUserById } from "@/services/user/userService";
import { defineComponent, onMounted, ref, watch } from "vue";
import Tabs from "@/components/Tabs.vue";
import { sendInstructionsForgotPassword } from "@/services/email/emailService";
import { useToast } from "vue-toastification";
import { User } from "../../services/user/types";
import CustomSelect from "../send/CustomSelect.vue";
import { checkUserSass } from "@/services/plans/plansService";
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { useRouter } from "vue-router";
dayjs.locale('pt-br');

export default defineComponent({
  components: {
    Tabs,
    CustomSelect,
  },
  setup() {
    const toast = useToast();
    const currentUser = ref<any>(null);
    const defaultPhotoUrl = ref<string>(
      require("../../assets/images/user.png")
    );
    const tabs = ref([
      { title: "Dados pessoais", active: true, disabled: false },
      { title: "Financeiro", active: false, disabled: false },
    ]);

    const cargos = [
      { descricao: "C-Level", title: "C-Level" },
      { descricao: "Gerente", title: "Gerente" },
      { descricao: "Coordenador", title: "Coordenador" },
      { descricao: "Analista", title: "Analista" },
      { descricao: "Técnico", title: "Técnico" },
      { descricao: "Assistente", title: "Assistente" },
      { descricao: "Corretor", title: "Corretor" },
      { descricao: "Arquiteto", title: "Arquiteto" },
      { descricao: "Outro", title: "Outro" },
    ];

    const router = useRouter();
    const activeTab = ref(0);

    const planInfo = ref<any>(null);

    const isLoading = ref(false);

    const getUserPlan = async (userId: number) => {
      try {
        const response = await checkUserSass(userId);
        if (response) {
          planInfo.value = response[0];
        }
      } catch (error) {
        console.error("Error fetching user plan:", error);
      }
    };

    const getUser = async () => {
      try {
        const userData = localStorage.getItem("userData");
        if (!userData) return;
        const userId = JSON.parse(userData).user_id;
        const result: User = await getUserById(userId);
        if (result) {
          currentUser.value = result;
          getUserPlan(currentUser.value.id);
          formatPhone();
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const formatPhone = () => {
      let phone = currentUser.value.telefone;
      if (!phone) return;
      phone = phone.replace(/\D/g, "");
      phone = phone.replace(/^(\d{2})(\d)/g, "($1) $2");
      phone = phone.replace(/(\d)(\d{4})$/, "$1-$2");
      currentUser.value.telefone = phone;
    };

    const saveUser = async () => {
      isLoading.value = true;
      if (currentUser.value) {
        try {
          const userToSave = {
            ...currentUser.value,
            telefone: currentUser.value.telefone.replace(/\D/g, ""),
          };
          await updateUserById(userToSave);
          showSuccessToast("Dados atualizados com sucesso.");
          isLoading.value = false;
        } catch (error) {
          showErrorToast("Houve um problema. Tente novamente mais tarde.");
          isLoading.value = false;
        }
      }
    };

    const showErrorToast = (message: string) => {
      toast.error(message, {
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    };

    const showSuccessToast = (message: string) => {
      toast.success(message, {
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    };

    const resetPassword = async () => {
      try {
        isLoading.value = true;
        await sendInstructionsForgotPassword({
          email: currentUser.value.email,
        });
        showSuccessToast("E-mail de instruções enviado com sucesso.");
      } catch (error) {
        showErrorToast("Houve um problema. Tente novamente mais tarde.");
        isLoading.value = false;
      } finally {
        isLoading.value = false;
      }
    };

    const formattedDate = (dateStr: string) => {
      const date = dayjs(dateStr).format('MMMM [de] YYYY');
      return date.charAt(0).toUpperCase() + date.slice(1);
    };

    const goToPlanPage = () => {
      window.open('https://conteudo.locates.com.br/por_assinatura', '_blank');
    }

    const onTabChanged = (tab: number) => {
      activeTab.value = tab;
      switch(activeTab.value) {
        case 0:
          tabs.value[0].active = true;
          break;
        case 1:
          tabs.value[1].active = true;
          break;
      }
    };

    onMounted(() => {
      getUser();
      const tab = router.currentRoute.value.params.tab;
      if(router.currentRoute.value.params.tab) {
        activeTab.value = tab === 'financeiro' ? 1 : 0;
        if (tab === 'financeiro') {
          tabs.value[0].active = false;
          tabs.value[1].active = true;
        } else {
          tabs.value[0].active = true;
          tabs.value[1].active = false;
        }
        onTabChanged(activeTab.value);
      }
    });

    return {
      currentUser,
      saveUser,
      tabs,
      defaultPhotoUrl,
      resetPassword,
      isLoading,
      formatPhone,
      cargos,
      planInfo,
      formattedDate,
      activeTab,
      goToPlanPage,
      onTabChanged,
    };
  },
});
