
import { defineComponent } from "vue";
export default defineComponent({
  name: "Modal",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
  },
  emits: ["modal-close"],
  setup (props, { emit }) {
    const closeModal = () => {
      emit('modal-close');
    }
    return {
      closeModal,
    }
  }
})
