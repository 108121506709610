import { createStore, createLogger } from "vuex";
import createPersistedState from "vuex-persistedstate";
import {
  store as loading,
  LoadingStore,
  State as LoadingState,
} from "@/store/modules/loading";
import {
  store as user,
  UserStore,
  State as UserState,
} from "@/store/modules/user";
import {
  store as layout,
  LayoutStore,
  State as LayoutState,
} from "@/store/modules/layout";
import {
  store as filter,
  FilterStore,
  State as FilterState,
} from "@/store/modules/filter";
import {
  store as opportunity,
  OpportunityStore,
  State as OpportunityState,
} from "@/store/modules/opportunity";

export type RootState = {
  loading: LoadingState;
  user: UserState;
  layout: LayoutState;
  filter: FilterState;
  opportunity: OpportunityState;
};


export type Store = LoadingStore<Pick<RootState, "loading">>
  & UserStore<Pick<RootState, 'user'>>
  & LayoutStore<Pick<RootState, 'layout'>>
  & FilterStore<Pick<RootState, 'filter'>>
  & OpportunityStore<Pick<RootState, 'opportunity'>>
  ;

const debug = process.env.NODE_ENV !== "production";
const plugins = debug ? [createLogger({}), createPersistedState()] : [createPersistedState()];

export const store = createStore({
  plugins,
  modules: {
    loading,
    user,
    layout,
    filter,
    opportunity,
  },
});

export function useStore(): Store {
  return store as Store;
}
