
import axiosInstance from '@/axios/axiosInstance'
import { AuthEmailPayload, GenericResponse } from './types'
import qs from 'qs';

export const sendAuthEmail = async (payload: AuthEmailPayload): Promise<GenericResponse> => {
    const data = qs.stringify(payload);
    const response = await axiosInstance.post('/send-auth-email', data)
    return response.data
}

export const authCodeIsValid = async (email: string, code: number): Promise<any> => {
  const params = { email, code };
  const response = await axiosInstance.get('/send-auth-email', { params });
  return response.data;
}

export const sendInstructionsForgotPassword = async (payload: AuthEmailPayload): Promise<any> => {
  const data = qs.stringify(payload);
  const response = await axiosInstance.post('/forgot-password', data);
  return response.data;
}
