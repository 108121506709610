import { ActionTree, ActionContext } from "vuex";
import { Mutations } from "./mutations";
import { RootState } from "@/store";
import { State } from "./state";
import { LayoutActionTypes } from "./action-types";
import { LayoutMutationTypes } from "./mutation-types";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [LayoutMutationTypes.UPDATE_IS_SIDEBAR_LEFT_OPEN](
    { commit }: AugmentedActionContext,
    isSideBarLeftOpen: boolean
  ): void,
  [LayoutMutationTypes.UPDATE_IS_SIDEBAR_RIGHT_OPEN](
    { commit }: AugmentedActionContext,
    isSideBarRightOpen: boolean
  ): void,
}

export const actions: ActionTree<State, RootState> & Actions = {
  [LayoutActionTypes.UPDATE_IS_SIDEBAR_LEFT_OPEN]({ commit }, isSideBarLeftOpen: boolean) {
    commit(LayoutMutationTypes.UPDATE_IS_SIDEBAR_LEFT_OPEN, isSideBarLeftOpen);
  },
  [LayoutActionTypes.UPDATE_IS_SIDEBAR_RIGHT_OPEN]({ commit }, isSideBarRightOpen: boolean) {
    commit(LayoutMutationTypes.UPDATE_IS_SIDEBAR_RIGHT_OPEN, isSideBarRightOpen);
  },
};
