
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import { useStore } from "@/store";
import VueApexCharts from "vue3-apexcharts";
import {
  capitalizeFirstLetter,
  formatNumber,
  formatNumberWithDesc,
} from "@/helpers/mask";
import Map from "@/components/Map.vue";
import {
  findByRaioStart,
  findByDistrictStart,
  findByLaunchStart,
  findRaioByMeters,
  findRaioByValues,
  findDistrictByMeters,
  findDistrictByValues,
  findLaunchByMeters,
  findLaunchByValues,
} from "@/services/opportunity/opportunityService";
import { LoadingMutationTypes } from "@/store/modules/loading/mutation-types";
import ResponsiveTable from "@/components/ResponsiveTable.vue";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "SummaryExecutiveSection",
  components: {
    ResponsiveTable,
    apexchart: VueApexCharts,
    Map,
  },
  props: {
    showHr: {
      type: Boolean,
      default: false,
    },
    isBlockedOpportunity: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const isLoading = ref(true);
    const sectionMain = ref<HTMLElement | null>(null);
    const completeOpportunity = computed(
      () => store.getters.completeOpportunities
    );
    const selectedButton = ref<number | null>(1);
    const selectedButtonBoard = ref(false);
    const tablesColumn1Area1 = ref<any[]>([]);
    const tablesColumn3Area1 = ref<any[]>([]);
    const tablesColumn3Area2 = ref<any[]>([]);
    const latLngList = ref<any[]>([]);
    const lat = ref(0);
    const lng = ref(0);
    const chartMeters = ref(null);
    const chartValues = ref(null);
    const itemMetersSelected = ref({
      isSelected: false,
      item: "0",
    });
    const itemValuesSelected = ref({
      isSelected: false,
      item: "0",
    });

    const selectButtonBoard = (isSelected: boolean) => {
      selectedButtonBoard.value = isSelected;
    };

    const getMetragemMin = (metragem: string) => {
      if (metragem.includes("até 40m²")) {
        return 0;
      } else if (metragem.includes("46 a 60m²")) {
        return 46;
      } else if (metragem.includes("61 a 75m²")) {
        return 61;
      } else if (metragem.includes("76 a 90m²")) {
        return 76;
      } else if (metragem.includes("91 a 115m²")) {
        return 91;
      } else if (metragem.includes("116 a 145m²")) {
        return 116;
      } else if (metragem.includes("146 a 175m²")) {
        return 146;
      } else if (metragem.includes("Maior que 300m²")) {
        return 300;
      } else {
        return 0;
      }
    };

    const getMetragemMax = (metragem: string) => {
      if (metragem.includes("até 40m²")) {
        return 40;
      } else if (metragem.includes("46 a 60m²")) {
        return 60;
      } else if (metragem.includes("61 a 75m²")) {
        return 75;
      } else if (metragem.includes("76 a 90m²")) {
        return 90;
      } else if (metragem.includes("91 a 115m²")) {
        return 115;
      } else if (metragem.includes("116 a 145m²")) {
        return 145;
      } else if (metragem.includes("146 a 175m²")) {
        return 175;
      } else if (metragem.includes("Maior que 300m²")) {
        return 999999999999999;
      } else {
        return 999999999999999;
      }
    };

    const getValoresMin = (valores: string) => {
      if (valores.includes("Até 500 mil")) {
        return 0;
      } else if (valores.includes("R$500 até R$750 mil")) {
        return 500000;
      } else if (valores.includes("R$750 até R$1 mi")) {
        return 750000;
      } else if (valores.includes("1 mi até 1.5 mi²")) {
        return 1000000;
      } else if (valores.includes("1.5 mi até 2 mi")) {
        return 1500000;
      } else if (valores.includes("2 mi até 3 mi")) {
        return 2000000;
      } else if (valores.includes("3 mi até 5 mi")) {
        return 3000000;
      } else if (valores.includes("Acima de 5 mi")) {
        return 5000000;
      } else {
        return 0;
      }
    };

    const getValoresMax = (valores: string) => {
      if (valores.includes("Até 500 mil")) {
        return 500000;
      } else if (valores.includes("R$500 até R$750 mil")) {
        return 750000;
      } else if (valores.includes("R$750 até R$1 mi")) {
        return 1000000;
      } else if (valores.includes("1 mi até 1.5 mi²")) {
        return 1500000;
      } else if (valores.includes("1.5 mi até 2 mi")) {
        return 2000000;
      } else if (valores.includes("2 mi até 3 mi")) {
        return 3000000;
      } else if (valores.includes("3 mi até 5 mi")) {
        return 5000000;
      } else if (valores.includes("Acima de 5 mi")) {
        return 999999999999999;
      } else {
        return 999999999999999;
      }
    };

    const isNoItemsSelected = () => {
      return (
        !itemMetersSelected.value.isSelected &&
        !itemValuesSelected.value.isSelected
      );
    };

    watch(isNoItemsSelected, (newValue: any) => {
      if (newValue) {
        executarOperacaoPadrao();
      }
    });

    const executarOperacaoPadrao = () => {
      selectButton(selectedButton.value);
    };

    const handleItemSelectedMeters = async (itemSelected: any) => {
      itemMetersSelected.value = {
        isSelected: true,
        item: itemSelected,
      };

      let raio = 1000;
      let bairro = capitalizeFirstLetter(completeOpportunity.value.bairro);

      if (selectedButton.value == 1) {
        if (itemValuesSelected.value.isSelected) {
          completeOpportunity.value.mercado_info = await findRaioByValues(
            completeOpportunity.value.id,
            raio,
            getValoresMin(itemValuesSelected.value.item[0]),
            getValoresMax(itemValuesSelected.value.item[0]),
            getMetragemMin(itemSelected[0]),
            getMetragemMax(itemSelected[0])
          );
        } else {
          completeOpportunity.value.mercado_info = await findRaioByMeters(
            completeOpportunity.value.id,
            raio,
            getMetragemMin(itemSelected[0]),
            getMetragemMax(itemSelected[0])
          );
        }
      } else if (selectedButton.value == 2) {
        if (itemValuesSelected.value.isSelected) {
          completeOpportunity.value.mercado_info = await findDistrictByValues(
            completeOpportunity.value.id,
            bairro,
            getValoresMin(itemSelected[0]),
            getValoresMax(itemSelected[0]),
            getMetragemMin(itemValuesSelected.value.item[0]),
            getMetragemMax(itemValuesSelected.value.item[0])
          );
        } else {
          completeOpportunity.value.mercado_info = await findDistrictByMeters(
            completeOpportunity.value.id,
            bairro,
            getMetragemMin(itemSelected[0]),
            getMetragemMax(itemSelected[0])
          );
        }
      } else if (selectedButton.value == 3) {
        if (itemValuesSelected.value.isSelected) {
          completeOpportunity.value.mercado_info = await findLaunchByValues(
            completeOpportunity.value.id,
            raio,
            getValoresMin(itemSelected[0]),
            getValoresMax(itemSelected[0]),
            getMetragemMin(itemValuesSelected.value.item[0]),
            getMetragemMax(itemValuesSelected.value.item[0])
          );
        } else {
          completeOpportunity.value.mercado_info = await findLaunchByMeters(
            completeOpportunity.value.id,
            raio,
            getMetragemMin(itemSelected[0]),
            getMetragemMax(itemSelected[0])
          );
        }
      }

      reloadDataTable();
    };

    const handleItemDesselectedMeters = async (itemSelected: any) => {
      itemMetersSelected.value = {
        isSelected: false,
        item: itemSelected,
      };

      let raio = 1000;
      let bairro = capitalizeFirstLetter(completeOpportunity.value.bairro);
      if (itemValuesSelected.value.isSelected) {
        if (selectedButton.value == 1) {
          completeOpportunity.value.mercado_info = await findRaioByValues(
            completeOpportunity.value.id,
            raio,
            getValoresMin(itemValuesSelected.value.item[0]),
            getValoresMax(itemValuesSelected.value.item[0])
          );
        } else if (selectedButton.value == 2) {
          completeOpportunity.value.mercado_info = await findDistrictByValues(
            completeOpportunity.value.id,
            bairro,
            getValoresMin(itemValuesSelected.value.item[0]),
            getValoresMax(itemValuesSelected.value.item[0])
          );
        } else if (selectedButton.value == 3) {
          completeOpportunity.value.mercado_info = await findLaunchByValues(
            completeOpportunity.value.id,
            raio,
            getValoresMin(itemValuesSelected.value.item[0]),
            getValoresMax(itemValuesSelected.value.item[0])
          );
        }
      }

      reloadDataTable();
    };

    const handleItemSelectedValues = async (itemSelected: any) => {
      itemValuesSelected.value = {
        isSelected: true,
        item: itemSelected,
      };

      let raio = 1000;
      let bairro = capitalizeFirstLetter(completeOpportunity.value.bairro);

      if (selectedButton.value == 1) {
        if (itemMetersSelected.value.isSelected) {
          completeOpportunity.value.mercado_info = await findRaioByMeters(
            completeOpportunity.value.id,
            raio,
            getMetragemMin(itemMetersSelected.value.item[0]),
            getMetragemMax(itemMetersSelected.value.item[0]),
            getValoresMin(itemSelected[0]),
            getValoresMax(itemSelected[0])
          );
        } else {
          completeOpportunity.value.mercado_info = await findRaioByValues(
            completeOpportunity.value.id,
            raio,
            getValoresMin(itemSelected[0]),
            getValoresMax(itemSelected[0])
          );
        }
      } else if (selectedButton.value == 2) {
        if (itemMetersSelected.value.isSelected) {
          completeOpportunity.value.mercado_info = await findRaioByMeters(
            completeOpportunity.value.id,
            bairro,
            getMetragemMin(itemSelected[0]),
            getMetragemMax(itemSelected[0]),
            getValoresMin(itemMetersSelected.value.item[0]),
            getValoresMax(itemMetersSelected.value.item[0])
          );
        } else {
          completeOpportunity.value.mercado_info = await findDistrictByValues(
            completeOpportunity.value.id,
            bairro,
            getValoresMin(itemSelected[0]),
            getValoresMax(itemSelected[0])
          );
        }
      } else if (selectedButton.value == 3) {
        if (itemMetersSelected.value.isSelected) {
          completeOpportunity.value.mercado_info = await findLaunchByMeters(
            completeOpportunity.value.id,
            raio,
            getMetragemMin(itemSelected[0]),
            getMetragemMax(itemSelected[0]),
            getValoresMin(itemMetersSelected.value.item[0]),
            getValoresMax(itemMetersSelected.value.item[0])
          );
        } else {
          completeOpportunity.value.mercado_info = await findLaunchByValues(
            completeOpportunity.value.id,
            raio,
            getValoresMin(itemSelected[0]),
            getValoresMax(itemSelected[0])
          );
        }
      }

      reloadDataTable();
    };

    const handleItemDesselectedValues = async (itemSelected: any) => {
      itemValuesSelected.value = {
        isSelected: false,
        item: itemSelected,
      };

      let raio = 1000;
      let bairro = capitalizeFirstLetter(completeOpportunity.value.bairro);

      if (itemMetersSelected.value.isSelected) {
        if (selectedButton.value == 1) {
          completeOpportunity.value.mercado_info = await findRaioByMeters(
            completeOpportunity.value.id,
            raio,
            getMetragemMin(itemMetersSelected.value.item[0]),
            getMetragemMax(itemMetersSelected.value.item[0])
          );
        } else if (selectedButton.value == 2) {
          completeOpportunity.value.mercado_info = await findDistrictByValues(
            completeOpportunity.value.id,
            bairro,
            getMetragemMin(itemMetersSelected.value.item[0]),
            getMetragemMax(itemMetersSelected.value.item[0])
          );
        } else if (selectedButton.value == 3) {
          completeOpportunity.value.mercado_info = await findLaunchByValues(
            completeOpportunity.value.id,
            raio,
            getMetragemMin(itemMetersSelected.value.item[0]),
            getMetragemMax(itemMetersSelected.value.item[0])
          );
        }
      }

      reloadDataTable();
    };

    const getDataOpportunityExternal = async () => {
      var dataList: any = [];

      await completeOpportunity.value.mercado_info.anuncios.forEach(
        (element: any) => {
          dataList.push([
            element.titulo,
            element.nm_quartos,
            "R$" + formatNumber(element.nm_valor),
            element.nm_area,
            "R$" + formatNumber(element.nm_media),
            "Acesse clicando aqui",
          ]);
        }
      );

      return dataList;
    };

    const getDataAverageMeters = async () => {
      var dataList: any = [];
      await completeOpportunity.value.mercado_info.valores_area_tabela.forEach(
        (element: any) => {
          dataList.push([
            element.label,
            element.count,
            "R$" + formatNumber(element.mean),
          ]);
        }
      );

      return dataList;
    };

    const getDataAverageValues = async () => {
      var dataList: any = [];

      await completeOpportunity.value.mercado_info.valores_preco_tabela.forEach(
        (element: any) => {
          dataList.push([
            element.label,
            element.count,
            "R$" + formatNumber(element.mean),
          ]);
        }
      );

      return dataList;
    };

    const fillTablesColumn1Area1 = async () => {
      switch (selectedButton.value) {
        case 1:
          completeOpportunity.value.mercado_info = await findByRaioStart(
            completeOpportunity.value.id,
            1000
          );
          break;
        case 2:
          let bairro = capitalizeFirstLetter(completeOpportunity.value.bairro);
          completeOpportunity.value.mercado_info = await findByDistrictStart(
            completeOpportunity.value.id,
            bairro
          );
          break;
        case 3:
          completeOpportunity.value.mercado_info = await findByLaunchStart(
            completeOpportunity.value.id,
            1000
          );
          break;
        default:
          completeOpportunity.value.mercado_info = await findByRaioStart(
            completeOpportunity.value.id,
            1000
          );
          break;
      }

      const opportunityExternal = await getDataOpportunityExternal();

      tablesColumn1Area1.value = [
        {
          circleColor: "#E0E0E0",
          title: "Oportunidade de negócios",
          headers: ["Título", "Quartos", "Valor", "Área (m²)", "Vm²", "Link"],
          data: opportunityExternal,
        },
      ];
    };

    const reloadDataTable = async () => {
      latLngList.value = [];
      completeOpportunity.value.mercado_info.anuncios.forEach(
        (element: any) => {
          latLngList.value.push(element.lat_lon);
        }
      );
      latLngList.value.forEach((element: any) => {
        lat.value = element[1];
        lng.value = element[0];
      });

      const opportunityExternal = await getDataOpportunityExternal();
      tablesColumn1Area1.value = [
        {
          circleColor: "#E0E0E0",
          title: "Oportunidade de negócios",
          headers: ["Título", "Quartos", "Valor", "Área (m²)", "Vm²", "Link"],
          data: opportunityExternal,
        },
      ];

      const averageMeters = await getDataAverageMeters();
      tablesColumn3Area1.value = [
        {
          circleColor: "#E0E0E0",
          title: "Média de metragens por anúncios",
          headers: ["Metragens", "Anúncios", "Vm²"],
          data: averageMeters,
          info: completeOpportunity.value.mercado_info.valores_area_tabela,
        },
      ];

      const averageValues = await getDataAverageValues();
      tablesColumn3Area2.value = [
        {
          circleColor: "#E0E0E0",
          title: "Média de valores por anúncios",
          headers: ["Metragens", "Anúncios", "Vm²"],
          data: averageValues,
          info: completeOpportunity.value.mercado_info.valores_preco_tabela,
        },
      ];
    };

    const fillTablesColumn3Area1 = async () => {
      const averageMeters = await getDataAverageMeters();

      tablesColumn3Area1.value = [
        {
          circleColor: "#E0E0E0",
          title: "Média de metragens por anúncios",
          headers: ["Metragens", "Anúncios", "Vm²"],
          data: averageMeters,
          info: completeOpportunity.value.mercado_info.valores_area_tabela,
        },
      ];
    };

    const fillTablesColumn3Area2 = async () => {
      const averageValues = await getDataAverageValues();

      tablesColumn3Area2.value = [
        {
          circleColor: "#E0E0E0",
          title: "Média de valores por anúncios",
          headers: ["Metragens", "Anúncios", "Vm²"],
          data: averageValues,
          info: completeOpportunity.value.mercado_info.valores_preco_tabela,
        },
      ];
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          emit("SummaryExecutiveSection-visible", 1);
        }
      });
    };

    const selectButton = async (buttonNumber: number | null) => {
      selectedButton.value = buttonNumber;

      switch (buttonNumber) {
        case 1:
          completeOpportunity.value.mercado_info = await findByRaioStart(
            completeOpportunity.value.id,
            1000
          );
          break;
        case 2:
          let bairro = capitalizeFirstLetter(completeOpportunity.value.bairro);
          completeOpportunity.value.mercado_info = await findByDistrictStart(
            completeOpportunity.value.id,
            bairro
          );
          break;
        case 3:
          completeOpportunity.value.mercado_info = await findByLaunchStart(
            completeOpportunity.value.id,
            1000
          );
          break;
        default:
          completeOpportunity.value.mercado_info = await findByRaioStart(
            completeOpportunity.value.id,
            1000
          );
          break;
      }

      fillTablesColumn1Area1();
      fillTablesColumn3Area1();
      fillTablesColumn3Area2();

      completeOpportunity.value.mercado_info.anuncios.forEach(
        (element: any) => {
          latLngList.value.push(element.lat_lon);
        }
      );

      latLngList.value.forEach((element: any) => {
        lat.value = element[1];
        lng.value = element[0];
      });
    };

    const observer = new IntersectionObserver(handleIntersection);

    onMounted(async () => {
      const selectedButtonFromRedirect = store.getters.selectedMarketButton;
      if (selectedButtonFromRedirect) {
        selectedButton.value = selectedButtonFromRedirect;
      }

      completeOpportunity.value.mercado_info = await findByRaioStart(
        completeOpportunity.value.id,
        1000
      );
      completeOpportunity.value.mercado_info.anuncios.forEach(
        (element: any) => {
          latLngList.value.push(element.lat_lon);
        }
      );
      latLngList.value.forEach((element: any) => {
        lat.value = element[1];
        lng.value = element[0];
      });
      fillTablesColumn1Area1();
      fillTablesColumn3Area1();
      fillTablesColumn3Area2();

      if (sectionMain.value) {
        observer.observe(sectionMain.value);
      }

      isLoading.value = false;
    });

    const labelQuartos = computed(() => {
      const data = completeOpportunity.value.mercado_info.labels_quartos;

      const sortedData = data.sort((a: any, b: any) => {
        return parseInt(a) - parseInt(b);
      });

      return sortedData;
    });

    const series = computed(() => {
      return [
        {
          name: "Anúncios",
          data: completeOpportunity.value.mercado_info.values_quartos,
        },
      ];
    });

    const chartOptions = ref({
      title: {
        text: "Anúncios por quartos",
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "15px",
          fontWeight: "500",
        },
      },
      chart: {
        type: "bar",
        fontFamily: "Lexend",
        toolbar: {
          show: false,
        },
        selection: {
          enabled: true,
          type: 'multiple',
          fill: {
            color: '#f8b400',
            opacity: 1
          },
          stroke: {
            width: 1,
            dashArray: 3,
            color: '#080808',
            opacity: 1
          },
        },
        redrawOnParentResize: true, // Permite que o gráfico seja redimensionado quando o pai for redimensionado
        offsetY: 10, // Ajusta a posição vertical do gráfico
      },
      grid: {
        yaxis: {
          lines: {
            show: true,
            offsetX: 0,
            offsetY: 0,
          },
        },
        xaxis: {
          lines: {
            show: true,
            dashArray: 5, // Isso tornará a linha tracejada
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false, // Mudamos para 'false' para torná-lo vertical
          colors: {
            ranges: [{
              from: 0,
              to: 6,
            }],
          },
        },
        cursor: "pointer",
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        title: {
          text: "Quartos",
          rotate: 0,
          offsetX: -20,
          offsetY: -10,
          style: {
            color: "#B0B0B0",
            fontSize: "12px",
            fontFamily: "Lexend",
            fontWeight: 400,
            marginTop: "50px",
          },
        },
        categories: labelQuartos.value,
      },
      yaxis: {
        title: {
          text: "Anúncios",
          rotate: -90,
          offsetX: 0,
          offsetY: 16,
          style: {
            color: "#B0B0B0",
            fontSize: "12px",
            fontFamily: "Lexend",
            fontWeight: 400,
          },
        },
      },
    });

    const selectedDataPointsMeters: string[] = [];
    const initialDefaultData = cloneDeep(completeOpportunity.value.mercado_info.anuncios);
    const handleDataPointSelectionMeters = async (event: any, chartContext: any, config: any) => {
      const { dataPointIndex } = config;
      const labels = chartContext.w.globals.labels;
      const roomsQtd = labels[dataPointIndex];

      // Se o item ainda não foi clicado, ele inclui no filtro. Se já foi, ele remove.
      if (!selectedDataPointsMeters.includes(roomsQtd)) {
        selectedDataPointsMeters.push(roomsQtd);
      } else {
        selectedDataPointsMeters.splice(
          selectedDataPointsMeters.indexOf(roomsQtd),
          1
        );
      }

      const filteredData = initialDefaultData.filter((anuncio: any) => {
        if (selectedDataPointsMeters.includes(anuncio.nm_quartos.toString())) {
          return anuncio;
        }
      });

      completeOpportunity.value.mercado_info.anuncios = filteredData.length > 0 ? filteredData : initialDefaultData;
      await reloadDataTable();
    };

    const clearFilters = () => {
      selectedDataPointsMeters.splice(0, selectedDataPointsMeters.length);
      completeOpportunity.value.mercado_info.anuncios = initialDefaultData;
      itemMetersSelected.value = {
        isSelected: false,
        item: "0",
      };
      itemValuesSelected.value = {
        isSelected: false,
        item: "0",
      };

      reloadDataTable();
    }
    
    store.commit(LoadingMutationTypes.SET_LOADING, false);

    return {
      tablesColumn1Area1,
      tablesColumn3Area1,
      tablesColumn3Area2,
      sectionMain,
      completeOpportunity,
      selectedButton,
      selectButton,
      selectButtonBoard,
      selectedButtonBoard,
      formatNumberWithDesc,
      formatNumber,
      latLngList,
      lat,
      lng,
      handleItemSelectedMeters,
      handleItemDesselectedMeters,
      handleItemSelectedValues,
      handleItemDesselectedValues,
      series,
      chartOptions,
      isLoading,
      chartMeters,
      chartValues,
      handleDataPointSelectionMeters,
      clearFilters,
    };
  },
});
