import { MutationTree } from "vuex";

import type { Opportunity } from "@/@types";

import { State } from "./state";
import { OpportunityMutationTypes as MutationTypes } from "./mutation-types";

export type Mutations<S = State> = {
  [MutationTypes.SET_OPPORTUNITY](state: S, payload: Opportunity): void,
  [MutationTypes.SET_OPPORTUNITY_FIELD](state: S, payload: any): void,
  [MutationTypes.SET_COMPLETE_OPPORTUNITY](state: S, payload: any): void,
  [MutationTypes.SET_OPPORTUNITY_UNIQUE](state: S, payload: Opportunity): void,
  [MutationTypes.SET_SELECTED_MARKET_BUTTON](state: S, payload: number): void,
  [MutationTypes.SET_SELECTED_BI_OPPORTUNITY_URL](state: S, payload: string): void,
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_OPPORTUNITY](state: State, opportunities: Opportunity) {
    state.opportunities = opportunities;
  },
  [MutationTypes.SET_OPPORTUNITY_FIELD](state: State, field: any) {
    state.opportunities[field.index][field.field] = field.value;
  },
  [MutationTypes.SET_COMPLETE_OPPORTUNITY](state: State, completeOpportunities: any) {
    state.completeOpportunities = completeOpportunities;
  },
  [MutationTypes.SET_OPPORTUNITY_UNIQUE](state: State, opportunity: Opportunity) {
    state.opportunity = opportunity;
  },
  [MutationTypes.SET_SELECTED_MARKET_BUTTON](state: State, selectedMarketButton: number) {
    state.selectedMarketButton = selectedMarketButton;
  },
  [MutationTypes.SET_SELECTED_BI_OPPORTUNITY_URL](state: State, selectedBiOpportunityURL: string) {
    state.selectedBiOpportunityURL = selectedBiOpportunityURL;
  }
};
