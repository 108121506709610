export function formatCep(value: string): string {
  const formattedValue = value.replace(/\D/g, "");

  if (formattedValue.length === 8) {
    return `${formattedValue.substring(0, 5)}-${formattedValue.substring(5)}`;
  }

  return formattedValue;
}

export function formatCurrency(value: number): string {
  // Implemente o formato desejado para a moeda
  // Exemplo: R$ 1.000,00
  return value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
}

export function formatNumber(value: number | string, decimalPlaces: number = 2): string {
  if (typeof value === "number" && !isNaN(value)) {
    if (Number.isInteger(value)) {
      return value.toLocaleString("pt-BR");
    } else {
      return value.toLocaleString("pt-BR", {
        maximumFractionDigits: decimalPlaces,
        minimumFractionDigits: decimalPlaces,
      });
    }
  } else if (typeof value === "string") {
    const numericValue = parseFloat(value.replace(",", "."));
    if (!isNaN(numericValue)) {
      if (Number.isInteger(numericValue)) {
        return numericValue.toLocaleString("pt-BR");
      } else {
        return numericValue.toLocaleString("pt-BR", {
          maximumFractionDigits: decimalPlaces,
          minimumFractionDigits: decimalPlaces,
        });
      }
    }
  }
  return "0.00"; // Retorno padrão se o valor não for um número válido.
}

// Meio que repete código, mas como tem muito lugar usando a função formatNumber, não acho seguro mudá-la.
// export function formatNumberWithCurrency(value: number | string, decimalPlaces: number = 2, isCurrency: boolean): string {
//   if (typeof value === "number" && !isNaN(value)) {
//     if (Number.isInteger(value)) {
//       return isCurrency ? `R$ ${value.toLocaleString("pt-BR")}` : value.toLocaleString("pt-BR");
//     } else {
//       return isCurrency
//         ? `R$ ${value.toLocaleString("pt-BR", {
//             maximumFractionDigits: decimalPlaces,
//             minimumFractionDigits: decimalPlaces,
//           })}`
//         : value.toLocaleString("pt-BR", {
//             maximumFractionDigits: decimalPlaces,
//             minimumFractionDigits: decimalPlaces,
//           });
//     }
//   } else if (typeof value === "string") {
//     const numericValue = parseFloat(value.replace(",", "."));
//     if (!isNaN(numericValue)) {
//       if (Number.isInteger(numericValue)) {
//         return isCurrency ? `R$ ${numericValue.toLocaleString("pt-BR")}` : numericValue.toLocaleString("pt-BR");
//       } else {
//         return isCurrency
//           ? `R$ ${numericValue.toLocaleString("pt-BR", {
//               maximumFractionDigits: decimalPlaces,
//               minimumFractionDigits: decimalPlaces,
//             })}`
//           : numericValue.toLocaleString("pt-BR", {
//               maximumFractionDigits: decimalPlaces,
//               minimumFractionDigits: decimalPlaces,
//             });
//       }
//     }
//   }
//   return "R$ 0.00";
// }
export function formatNumberWithCurrency(value: number | string, decimalPlaces: number = 2, isCurrency: boolean): string {
  if (typeof value === "number" && !isNaN(value)) {
    if (Number.isInteger(value)) {
      return isCurrency ? `R$ ${value.toLocaleString("pt-BR")},00` : value.toLocaleString("pt-BR") + ",00";
    } else {
      return isCurrency
        ? `R$ ${value.toLocaleString("pt-BR", {
            maximumFractionDigits: decimalPlaces,
            minimumFractionDigits: decimalPlaces,
          })}`
        : value.toLocaleString("pt-BR", {
            maximumFractionDigits: decimalPlaces,
            minimumFractionDigits: decimalPlaces,
          });
    }
  } else if (typeof value === "string") {
    const numericValue = parseFloat(value.replace(",", "."));
    if (!isNaN(numericValue)) {
      if (Number.isInteger(numericValue)) {
        return isCurrency ? `R$ ${numericValue.toLocaleString("pt-BR")},00` : numericValue.toLocaleString("pt-BR") + ",00";
      } else {
        return isCurrency
          ? `R$ ${numericValue.toLocaleString("pt-BR", {
              maximumFractionDigits: decimalPlaces,
              minimumFractionDigits: decimalPlaces,
            })}`
          : numericValue.toLocaleString("pt-BR", {
              maximumFractionDigits: decimalPlaces,
              minimumFractionDigits: decimalPlaces,
            });
      }
    }
  }
  return "R$ 0,00";
}

export function formatNumberWithDesc(value: number): string {
  if (isNaN(value) || value === 0) {
    return '0';
  } else if (value >= 1000000) {
    const formattedValue = (value / 1000000).toFixed(0);
    return `${formattedValue} Mi`;
  } else if (value >= 1000) {
    const formattedValue = (value / 1000).toFixed(0);
    return `${formattedValue} mil`;
  } else {
    return formatNumber(value);
  }
}


export function capitalizeFirstLetter(inputStr: any) {
  if (!inputStr) return '';
  return inputStr.charAt(0).toUpperCase() + inputStr.slice(1).toLowerCase();
}

export function formatVgvValue(value: any) {
  if (value >= 1000000) {
    const formattedValue = (value / 1000000).toLocaleString("pt-BR", { maximumFractionDigits: 1 });
    return `R$${formattedValue}mi`;
  } else if (value >= 1000) {
    const formattedValue = (value / 1000).toLocaleString("pt-BR", { maximumFractionDigits: 0 });
    return `R$${formattedValue}mil`;
  } else {
    return `R$${value.toLocaleString("pt-BR")}`;
  }
};
