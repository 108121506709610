import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9e752b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "form-check" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  class: "form-check-label",
  for: "flexCheckDefault"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          class: "form-check-input",
          type: "checkbox",
          value: "",
          id: "flexCheckDefault",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isChecked) = $event)),
          disabled: _ctx.isDisabled,
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleCheckboxChange && _ctx.handleCheckboxChange(...args)))
        }, null, 40, _hoisted_3), [
          [_vModelCheckbox, _ctx.isChecked]
        ]),
        _createElementVNode("label", _hoisted_4, [
          _renderSlot(_ctx.$slots, "checkboxText", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.checkboxText), 1)
          ], true)
        ])
      ])
    ])
  ]))
}