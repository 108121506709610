<template>
  <div class="d-flex flex-column justify-content-around">
    <article>
      <p class="fw500">1. Informações Coletadas</p>
      <p>
        1.1 Ao utilizar o applocates, podemos coletar informações pessoais, como nome, endereço de e-mail, informações de pagamento e outras informações necessárias para fornecer nossos serviços.
      </p>  
      <p>
        1.2 Também podemos coletar informações não pessoais, como dados de uso, preferências e interações com o aplicativo.
      </p>
    </article>
    <article>
      <p class="fw500">2. Uso das Informações</p>
      <p>
        2.1 As informações pessoais coletadas são utilizadas para fornecer, manter e melhorar nossos serviços, processar pagamentos, enviar comunicações importantes e personalizar a experiência do usuário.
      </p>  
      <p>
        2.2 Podemos utilizar informações não pessoais de forma agregada e anônima para análises e melhorias no serviço.
      </p>
    </article>
    <article>
      <p class="fw500">3. Compartilhamento de Informações</p>
      <p>
        3.1 Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para fornecer os serviços solicitados por você ou quando exigido por lei.
      </p>  
      <p>
        3.2 Podemos compartilhar informações não pessoais de forma agregada e anônima para fins de marketing, análises e parcerias comerciais.
      </p>
    </article>
    <article>
      <p class="fw500">4. Segurança</p>
      <p>
        4.1 Empregamos medidas de segurança razoáveis para proteger suas informações contra acesso não autorizado, alteração, divulgação ou destruição.
      </p>  
    </article>
    <article>
      <p class="fw500">5. Cookies e Tecnologias Semelhantes</p>
      <p>
        5.1 Utilizamos cookies e tecnologias semelhantes para melhorar a experiência do usuário, analisar tendências e administrar o site. Você pode controlar o uso de cookies nas configurações do seu navegador.
      </p>  
    </article>
    <article>
      <p class="fw500">6. Links para Outros Sites</p>
      <p>
        6.1 Nosso aplicativo pode conter links para sites de terceiros. Não nos responsabilizamos pela privacidade ou conteúdo desses sites.
      </p>  
    </article>
    <article>
      <p class="fw500">7. Links para Outros Sites</p>
      <p>
        7.1 Reservamo-nos o direito de atualizar nossa Política de Privacidade. Recomendamos que você revise periodicamente para estar ciente de quaisquer alterações.
      </p>  
    </article>
    <article>
      <p class="fw500">8. Contato</p>
      <p>
        8.1 Se tiver dúvidas sobre esta Política de Privacidade, entre em contato conosco no email <a>contato@locates.com.br</a>.
      </p>  
    </article>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TermsOfUseContent',
});
</script>

<style lang="scss" scoped>
.fw500 {
  font-weight: 500;
}
</style>
