
import { defineComponent, ref } from "vue";
import { useToast } from "vue-toastification";
import { sendInstructionsForgotPassword } from "@/services/email/emailService";
import LoginCarousel from "@/components/LoginCarousel.vue";
import ButtonLoader from "@/components/ButtonLoader.vue";

export default defineComponent({
  name: "ForgotPassword",
  components: {
    LoginCarousel,
    ButtonLoader,
  },
  setup() {
    const toast = useToast();
    const emailRef = ref('');
    const isLoading = ref(false);

    const showErrorToast = (message: string) => {
      toast.error(message, {
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    };

    const showSuccessToast = (message: string) => {
      toast.success(message, {
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    };

    const sendResetPasswordEmail = async () => {
      try {
        isLoading.value = true;
        await sendInstructionsForgotPassword({ email: emailRef.value });
        showSuccessToast('Email de instruções enviado com sucesso.');
        emailRef.value = '';
      } catch (error) {
        showErrorToast('Houve um problema. Tente novamente mais tarde.');
        isLoading.value = false;
      } finally {
        isLoading.value = false;
      }
    }

    return {
      emailRef,
      sendResetPasswordEmail,
      isLoading,
    };
  },
});
