
import { useToast } from "vue-toastification";
import { defineComponent, ref, reactive, toRaw, onMounted } from "vue";
import Button from "@/components/Button.vue";
import InputCheckbox from "@/components/InputCheckbox.vue";
import InputTextfield from "@/components/InputTextfield.vue";
import LoginCarousel from "@/components/LoginCarousel.vue";
import { getToken, authenticateUser } from "@/services/user/userService";
import { LoginPayload, LoginResponse } from "@/services/user/types";
import { useStore } from "@/store";
import { LoadingMutationTypes } from "@/store/modules/loading/mutation-types";
import { UserMutationTypes } from "@/store/modules/user/mutation-types";
import router from "@/router/index";
import { UserResponse, User } from "@/services/user/types";
import { getUserByEmail } from "@/services/user/userService";
import Password from "@/components/Password.vue";

interface LoginForm {
  username: string;
  password: string;
}

export default defineComponent({
  name: "Login",
  components: {
    Button,
    InputCheckbox,
    InputTextfield,
    LoginCarousel,
    Password,
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const isChecked = ref(false);
    const disableButton = ref(true);
    const inputEmailIsValid = ref(false);
    const inputPasswordIsValid = ref(false);
    const form = reactive<LoginForm>({
      username: "",
      password: "",
    });
    const userObj = ref<User>({
      id: 0,
      name: "",
      email: "",
      password: "",
      bl_ativo: false,
      telefone: "",
      ocupacao: "",
    });
    const showError = ref(false);

    showError.value = false;

    const handleInputEmailValue = (value: string) => {
      form.username = value;
    };

    const handleInputPasswordValue = (value: string) => {
      if (value != "") inputPasswordIsValid.value = true;
      else inputPasswordIsValid.value = false;

      form.password = value;
    };
    const handleInputEmailIsValid = (value: boolean) => {
      inputEmailIsValid.value = value;
    };

    const isFormValid = (): boolean => {
      return inputEmailIsValid.value && inputPasswordIsValid.value;
    };

    const userIsActive = async (): Promise<boolean> => {
      let user: User = await getUserByEmail(form.username);
      userObj.value = user;

      return userObj.value.bl_ativo;
    };

    const handleButtonClick = async (formLogin: LoginForm) => {
      try {
        if (! await userIsActive()) {
          showErrorToast("Usuário não está ativo.");
          store.commit(LoadingMutationTypes.SET_LOADING, false);
          return;
        }

        const loginResponse = await login(formLogin);
     
        store.commit(LoadingMutationTypes.SET_LOADING, false);
        store.commit(UserMutationTypes.SET_USER, loginResponse);
        showError.value = false;

        showSuccessToast(loginResponse.nome);
        goToPanel();
      } catch (error) {
        showError.value = true;
        store.commit(LoadingMutationTypes.SET_LOADING, false);

        showErrorToast("Usuário ou senha inválido.");
      }
    };

    const login = async (formLogin: LoginForm) => {
      const rawFormLogin = toRaw(formLogin);

      const loginPayload: LoginPayload = {
        username: rawFormLogin.username,
        password: rawFormLogin.password,
      };

      const response: LoginResponse = await getToken(loginPayload);

      const loginResponse = {
        ok: response.ok,
        login: response.login,
        user_id: response.user_id,
        empresa: response.empresa,
        link_foto: response.link_foto,
        nome: response.nome,
        token: response.token,
      };

      return loginResponse;
    };

    const showSuccessToast = (name: string) => {
      toast.success(`Seja bem vindo, ${name}.`, {
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    };

    const showErrorToast = (message: string) => {
      toast.error(message, {
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    };

    const goToPanel = () => {
      router.push({ name: "my-panel" });
    };

    const handleCheckboxChange = (checked: boolean) => {
      localStorage.setItem("locates_remember_me", checked ? "1" : "0");
    };

    onMounted(() => {
      const userData = localStorage.getItem("userData");
      let token = null;
      let email = null;
      const rememberMeValue = localStorage.getItem("locates_remember_me");

      if (typeof userData === "string") {
        token = JSON.parse(userData).token;
        email = JSON.parse(userData).login;
      }

      if (token && rememberMeValue === "1") {
        authenticateUser(token, email)
          .then(() => {
            goToPanel();
          })
          .catch(() => {
            localStorage.removeItem("userData");
            localStorage.removeItem("locates_remember_me");
          });
      }
    });

    return {
      handleButtonClick,
      isChecked,
      handleCheckboxChange,
      handleInputEmailValue,
      handleInputPasswordValue,
      handleInputEmailIsValid,
      isFormValid,
      form,
      showError,
      disableButton,
      toast,
    };
  },
});
