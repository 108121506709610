
import { defineComponent, ref, reactive, toRaw } from "vue";
import Button from "@/components/Button.vue";
import InputCheckbox from "@/components/InputCheckbox.vue";
import InputTextfield from "@/components/InputTextfield.vue";
import LoginCarousel from "@/components/LoginCarousel.vue";
import {
  RegisterPayload,
  RegisterResponse,
  UserResponse,
} from "@/services/user/types";
import { AuthEmailPayload, AuthEmailResponse } from "@/services/email/types";
import {
  registerUser,
  userExist,
  getUserByEmail,
} from "@/services/user/userService";
import { sendAuthEmail } from "@/services/email/emailService";
import { useToast } from "vue-toastification";
import router from "@/router/index";
import { useStore } from "@/store";
import { LoadingMutationTypes } from "@/store/modules/loading/mutation-types";
import { UserMutationTypes } from "@/store/modules/user/mutation-types";
import AuthenticationEmail from "./AuthenticationEmail.vue";
import Password from "@/components/Password.vue";
import Modal from "@/components/Modal.vue";
import TermsOfUseContent from "./TermsOfUseContent.vue";
import { MaskInput } from 'vue-3-mask';

interface RegisterForm {
  name: string;
  email: string;
  phone: string;
  company: string;
  occupation: string;
  password: string;
  confirmPassword: string;
}

export default defineComponent({
  name: "Register",
  components: {
    Button,
    InputCheckbox,
    InputTextfield,
    LoginCarousel,
    AuthenticationEmail,
    Password,
    Modal,
    TermsOfUseContent,
    MaskInput,
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const termsOfUseCheckbox = ref(false);
    const errorMessage = ref("");
    const showModal = ref(false);
    const form = reactive<RegisterForm>({
      name: "",
      email: "",
      phone: "",
      company: "",
      occupation: "",
      password: "",
      confirmPassword: "",
    });

    const userObj = ref<UserResponse>({
      id: 0,
      nome: "",
      email: "",
      link_foto: "",
      usuario: "",
      empresa: "",
      ocupacao: "",
      senha: "",
      salt: "",
      bl_ativo: true,
    });
    const inputEmailIsValid = ref(false);
    const inputNameIsValid = ref(false);
    const inputCompanyIsValid = ref(false);
    const inputOccupationIsValid = ref(false);
    const inputPasswordIsValid = ref(false);
    const inputConfirmPasswordIsValid = ref(false);
    const showAuthCodeScreen = ref(false);

    const handleInputNameValue = (value: string) => {
      form.name = value;
    };

    const handleInputEmailValue = (value: string) => {
      form.email = value;
    };

    const handleInputPhoneValue = (value: string) => {
      form.phone = value;
    };

    const handleInputCompanyValue = (value: string) => {
      form.company = value;
    };

    const handleInputOccupationValue = (value: string) => {
      form.occupation = value;
      handleInputOccupationIsValid(value !== "")
    };

    const profileOptions = [
      { value: 'Incorporador', label: 'Incorporador' },
      { value: 'Corretor Imobiliário', label: 'Corretor Imobiliário' }
    ];
    const showModalToggle = () => {
      showModal.value = true;
    };

    const isModalOpened = ref(false);

    const openModal = () => {
      isModalOpened.value = true;
    };
    const closeModal = () => {
      isModalOpened.value = false;
    };

    const handleInputPasswordValue = (value: string) => {
      form.password = value;
      form.confirmPassword = value;
    };

    const handleInputPasswordIsValid = (value: boolean) => {
      inputPasswordIsValid.value = value;
      inputConfirmPasswordIsValid.value = value;
    };

    const handleCheckboxChange = (checked: boolean) => {
      termsOfUseCheckbox.value = checked;
    };

    const handleInputNameIsValid = (value: boolean) => {
      inputNameIsValid.value = value;
    };

    const handleInputEmailIsValid = (value: boolean) => {
      inputEmailIsValid.value = value;
    };

    const handleInputCompanyIsValid = (value: boolean) => {
      inputCompanyIsValid.value = value;
    };

    const handleInputOccupationIsValid = (value: boolean) => {
      inputOccupationIsValid.value = value;
    };

    const formIsValid = (): boolean => {
      return (
        inputNameIsValid.value &&
        inputEmailIsValid.value &&
        form.phone != "" &&
        inputCompanyIsValid.value &&
        inputOccupationIsValid.value &&
        inputPasswordIsValid.value &&
        termsOfUseCheckbox.value &&
        form.confirmPassword != ""
      );
    };

    const handleButtonClick = async (formRegister: RegisterForm) => {
      try {
        if (form.password != form.confirmPassword) {
          errorMessage.value = "Senhas não correspondem";

          store.commit(LoadingMutationTypes.SET_LOADING, false);
          return;
        } else if ((await userExistInDB(formRegister.email)) == true) {
          store.commit(LoadingMutationTypes.SET_LOADING, false);
          showErrorToast("Usuário já cadastrado.");
          return;
        } else {
          errorMessage.value = "";
          const registerResponse = await register(formRegister);
          const sendAuthEmailResponse = await sendAuthEmailAndSave(
            formRegister
          );

          store.commit(LoadingMutationTypes.SET_LOADING, false);
          store.commit(UserMutationTypes.SET_EMAIL, formRegister.email);

          //goToAuthenticationEmail();
          showAuthCodeScreen.value = true;
          let user: UserResponse = await getUserByEmail(formRegister.email);
          userObj.value = user;
        }
      } catch (error) {
        store.commit(LoadingMutationTypes.SET_LOADING, false);
        console.error("Error", error);
        showErrorToast("Não foi possível efetuar seu cadastro.");
      }
    };

    const userExistInDB = async (email: string) => {
      const response: boolean = await userExist(email);

      return response;
    };

    const register = async (formLogin: RegisterForm) => {
      const rawFormLogin = toRaw(formLogin);

      const loginPayload: RegisterPayload = {
        nome: rawFormLogin.name,
        email: rawFormLogin.email,
        telefone: rawFormLogin.phone.replace(/\D/g,''),
        empresa: rawFormLogin.company,
        ocupacao: rawFormLogin.occupation,
        senha: rawFormLogin.password,
      };

      const response: RegisterResponse = await registerUser(loginPayload);
      return response;
    };

    const sendAuthEmailAndSave = async (formLogin: RegisterForm) => {
      const rawFormLogin = toRaw(formLogin);

      const authEmailPayload: AuthEmailPayload = {
        email: rawFormLogin.email,
      };

      const response: AuthEmailResponse = await sendAuthEmail(authEmailPayload);

      const registerResponse = {
        ok: response.ok,
      };

      return registerResponse;
    };

    const showErrorToast = (message: string) => {
      toast.error(message, {
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    };

    const goToAuthenticationEmail = () => {
      router.push({ name: "auth-email" });
    };

    return {
      handleButtonClick,
      termsOfUseCheckbox,
      handleCheckboxChange,
      handleInputEmailValue,
      handleInputPasswordValue,
      handleInputNameValue,
      handleInputPhoneValue,
      handleInputCompanyValue,
      handleInputOccupationValue,
      handleInputNameIsValid,
      handleInputEmailIsValid,
      handleInputCompanyIsValid,
      handleInputOccupationIsValid,
      handleInputPasswordIsValid,
      formIsValid,
      form,
      errorMessage,
      showAuthCodeScreen,
      userObj,
      profileOptions,
      showModal,
      showModalToggle,
      isModalOpened,
      closeModal,
      openModal,
    };
  },
});
