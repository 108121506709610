
import { defineComponent, computed, PropType } from "vue";
import ButtonLoader from "@/components/ButtonLoader.vue";
import { useStore } from "@/store";
import { LoadingMutationTypes } from "@/store/modules/loading/mutation-types";
export default defineComponent({
  name: "Button",
  emits: ["lctsButton-clicked"],
  components: {
    ButtonLoader,
  },
  props: {
    text: {
      type: String,
      default: "",
    },
    size: {
      type: String as PropType<"small" | "medium" | "large">,
      default: "medium",
    },
    styleButton: {
      type: String as PropType<
        "primary" | "secondary" | "outline-primary" | "text" | "danger"
      >,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "auto",
    },
    height: {
      type: String,
      default: "2.9rem",
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const isLoading = computed(() => store.state.loading.isLoading);
    const buttonClass = computed(() => {
      let btnSizeClass = "";
      let btnHoverClass = "";
      let btnFocusClass = "";
      let btnActiveClass = "";

      switch (props.size) {
        case "small":
          btnSizeClass = "btn-sm";
          break;
        case "large":
          btnSizeClass = "btn-lg";
          break;
        default:
          btnSizeClass = "";
      }

      switch (props.styleButton) {
        case "primary":
          btnHoverClass = "btn-primary-hover";
          btnFocusClass = "btn-primary-focus";
          btnActiveClass = "btn-primary-active";
          break;
        case "secondary":
          btnHoverClass = "btn-secondary-hover";
          btnFocusClass = "btn-secondary-focus";
          btnActiveClass = "btn-secondary-active";
          break;
        case "outline-primary":
          btnHoverClass = "btn-outline-primary-hover";
          btnFocusClass = "btn-outline-primary-focus";
          btnActiveClass = "btn-outline-primary-active";
          break;
        case "text":
          btnHoverClass = "btn-text-hover";
          btnFocusClass = "btn-text-focus";
          btnActiveClass = "btn-text-active";
          break;
        case "danger":
          btnHoverClass = "btn-danger-hover";
          btnFocusClass = "btn-danger-focus";
          btnActiveClass = "btn-danger-active";
          break;
        default:
          btnHoverClass = "";
          btnFocusClass = "";
      }

      return `btn btn-${props.styleButton} ${btnSizeClass} ${btnHoverClass} ${btnFocusClass} ${btnActiveClass}`;
    });

    const handleClick = () => {
      store.commit(LoadingMutationTypes.SET_LOADING, true);
      emit("lctsButton-clicked");
    };

    return {
      buttonClass,
      handleClick,
      isLoading,
    };
  },
});
