<template>
  <div>
    <nav class="nav nav-pills">
      <a
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ 'nav-link': true, 'active': tab.active, 'disabled': tab.disabled }"
        @click="activateTab(tab, index)"
        href="#"
      >
        {{ tab.title }}
      </a>
    </nav>
    <div v-if="activeTab !== 3" class="container-toggle-tabs" :class="{'disable-toggle': !dataMap.length || loadDataMap}" @click="changeToggle">
      <span class="material-symbols-outlined icon-toggle-map"  v-if="toggleCard" >toggle_on</span>
      <span class="material-symbols-outlined icon-toggle-map" v-else>toggle_off</span>
      <p class="title-toggle-map">{{ toggleCard ? 'Ver lista' : 'Ver mapa' }}</p>
    </div>
    <div :class="{'change-page-animation': changedTab }">
      <slot :activeTab="activeTab" :isMap="toggleCard"></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'Tabs',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    dataMap: {
      type: Array,
      default: () => []
    },
    loadDataMap: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const activeTab = ref(0);
    const changedTab = ref(false);
    const toggleCard = ref(false)

    const activateTab = (tab, index) => {
      if (tab.disabled) return;
      activeTab.value = index;
      props.tabs.forEach((tab, i) => {
        tab.active = i === index;
      });
      toggleCard.value = false
      emit('tab-changed', index);
    };

    const changeToggle = () => {
      if (!props.dataMap.length || props.loadDataMap) return

      toggleCard.value = !toggleCard.value
    }

    watch(activeTab, () => {
      changedTab.value = true;
      setTimeout(() => {
        changedTab.value = false;
      }, 1200);
    });

    return {
      activeTab,
      activateTab,
      changedTab,
      toggleCard,
      changeToggle
    };
  },
});
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
}

nav {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 8px 10px;
  gap: 5px;
}

.nav-link {
  white-space: nowrap;
  color: #4F3C88;
  font-weight: 300;
  &.disabled {
    color: #ccc;
  }
  &.active {
    background-color: #4F3C88;
    color: #fff;
    &:hover {
      background-color: #4F3C88;
    }
  }
  &:hover {
    background-color: #BCA9F5;
  }
}

.container-toggle-tabs {
  cursor: pointer;
  width: fit-content;
  padding-top: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #4F3C88;
  .icon-toggle-map {
    font-size: 50px
  }
  .title-toggle-map {
    margin: 0;
    font-size: 18px;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease forwards;
}

.fade-out {
  animation: fadeOut 0.5s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.change-page-animation {
  animation: fadeIn 1.5s ease forwards;
}

.disable-toggle {
  color: #cacaca;
  cursor: not-allowed;
}
</style>
