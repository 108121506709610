
import { defineComponent, ref, onMounted, computed  } from "vue";
import { useStore } from "@/store";
import { formatNumber } from "@/helpers/mask"
import ResponsiveTable from "@/components/ResponsiveTable.vue";

export default defineComponent({
  name: "SummaryViabilitySection",
  components: { ResponsiveTable },
  props: {
    showHr: {
      type: Boolean,
      default: false,
    },
    isBlockedOpportunity: {
      type: Boolean,
      default: false, 
    },
  },
  setup( props, { emit }) {
    const store = useStore();
    const sectionMain = ref<HTMLElement | null>(null);
    const completeOpportunity = computed(() => store.getters.completeOpportunities);
    const tablesColumn1Area1 = ref<any[]>([]);
    const tablesColumn1Area2 = ref<any[]>([]);
    const tablesColumn1Area3 = ref<any[]>([]);
    const tablesColumn1Area4 = ref<any[]>([]);
      
    const getDataZonamentoTerreno = async () => {
      var dataList: any = [];
      if (completeOpportunity.value.pri_info.length > 0) {
        await completeOpportunity.value.pri_info.forEach((element: any) => {
          dataList.push([
            element.pri_zona,
            element.pri_nm_zon,
            element.pri_area_zon_atingimento == null ? 0 : formatNumber(element.pri_area_zon_atingimento, 2),
            element.pri_obs === null || '' ? '–' : element.pri_obs
          ])
        });
      } else {
        dataList.push(['–', '–', '–', '–'])
      }

      return dataList;
    }

    const getDataIndiceZoneamento = async () => {
      var dataList: any = [];
      if (completeOpportunity.value.pri_info.length > 0) {
        await completeOpportunity.value.pri_info.forEach((element: any) => {
          dataList.push([
            element.pri_zona,
            element.pri_area_lote == null ? 0 : formatNumber(element.pri_area_lote),
            element.pri_aproveitamento,
            `${element.pri_taxa_ocupacao}%`,
            element.pri_pavimentos_maximo == null ? 0: element.pri_pavimentos_maximo
          ])
        });
      } else {
        dataList.push(['–', '–', '–', '–', '–'])
      }

      return dataList;
    }

    const getDataSistemaViario = async () => {
      var dataList: any = [];
      if (completeOpportunity.value.sv_info.length > 0) {
        await completeOpportunity.value.sv_info.forEach((element: any) => {
          dataList.push([
            element.sv_zona,
            element.sv_categoria,
            element.sv_area_zon_atingimento == null ? 0 : formatNumber(element.sv_area_zon_atingimento, 2),
            element.sv_observacao === null || '' ? '–' : element.sv_observacao
          ])
        });
      } else {
        dataList.push(['–', '–', '–', '–'])
      }

      return dataList;
    }

    const getDataIndiceSistemaViario = async () => {
      var dataList: any = [];
      if (completeOpportunity.value.sv_info.length > 0) {
        await completeOpportunity.value.sv_info.forEach((element: any) => {
          dataList.push([
            element.sv_zona,
            formatNumber(element.sv_faixa_dominio, 1),
            element.sv_numero_pistas,
            element.sv_largura_passeios === null ? '–' : element.sv_largura_passeios,
            // 'Ciclovia',
            // 'Canteiro Central'
          ])
        });
      } else {
        dataList.push(['–', '–', '–', '–'])
      }

      return await dataList;
    }

    const getDataZoneamentoSecundario = async () => {
      var dataList: any = [];
      if (completeOpportunity.value.sec_info.length > 0) {
        await completeOpportunity.value.sec_info.forEach((element: any) => {
          dataList.push([
            element.sec_zona === null || '' ? '–' : element.sec_zona,
            element.sec_descricao == null || '' ? '–' : element.sec_descricao,
            element.sec_area_zon_atingimento === null || '' ? '–' : formatNumber(element.sec_area_zon_atingimento, 2)]
          )
        });
      } else {
        dataList.push(['–', '–', '–'])
      }

      return dataList;
    }

    const getDataIndiceZoneamentoSecundario = async () => {
      var dataList: any = [];
      if (completeOpportunity.value.sec_info.length > 0) {
        await completeOpportunity.value.sec_info.forEach((element: any) => {
          dataList.push([element.sec_zona, element.sec_observacao == '' ? '–': element.sec_observacao])
        });
      } else {
        dataList.push(['–', '–'])
      }

      return dataList;
    }

    const getDataRestricaoAmbientalTerreno = async () => {
      var dataList: any = [];
      if (completeOpportunity.value.amb_info.length > 0) {
        await completeOpportunity.value.amb_info.forEach((element: any) => {
          dataList.push([element.amb_zona, element.amb_nm_zon, element.amb_area_zon_atingimento == null ? 0 : formatNumber(element.amb_area_zon_atingimento, 2)])
        });
      } else {
        dataList.push(['–', '–', '–'])
      }

      return dataList;
    }

    const getDataRestricaoIndiceZoneamentoSecundario = async () => {
      var dataList: any = [];
      if (completeOpportunity.value.amb_info.length > 0) {
        await completeOpportunity.value.amb_info.forEach((element: any) => {
          dataList.push([element.amb_zona, element.amb_obs === '' || element.amb_obs === null ? '–' : element.amb_obs])
        });
      } else {
        dataList.push(['–', '–'])
      }

      return dataList;
    }

    const fillTablesColumn1Area1 = async () => {
      const zonamentoTerrenoData = await getDataZonamentoTerreno();
      const indiceZoneamento     = await getDataIndiceZoneamento();

      tablesColumn1Area1.value = [
        {
          circleColor: "#E0E0E0",
          title: "Zoneamento no terreno",
          headers: ["Código da zona", "Nome do zoneamento", "Área atingida (m²)", "Observação"],
          data: zonamentoTerrenoData,
          info: completeOpportunity.value.pri_info
        },
        {
          circleColor: "#E0E0E0",
          title: "Índices dos zoneamentos",
          headers: ["Código da zona", "Área do Lote (m²)", "CA (Máx)", "Taxa de ocupação (Máx)", "Pavimentos (Máx)"],
          data: indiceZoneamento,
          info: completeOpportunity.value.pri_info
        }
      ];

    };

    const fillTablesColumn1Area2 = async () => {
      const sistemaViario = await getDataSistemaViario();
      const indiceSistemaViario = await getDataIndiceSistemaViario();

      tablesColumn1Area2.value = [
        {
          circleColor: "#E0E0E0",
          title: "Sistema viário no terreno",
          headers: ["Código da zona", "Nome do zoneamento", "Área atingida (m²)", "Observação"],
          data: sistemaViario,
          info: completeOpportunity.value.sv_info
        },
        {
          circleColor: "#E0E0E0",
          title: "Índices do sistema viário",
          headers: ["Código da zona", "Largura (m)", "Pistas", "Largura Passeio (m)"],
          // TODO: Ciclovia e Canteiro Central ficarão de fora por hora.
          // headers: ["Código da zona", "Largura (m)", "Pistas", "Largura Passeio (m)", "Ciclovia", "Canteiro Central"],
          data: indiceSistemaViario,
          info: completeOpportunity.value.sv_info
        }
      ];
    };

    const fillTablesColumn1Area3 = async () => {
      const zoneamentoSecundario = await getDataZoneamentoSecundario();
      const indiceZoneamentoSecundario = await getDataIndiceZoneamentoSecundario();

      tablesColumn1Area3.value = [
        {
          circleColor: "#E0E0E0",
          title: "Zoneamento secundário",
          headers: ["Código da zona", "Nome do sobrezoneamento", "Área atingida (m²)"],
          data: zoneamentoSecundario,
          info: completeOpportunity.value.sec_info
        },
        {
          circleColor: "#E0E0E0",
          title: "Índices dos zoneamentos secundários",
          headers: ["Código da zona", "Observações"],
          data: indiceZoneamentoSecundario,
          info: completeOpportunity.value.sec_info
        }
      ];
    };

    const fillTablesColumn1Area4 = async () => {
      const restricaoAmbientalTerreno = await getDataRestricaoAmbientalTerreno();
      const restricaoIndiceZoneamentoSecundario  = await getDataRestricaoIndiceZoneamentoSecundario();

      tablesColumn1Area4.value = [
        {
          circleColor: "#E0E0E0",
          title: "Restrições ambientais no terreno",
          headers: ["Código da zona", "Nome do sobrezoneamento", "Área atingida (m²)"],
          data: restricaoAmbientalTerreno,
          info: completeOpportunity.value.amb_info
        },
        {
          circleColor: "#E0E0E0",
          title: "Índices dos zoneamentos secundários",
          headers: ["Código da zona", "Observações"],
          data: restricaoIndiceZoneamentoSecundario,
          info: completeOpportunity.value.amb_info
        }
      ];
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          emit("SummaryViabilitySection-visible", 2);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection);
    onMounted(() => {
        fillTablesColumn1Area1(); 
        fillTablesColumn1Area2();
        fillTablesColumn1Area3();
        fillTablesColumn1Area4();

        if (sectionMain.value) {
          observer.observe(sectionMain.value);
        }
    });

    return {
      tablesColumn1Area1,
      tablesColumn1Area2,
      tablesColumn1Area3,
      tablesColumn1Area4,
      sectionMain,
      completeOpportunity
    };
  },
});
