import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16c9f6ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["min", "max", "step"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "lcts-slider-container",
    style: _normalizeStyle(_ctx.scaleSize == 'small' ? { transform: 'scale(0.75)' } : {})
  }, [
    (!_ctx.showAsPercentual)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["slider-label settings", { blurred: _ctx.isBlockedOpportunity }])
        }, _toDisplayString(_ctx.label), 3))
      : (_ctx.showAsNumber)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(["slider-label settings", { blurred: _ctx.isBlockedOpportunity }])
          }, _toDisplayString(_ctx.sliderValue), 3))
        : (_ctx.showAsPercentual)
          ? (_openBlock(), _createElementBlock("span", {
              key: 2,
              class: _normalizeClass(["slider-label settings", { blurred: _ctx.isBlockedOpportunity }])
            }, _toDisplayString(_ctx.sliderValue) + "%", 3))
          : (_openBlock(), _createElementBlock("span", {
              key: 3,
              class: _normalizeClass(["slider-label settings", { blurred: _ctx.isBlockedOpportunity }])
            }, _toDisplayString(_ctx.sliderValue) + "%", 3)),
    _withDirectives(_createElementVNode("input", {
      type: "range",
      class: _normalizeClass(["m2-slider", { blurred: _ctx.isBlockedOpportunity }]),
      min: _ctx.min,
      max: _ctx.max,
      step: _ctx.step,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sliderValue) = $event))
    }, null, 10, _hoisted_1), [
      [_vModelText, _ctx.sliderValue]
    ])
  ], 4))
}