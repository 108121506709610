import { MutationTree } from "vuex";

import type { User } from "@/@types";

import { State } from "./state";
import { UserMutationTypes as MutationTypes } from "./mutation-types";

export type Mutations<S = State> = {
  [MutationTypes.SET_USER](state: S, payload: User): void,
  [MutationTypes.SET_EMAIL](state: S, payload: string): void,
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_USER](state: State, user: User) {
    state.user = user;
  },
  [MutationTypes.SET_EMAIL](state: State, email: string) {
    state.email = email;
  },
};
