import axiosInstance from '@/axios/axiosInstance'

export const getPlans = async (): Promise<any> => {
  const response = await axiosInstance.get('/planos')
  return response.data
}

export const checkUserSass = async (userId: number): Promise<any> => {
  const response = await axiosInstance.get(`/planos/check_user_sass/${userId}`)
  return response.data
}
