
import { useStore } from "@/store";
import { formatNumber, formatNumberWithCurrency, formatVgvValue } from "@/helpers/mask";
import { defineComponent, ref, onMounted, computed} from "vue";
import ResponsiveTable from "@/components/ResponsiveTable.vue";
import GraphicTableCard from "@/components/GraphicTableCard.vue";
import MiniMap from "@/components/MiniMap.vue";
import { formatListAscOrder } from "@/helpers/formatList";

export default defineComponent({
  name: "SummaryExecutiveSection",
  components: {
    ResponsiveTable,
    GraphicTableCard,
    MiniMap,
  },
  props: {
    showHr: {
      type: Boolean,
      default: false,
    },
    isBlockedOpportunity: {
      type: Boolean,
      default: false,
    },
  },
  setup( props, { emit }) {
    const store = useStore();
    const sectionMain = ref<HTMLElement | null>(null);
    const completeOpportunity = computed(() => store.state.opportunity.completeOpportunities);
    const opportunity = computed(() => store.state.opportunity.opportunity);
    const tablesColumn1Area1 = ref<any[]>([]);
    const tablesColumn2Area1 = ref<any[]>([]);
    const tablesColumn2Area2 = ref<any[]>([]);

    const legendItems = ref([
      { label: "Viabilidade", color: "#D4A809" },
      { label: "Mercado imobiliário", color: "#4F3C88" },
      { label: "Sociodemografia", color: "#4FE48B" },
    ]);

    const getAreaDoTerreno = async () => {
      const dataList: any = [];
      const priInfoList: any = [];

      await completeOpportunity.value.pri_info.forEach((element: any) => {
        priInfoList.push(element.pri_zona);
      });

      const formattedPriInfo = priInfoList.join(', '); // Junta os elementos com vírgula

      dataList.push([formatNumber(completeOpportunity.value.area_do_terreno), formattedPriInfo]);
      return dataList;
    }

    const getQuadroDeArea = async () => {
      var dataList: any = [];
      dataList.push([formatNumber(completeOpportunity.value.area_total_construida), formatNumber(completeOpportunity.value.area_privativa)])
      return dataList;
    }

    const getQuadroFinanceiro = async () => {
      var dataList: any = [];
      dataList.push([formatVgvValue(opportunity.value.vgv), formatNumber(opportunity.value.roi) + '%'])
      return dataList;
    }

    const fillTablesColumn1Area1 = async () => {
      const areaDoTerreno = await getAreaDoTerreno();
      const quadroDeArea = await getQuadroDeArea();
      const quadroFinanceiro = await getQuadroFinanceiro();

      tablesColumn1Area1.value = [
        {
          circleColor: "#D4A809",
          title: "Perfil do terreno",
          headers: ["Área do terreno (m²)", "Zoneamento"],
          data: areaDoTerreno,
        },
        {
          circleColor: "#D4A809",
          title: "Quadro de áreas",
          headers: ["Área construída (m²)", "Área privativa (m²)"],
          data: quadroDeArea,
        },
        {
          circleColor: "#D4A809",
          title: "Quadro Financeiro",
          headers: ["VGV", "ROI"],
          data: quadroFinanceiro,
        },
      ];
    };

    const getMediaLancamentoRaio = async () => {
      var dataList: any = [];
      dataList.push(['R$' + formatNumber(completeOpportunity.value.mercado_info.valor_m2), formatNumber(completeOpportunity.value.mercado_info.area_media) + ' m²'])
      return dataList;
    }

    const getMediaGeralRaio = async () => {
      var dataList: any = [];
      dataList.push(['R$' + formatNumber(completeOpportunity.value.mercado_info.valor_m2), formatNumber(completeOpportunity.value.mercado_info.area_media) + ' m²'])
      return dataList;
    }

    const fillTablesColumn2Area1 = async () => {
      const mediaLancamentoRaio = await getMediaLancamentoRaio();
      const mediaGeralRaio = await getMediaGeralRaio()
      tablesColumn2Area1.value = [
        {
          circleColor: "#4F3C88",
          title: "Média de Lançamentos no Raio",
          headers: ["Valor de m²", "Área média"],
          data: mediaLancamentoRaio,
        },
        {
          circleColor: "#4F3C88",
          title: "Média Geral no Raio",
          headers: ["Valor de m²", "Área média"],
          data: mediaGeralRaio,
        },
      ];
    };

    const getOfertasPorMetragemNoRaio = async () => {
      var dataList: any = [];
      await completeOpportunity.value.mercado_info.valores_area_tabela.forEach((element: any) => {
        dataList.push([element.label, element.count, formatNumberWithCurrency(element.mean, 2, true)])
      });

      return dataList;
    }

    const getOfertasPorValoresNoRaio = async () => {
      var dataList: any = [];
      await completeOpportunity.value.mercado_info.valores_preco_tabela.forEach((element: any) => {
        dataList.push([element.label, element.count, formatNumberWithCurrency(element.mean, 2, true)])
      });

      return dataList;
    }

    const getOfertasPorNumeroDeQuartos = async () => {
      var dataList: any = [];

      const labels_quartos = completeOpportunity.value.mercado_info.labels_quartos;
      const values_quartos = completeOpportunity.value.mercado_info.values_quartos;

      dataList = labels_quartos.map((label:string, index:number) => [label, values_quartos[index]]);
      dataList.sort(formatListAscOrder);

      return dataList;
    }

    const fillTablesColumn2Area2 = async () => {
      const ofertasPorMetragemNoRaio = await getOfertasPorMetragemNoRaio();
      const ofertasPorValoresNoRaio = await getOfertasPorValoresNoRaio();
      const ofertasPorNumeroDeQuartos = await getOfertasPorNumeroDeQuartos();
          
      tablesColumn2Area2.value = [
          {
            circleColor: "#4F3C88",
            title: "Ofertas por metragem no raio",
            headers: ["Metragens", "Anúncios", "Vm²"],
            data: ofertasPorMetragemNoRaio,
            info: completeOpportunity.value.mercado_info.valores_area_tabela
          },
          {
            circleColor: "#4F3C88",
            title: "Ofertas por valores no raio",
            headers: ["Valores", "Anúncios", "Vm²"],
            data: ofertasPorValoresNoRaio,
            info: completeOpportunity.value.mercado_info.valores_preco_tabela
          },
          {
            circleColor: "#4F3C88",
            title: "Ofertas por número de quartos",
            headers: ["Quartos", "Anúncios"],
            data: ofertasPorNumeroDeQuartos,
          },
      ];

    };

    const valuesGraphic1 = ref([
      { class: 'Classe A', value: completeOpportunity.value.socioeconomico_resumo.classe['Classe A'] },
      { class: 'Classe B', value: completeOpportunity.value.socioeconomico_resumo.classe['Classe B'] },
      { class: 'Classe C', value: completeOpportunity.value.socioeconomico_resumo.classe['Classe C'] },
      { class: 'Classe D', value: completeOpportunity.value.socioeconomico_resumo.classe['Classe D'] },
      { class: 'Classe E', value: completeOpportunity.value.socioeconomico_resumo.classe['Classe E'] },
    ]);

    const valuesGraphic2 = ref([
      { class: 'Infância', value: completeOpportunity.value.socioeconomico_resumo.etario['Inf\u00e2ncia'] },
      { class: 'Jovens', value: completeOpportunity.value.socioeconomico_resumo.etario['Jovens'] },
      { class: 'Adultos', value: completeOpportunity.value.socioeconomico_resumo.etario['Adultos'] },
      { class: 'Idosos', value: completeOpportunity.value.socioeconomico_resumo.etario['Idosos'] },
    ]);

    const valuesGraphic3 = ref([
      { class: 'Solteiros', value: completeOpportunity.value.socioeconomico_resumo.conjugal['Solteiros'] },
      { class: 'Casados', value: completeOpportunity.value.socioeconomico_resumo.conjugal['Casados'] },
    ]);

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          emit("SummaryExecutiveSection-visible", 1);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection);

    onMounted(() => {
      fillTablesColumn1Area1(); 
      fillTablesColumn2Area1();
      fillTablesColumn2Area2();

      if (sectionMain.value) {
        observer.observe(sectionMain.value);
      }
    });

    return {
      legendItems,
      tablesColumn1Area1,
      tablesColumn2Area1,
      tablesColumn2Area2,
      valuesGraphic1,
      valuesGraphic2,
      valuesGraphic3,
      sectionMain,
      completeOpportunity
    };
  },
});
