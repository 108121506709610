
import { defineComponent, ref, onMounted, computed } from "vue";
import ResponsiveTable from "@/components/ResponsiveTable.vue";
import { useStore } from "@/store";
import { formatNumber } from "@/helpers/mask";

export default defineComponent({
  name: "SummaryViabilityPhisicDataSection",
  components: {
    ResponsiveTable,
  },
  props: {
    showHr: {
      type: Boolean,
      default: false,
    },
    isBlockedOpportunity: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const sectionMain = ref<HTMLElement | null>(null);
    const completeOpportunity = computed(() => store.getters.completeOpportunities);
    const tablesColumn1Area1 = ref<any[]>([]);

    const getDeclividadeAltimetriaTerreno = async () => {
      var dataList: any = [];
      if (completeOpportunity.value.decli_info.length > 0) {
        await completeOpportunity.value.decli_info.forEach((element: any) => {
          dataList.push([
            element.decli_zona,
            element.decli_area_zon_atingimento === null || '' ? '–' : formatNumber(element.decli_area_zon_atingimento, 2),
            element.decli_obs === null || '' ? '–' : element.decli_obs
          ])
        });
      } else {
        dataList.push(['–', '–', '–']);
      }

      return dataList;
    }

    const getIndicesDeclividadeAltimetriaTerreno = async () => {
      var dataList: any = [];
      if (completeOpportunity.value.decli_info.length > 0) {
        await completeOpportunity.value.decli_info.forEach((element: any) => {
          dataList.push([element.decli_zona, 'INTERVALO DE DECLIVIDADE'])
        });
      } else {
        dataList.push(['–', '–']);
      }
      return dataList;
    }

    const fillTablesColumn1Area1 = async () => {
      const declividadeAltimetriaTerreno = await getDeclividadeAltimetriaTerreno();
      const indicesDeclividadeAltimetriaTerreno = await getIndicesDeclividadeAltimetriaTerreno();

      tablesColumn1Area1.value = [
        {
          circleColor: "#E0E0E0",
          title: "Declividade e altimetria do terreno",
          headers: ["Código da zona", "Área atingida (m²)", "Observação"],
          data: declividadeAltimetriaTerreno,
          info: completeOpportunity.value.decli_info
        },
        {
          circleColor: "#E0E0E0",
          title: "Índices de declividade",
          headers: ["Código da zona", "Intervalo de declividade"],
          data: indicesDeclividadeAltimetriaTerreno,
          info: completeOpportunity.value.decli_info
        }
      ];

    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          emit("SummaryViabilityPhisicSection-visible", 2);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection);

    onMounted(() => {
      fillTablesColumn1Area1(); 

      if (sectionMain.value) {
        observer.observe(sectionMain.value);
      }
    });

    return {
      tablesColumn1Area1,
      sectionMain,
      completeOpportunity
    };
  },
});
