import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d7c54cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonLoader = _resolveComponent("ButtonLoader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
        type: "button",
        class: _normalizeClass([_ctx.buttonClass, "regular-p"]),
        disabled: _ctx.disabled,
        style: _normalizeStyle({ width: _ctx.width, height: _ctx.height })
      }, [
        (!_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.text), 1))
          : _createCommentVNode("", true),
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_ButtonLoader)
            ]))
          : _createCommentVNode("", true)
      ], 14, _hoisted_2)
    ])
  ]))
}