import { RouteLocationNormalized } from "vue-router";

const mapRoutesTranslation = {
  "marketplace": "Marketplace",
  "my-panel": "Meu painel",
  "send": "Enviar",
  "bi-opportunity": "Oportunidade BI",
  "login": "Login",
  "forgot-password": "Esqueci minha senha",
  "recover-password": "Recuperar senha",
  "recover-finalization": "Recover Finalization",
  "register": "Cadastro",
  "auth-email": "Autenticação de e-mail",
  "opportunity": "Tela de Oportunidade",
  "account": "Conta",
}

const sendAnalyticsTrack = (
  to?: RouteLocationNormalized,
  originPage?: string,
  opportunityDescription?: string,
) => {
  const pageName = to?.name ? mapRoutesTranslation[to.name as keyof typeof mapRoutesTranslation] : '';
  const originName = originPage ? mapRoutesTranslation[originPage as keyof typeof mapRoutesTranslation] : '';
  const trackingId = 'G-JPKC5Q2C98';
  
  const params: { [key: string]: string } = {};
  
  if (pageName) params['page'] = pageName;
  if (to?.fullPath) params['page_path'] = to.fullPath;
  if (opportunityDescription) params['opportunity'] = opportunityDescription;
  if (originPage) params['origin_page'] = originName;

  (window as any).gtag('config', trackingId, params);
};

export default sendAnalyticsTrack;
