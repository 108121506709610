import { GetterTree } from "vuex";
import type { Loading } from "@/@types";
import { RootState } from "@/store";
import { State } from "./state";

export type Getters = {
  loading(state: State): Loading;
};

export const getters: GetterTree<State, RootState> & Getters = {
  loading: (state) => state.isLoading,
};
