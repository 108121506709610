
import { defineComponent, ref } from 'vue';

interface TooltipData {
    [key: string]: string;
}

export default defineComponent({
    name: 'Tooltip',
    props: {
        title: {
            type: String,
            default: 'Categoria',
        },
        title2: {
            type: String,
            default: 'Outras informações',
        },
        info: {
            type: String,
            default: 'Info',
        },
        tooltipData: {
            type: Object as () => TooltipData,
            default: () => ({}),
        },
        position: {
            type: String,
            default: 'translate(10px, -127px)',
        },
        tagLabel: {
            type: String,
            default: "Em",
        },
        tagValue: {
            type: String,
            default: "análise",
        },
        tagColor: {
            type: String,
            default: "#bca9f5",
        },
    },
    setup() {
        const showTooltip = ref(false);

        return {
            showTooltip,
        };
    },
});
