import { store } from "@/store";
import { LoadingMutationTypes } from "@/store/modules/loading/mutation-types";
import { UserMutationTypes } from "@/store/modules/user/mutation-types";
import router from "@/router/index";

const USER_LOCAL_STORAGE_KEY = 'userData'
const REMEMBER_ME_STORAGE_KEY = 'locates_remember_me'

export const logout = () => {
  store.commit(LoadingMutationTypes.SET_LOADING, false);
  store.commit(UserMutationTypes.SET_USER, null);
  
  localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
  localStorage.removeItem(REMEMBER_ME_STORAGE_KEY);

  router.push({ name: "login" });
};
