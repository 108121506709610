
import { defineComponent, computed } from "vue";
import { formatNumber } from "@/helpers/mask";

export default defineComponent({
  name: "GraphicTableCard",
  props: {
    titleTable: {
      type: String,
      default: "Grupos estários predominantes",
    },
    descriptionTable: {
      type: String,
      default: " Em relação a composição sociodemográfica municipal, a região do imóvel possui maior peso de Classe Social para:",
    },
    descriptionHighlightedTable: {
      type: String,
      default: " Grupos sociais:",
    },
    values: {
      type: Array,
      default: () => [],
    },
    barColor: {
      type: String,
      default: "#4F3C88",
    },
    barColorBackground: {
      type: String,
      default: "#BCA9F5",
    },
    circleColor: {
      type: String,
      default: "#f9f9f9",
    },
    marginTopFooter: {
      type: String,
      default: "0px",
    },
    heightTable: {
      type: String,
      default: "293px",
    },
  },
  setup(props) {
    const markerPosition = (item: any) => {
      return item.value;
    };

    return {
      markerPosition,
      formatNumber
    };
  },
});
