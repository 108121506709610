export enum businessControlEnum {
  PANEL = 1,
  MARKETPLACE = 2,
}

export enum sendingTypeEnum {
  REPORTS = 1,
  MARKETPLACE = 2,
  FUNDING = 3,
  OBSERVATORY = 4,
}

export enum NewOpportunityCardText {
  FUNDING = 'Envie sua oportunidade/projeto para conectar com nossa rede de investidores!',
  REPORTS = 'Envie uma nova oportunidade para receber nossos estudos!',
  OBSERVATORY = 'Solicite o observatório de uma nova cidade!',
}