
import { defineComponent, ref, computed, onMounted, nextTick } from 'vue';
import { useStore } from "@/store";
import { LoadingMutationTypes } from '@/store/modules/loading/mutation-types';
import { findAllBis, getGeomsByGidList, getMarketplaceBis } from '@/services/opportunity/opportunityService';
import Card from '@/components/Card.vue';
import Sidebar from '@/components/Sidebar.vue';
import Tabs from '@/components/Tabs.vue';
import PanelList from './PanelList.vue';
import PanelMap from './PanelMap.vue';
import { businessControlEnum, sendingTypeEnum, NewOpportunityCardText } from '@/services/opportunity/enums/opportunityEnum';

export default defineComponent({
  name: 'Panel',
  components: {
    Card,
    Sidebar,
    Tabs,
    PanelList,
    PanelMap,
  },
  setup() {
    const store = useStore();
    const isLoading = computed(() => store.getters.loading);
    const loadDataMap = ref(false)
    const isMobile = ref(window.innerWidth <= 768);
    const sidebarOpen = ref(true);
    const selectedTab = ref(0);

    const tabs = [
      { title: 'Relatórios', active: true },
      { title: 'Financiamento', active: false },
      { title: 'Marketplace', active: false },
      { title: 'Observatório', active: false },
    ];

    const status = ref('');
    const isSearchExpanded = ref(true);

    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };

    const toggleSearch = () => {
      if (!isMobile.value) return;
      isSearchExpanded.value = !isSearchExpanded.value;
      if (isSearchExpanded.value) {
        nextTick(() => {
          const input = document.querySelector('.input-group input');
          if (input instanceof HTMLInputElement) {
            input.focus();
          }
        });
      }
    };

    const userID = ref(0);
    const opportunitiesFromBI = ref<any>([]);
    const fetchOpportunitiesFromBI = async (sendingType: number): Promise<void> => {
      loadDataMap.value = true
      try {
        opportunitiesFromBI.value = [];

        const userData = localStorage.getItem("userData");
        if (typeof userData === "string") {
          userID.value = JSON.parse(userData).user_id;
        }

        if (userID.value > 0) {
          let response;
          if (sendingType === sendingTypeEnum.MARKETPLACE) {
            response = await getMarketplaceBis(userID.value, true);
          } else {
            response = await findAllBis(businessControlEnum.PANEL, userID.value, sendingType);
          }

          response.forEach((opportunity: any) => {
            if (opportunity.url && opportunity.url.includes('.pdf')) {
              opportunity.isPdf = true;
            }
            opportunitiesFromBI.value.push(opportunity);
          });
        }

        opportunitiesFromBI.value.sort((a: any, b: any) => {
          const dateA = new Date(a.created_at).getTime();
          const dateB = new Date(b.created_at).getTime();
          return dateB - dateA;
        });

        await fillGeoms();
      } catch (error) {
        console.error(error);
      } finally {
        store.commit(LoadingMutationTypes.SET_LOADING, false);
        console.log('OPPORTUNITIES: ', opportunitiesFromBI.value);
      }
    };

    const onTabChanged = (activeTab: number) => {
      selectedTab.value = activeTab;
      switch(activeTab) {
        case 0:
          fetchOpportunitiesFromBI(sendingTypeEnum.REPORTS);
          break;
        case 1:
          fetchOpportunitiesFromBI(sendingTypeEnum.FUNDING);
          break;
        case 2:
          fetchOpportunitiesFromBI(sendingTypeEnum.MARKETPLACE);
          break;
        case 3:
          fetchOpportunitiesFromBI(sendingTypeEnum.OBSERVATORY);
          break;
      }
    };

    const searchOpportunities = (event: any) => {
      const searchValue = event.target.value.toLowerCase();
      if (!searchValue) {
        onTabChanged(selectedTab.value);
        return;
      }
      opportunitiesFromBI.value = opportunitiesFromBI.value.filter((opportunity: any) => {
        return (
          (opportunity.title?.toLowerCase().includes(searchValue) ?? false) ||
          (opportunity.bairro?.toLowerCase().includes(searchValue) ?? false) ||
          (opportunity.cidade?.toLowerCase().includes(searchValue) ?? false) ||
          (opportunity.estado?.toLowerCase().includes(searchValue) ?? false) ||
          (opportunity.tipo?.toLowerCase().includes(searchValue) ?? false) ||
          (opportunity.gid_imovel?.toString().includes(searchValue) ?? false)
        );
      });
    };

    const geomsToSend = ref<any>([]);
    const fillGeoms = async () => {
      const gidList: number[] = [];
      opportunitiesFromBI.value.forEach((opportunity: any) => {
        if (opportunity.gid_imovel) {
          gidList.push(opportunity.gid_imovel);
        }
      });

      const geoms = await getGeomsByGidList(gidList);
      if (geoms) {
        geomsToSend.value = geoms;
        loadDataMap.value = false
      }
    }

    onMounted(() => {
      fetchOpportunitiesFromBI(sendingTypeEnum.REPORTS);

      if (isMobile.value) {
        sidebarOpen.value = false;
        isSearchExpanded.value = false;
      }

      window.addEventListener('resize', () => {
        isMobile.value = window.innerWidth <= 768;
        if (isMobile.value) {
          sidebarOpen.value = false;
          isSearchExpanded.value = false;
        }
      });

      window.addEventListener('keydown', (event) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 'b') {
          toggleSidebar();
        }
      });
    });

    return {
      isLoading,
      loadDataMap,
      opportunitiesFromBI,
      isMobile,
      toggleSidebar,
      sidebarOpen,
      onTabChanged,
      tabs,
      status,
      searchOpportunities,
      isSearchExpanded,
      toggleSearch,
      geomsToSend,
    }
  }
});
