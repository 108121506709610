import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-024f966a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loader-container" }
const _hoisted_2 = { class: "dot-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(['dot', _ctx.currentDot === 1 ? 'active' : ''])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(['dot', _ctx.currentDot === 2 ? 'active' : ''])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(['dot', _ctx.currentDot === 3 ? 'active' : ''])
      }, null, 2)
    ])
  ]))
}