
import { defineComponent, computed, ref, onMounted, watchEffect } from 'vue';
import PageTransition from '@/components/PageTransition.vue';
import Header from '@/components/Header.vue';
import UnderMaintenance from '@/views/maintenance/UnderMaintenance.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'App',
  components: {
    PageTransition,
    Header,
    UnderMaintenance,
  },
  setup() {
    const route = useRoute();
    const isUnderMaintenance = ref(false);
    const shouldHaveScroll = ref(true);

    const updateScrollClass = () => {
      const excludedRoutes = ['my-panel', 'marketplace'];
      shouldHaveScroll.value = !excludedRoutes.includes(route.name as string);
      document.documentElement.classList.toggle('main-scroll', shouldHaveScroll.value);
    };

    const shouldShowHeader = computed(() => {
      const excludedRoutes = [
        'login',
        'register',
        'forgot-password',
        'recover-password',
        'recover-finalization',
        'auth-email',
        'register-finalization',
      ];

      return !excludedRoutes.includes(route.name as string);
    });

    const shouldShowDivider = computed(() => {
      const routeName = route.name as string;
      if (routeName === 'bi-opportunity') {
        return false;
      }
      return true;
    });

    onMounted(() => {
      updateScrollClass();
    });

    watchEffect(() => {
      updateScrollClass();
    });

    return {
      isUnderMaintenance,
      shouldShowHeader,
      shouldShowDivider,
    };
  },
});
