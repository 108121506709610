import { ActionTree, ActionContext } from "vuex";
import { Mutations } from "./mutations";
import { RootState } from "@/store";
import { State } from "./state";
import { UserActionTypes } from "./action-types";
import { UserMutationTypes } from "./mutation-types";
import {User} from "@/@types/user"

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [UserActionTypes.SET_USER](
    { commit }: AugmentedActionContext,
    user: User
  ): void,
  [UserActionTypes.SET_EMAIL](
    { commit }: AugmentedActionContext,
    email: string
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  [UserActionTypes.SET_USER]({ commit }, user: User) {
    commit(UserMutationTypes.SET_USER, user);
  },[UserActionTypes.SET_EMAIL]({ commit }, email: string) {
    commit(UserMutationTypes.SET_EMAIL, email);
  },
  
};
