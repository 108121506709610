import type { User, Email } from "@/@types";

export type State = {
  user: User | null,
  email: Email | null
};

export const state: State = {
  user: null,
  email: ""
};



