import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "header-divider"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UnderMaintenance = _resolveComponent("UnderMaintenance")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PageTransition = _resolveComponent("PageTransition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isUnderMaintenance)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_UnderMaintenance)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.shouldShowHeader)
            ? (_openBlock(), _createElementBlock("header", _hoisted_4, [
                _createVNode(_component_Header),
                (_ctx.shouldShowDivider)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_PageTransition, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]))
  ]))
}