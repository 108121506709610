import axiosInstance from '@/axios/axiosInstance'
import { User, LoginPayload, LoginResponse, RegisterResponse, UserResponse, UserUpdatePayload, RegisterPayload, ResetPasswordPayload, UserExistPayload, GenericResponse } from './types'
import qs from 'qs';

export const createUser = async (user: User): Promise<User> => {
  const response = await axiosInstance.post('/users', user)
  return response.data
}

export const updateUser = async (user: User): Promise<User> => {
  const response = await axiosInstance.put(`/users/${user.id}`, user)
  return response.data
}

export const deleteUser = async (id: number): Promise<void> => {
  await axiosInstance.delete(`/users/${id}`)
}

export const getUsers = async (): Promise<User[]> => {
  const response = await axiosInstance.get('/users')
  return response.data
}

export const getUserById = async (id: number): Promise<User> => {
  const response = await axiosInstance.get(`/user/${id}`)
  return response.data
}

export const getUserByEmail = async (email: string): Promise<User> => {
  const response = await axiosInstance.get(`/user/${email}`)
  return response.data
}

export const getToken = async (payload: LoginPayload): Promise<LoginResponse> => {
  const data = qs.stringify(payload);
  const response = await axiosInstance.post('/login', data)
  localStorage.setItem('userData', JSON.stringify(response.data));
  return response.data
}

export const authenticateUser = async (token: string, email: string): Promise<LoginResponse> => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'USERID': email
  };

  const response = await axiosInstance.get('/authenticated', { headers });
  return response.data;
};


export const userExist = async (email: string): Promise<boolean> => {
  const response = await axiosInstance.post('/user-exist', {email:email});
  return response.data;
};

export const getUserData = (): LoginResponse | null => {
  const userData = localStorage.getItem("userData");
  if (userData) {
    return JSON.parse(userData);
  }
  return null;
};

export const registerUser = async (payload: RegisterPayload): Promise<RegisterResponse> => {
  const data = qs.stringify(payload);
  const response = await axiosInstance.post('/user', data)
  return response.data
}

export const updateUserById = async (payload: UserUpdatePayload): Promise<UserResponse> => {
  const data = qs.stringify(payload);
  const response = await axiosInstance.put(`/user/${payload.id}`, data)
  return response.data
}

export const resetPassword = async (payload: ResetPasswordPayload): Promise<GenericResponse> => {
  const data = qs.stringify(payload);
  const response = await axiosInstance.post('/password-reset', data)
  return response.data
}


