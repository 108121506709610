import { MutationTree } from "vuex";

import type { Layout } from "@/@types";

import { State } from "./state";
import { LayoutMutationTypes as MutationTypes } from "./mutation-types";

export type Mutations<S = State> = {
  [MutationTypes.UPDATE_IS_SIDEBAR_LEFT_OPEN](state: S, payload: Layout): void,
  [MutationTypes.UPDATE_IS_SIDEBAR_RIGHT_OPEN](state: S, payload: Layout): void,
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.UPDATE_IS_SIDEBAR_LEFT_OPEN](state: State, isLeftSidebarOpen: Layout) {
    state.isSidebarLeftOpen = isLeftSidebarOpen;
  },
  [MutationTypes.UPDATE_IS_SIDEBAR_RIGHT_OPEN](state: State, isRightSidebarOpen: Layout) {
    state.isSidebarRightOpen = isRightSidebarOpen;
  },
};
