import { GetterTree } from "vuex";
import type {Layout} from "@/@types";
import { RootState } from "@/store";
import { State } from "./state";

export type Getters = {
  sidebarLeftIsOpen(state: State): Layout,
  sidebarRightIsOpen(state: State): Layout,
};

export const getters: GetterTree<State, RootState> & Getters = {
  sidebarLeftIsOpen: (state) => state.isSidebarLeftOpen,
  sidebarRightIsOpen: (state) => state.isSidebarRightOpen,
};
